import React, { useState, useEffect } from "react";
import { DataForm, LoaderInfo } from "../../../../../libs/react-mpk/components";
import { storage } from "../../../../../services";
import t from "counterpart";
import { number, toast } from "../../../../../libs/react-mpk/services";
import { inject, observer } from "mobx-react";
import { find } from "lodash";

const dto = () => ({
  as: "Suami",
  asKK: true,
  A13: "0",
  A14: "0",
  A23: "0",
  A24: "0",
  A33: "0",
  A34: "0",
  A43: "0",
  A44: "0",
  A53: "0",
  A54: "0",
  A63: "0",
  A64: "0",
  A74: "0",
  A83: "0",
  A84: "0",
  B4: "0",
  C2: "0",
  C3: "0",
  C4: "0",
  D4: "0",
  E12: "0",
  E13: "0",
  E22: "0",
  E23: "0",
  E32: "0",
  E33: "0",
  E42: "0",
  E43: "0",
  E4: "0",
  F4: "0",
  G4: "0",
});

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770S_PHMT, dto());

  if (data.as === undefined) data.as = "Suami";
  if ([undefined, ""].indexOf(data.asKK) >= 0) data.asKK = true;
  return data;
};

const { inputTypes } = DataForm;

const PHMT = (props) => {
  const profile = storage.get(storage.keys.SPT_1770S_PROFILE);
  const phmt = storage.get(storage.keys.SPT_1770S_PHMT);

  const induk = storage.get(storage.keys.SPT_1770S_INDUK);
  const detailLampIID = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IID);
  const headerLampI = storage.get(storage.keys.SPT_1770S_HEADER_LAMP_I);
  // const headerLampIII = storage.get(storage.keys.SPT_1770S_HEADER_LAMP_III)

  const [as, setAs] = useState(
    phmt ? (phmt.as === undefined ? "Suami" : phmt.as) : "Suami"
  );
  const [asKK, setAsKK] = useState(
    phmt ? (typeof phmt.asKK === "boolean" ? phmt.asKK : true) : true
  );
  const [ready, setReady] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [isPhmt] = useState(
    ["3", "4"].indexOf(String(profile.flagSuamiIstri)) >= 0
  );

  const __jumlahTanggungan = detailLampIID.daftarKeluarga.filter(
    (d) => ["suami", "isteri"].indexOf(d.hubungan.toLowerCase()) < 0
  ).length;
  const [jumlahTanggungan] = useState(
    __jumlahTanggungan > 3 ? 3 : __jumlahTanggungan
  );
  const [tanggunganPasangan, setTanggunganPasangan] = useState([]);
  const [C3Options, setC3Options] = useState(
    props.envStore.env.spt.FlgPTKPPHMT
  );

  const getUpdateData = (data, key) => {
    return new Promise(async (resolve, reject) => {
      try {
        data.A43 = isPhmt ? data.A43 : "0";
        data.A53 = isPhmt ? data.A53 : "0";
        const A63 = number.sumFormatted(
          data.A13,
          data.A23,
          data.A33,
          data.A43,
          `-${data.A53}`
        );
        const A64 = number.sumFormatted(
          data.A14,
          data.A24,
          data.A34,
          data.A44,
          `-${data.A54}`
        );
        const A83 = A63;
        const A84 = number.sumFormatted(A64, `-${data.A74}`);
        const B4 = number.sumFormatted(A83, A84);

        if (key === "A43" || key === "A44") {
          induk.A.A4 = number.sumFormatted(data.A43, data.A44);
          storage.update(storage.keys.SPT_1770S_INDUK);
        }

        const C2Status = data.C2Status; //data.asKK ? 'K' : 'TK'
        const C3Status =
          key === "C2Status" && data.C2Status === "K" && data.C3Status === "K"
            ? "0"
            : data.C3Status;
        setC3Options(
          props.envStore.env.spt.FlgPTKPPHMT.filter((d) =>
            C2Status === "0" ? true : C2Status !== d.code
          )
        );

        const C2Tanggungan = C2Status === "0" ? "0" : jumlahTanggungan;
        const C3Tanggungan = C3Status === "0" ? "0" : data.C3Tanggungan;

        let tmpTanggunganPasangan = [];
        for (
          let i = 0;
          i <=
          3 - (C3Status === "0" ? 3 : C2Status === "0" ? 0 : jumlahTanggungan);
          i++
        ) {
          tmpTanggunganPasangan.push(String(i));
        }
        setTanggunganPasangan(tmpTanggunganPasangan);

        const defC2 = `${C2Status}/${C2Tanggungan}`;
        const C2 = isPhmt ? defC2 : "0";

        const defC3 = `${C3Status}/${C3Tanggungan}`;
        const C3 = isPhmt ? defC3 : "0";

        let C4 = 0;
        if (String(profile.tahunPajak) === "2015") {
          if (C2Status === "TK") {
            C4 += 36 * Math.pow(10, 6) + C2Tanggungan * (3 * Math.pow(10, 6));
          } else if (C2Status === "K") {
            C4 += 39 * Math.pow(10, 6) + C2Tanggungan * (3 * Math.pow(10, 6));
          }

          if (C3Status === "TK") {
            C4 += 36 * Math.pow(10, 6) + C3Tanggungan * (3 * Math.pow(10, 6));
          } else if (C3Status === "K") {
            C4 += 39 * Math.pow(10, 6) + C3Tanggungan * (3 * Math.pow(10, 6));
          }
        } else {
          if (C2Status === "TK") {
            C4 += 54 * Math.pow(10, 6) + C2Tanggungan * (4.5 * Math.pow(10, 6));
          } else if (C2Status === "K") {
            C4 +=
              58.5 * Math.pow(10, 6) + C2Tanggungan * (4.5 * Math.pow(10, 6));
          }

          if (C3Status === "TK") {
            C4 += 54 * Math.pow(10, 6) + C3Tanggungan * (4.5 * Math.pow(10, 6));
          } else if (C3Status === "K") {
            C4 +=
              58.5 * Math.pow(10, 6) + C3Tanggungan * (4.5 * Math.pow(10, 6));
          }
        }

        C4 = isPhmt ? number.format(C4) : "0";

        let D4 = isPhmt ? number.sumFormatted(B4, `-${C4}`) : "0";
        D4 =
          number.clearFormat(D4) <= 0 ? "0" : number.roundFormatted(D4, false);
        let _D4 = number.clearFormat(D4);

        let progressif = {
          _5: { max: 50 * Math.pow(10, 6), p: 0.05 },
          _15: { max: 200 * Math.pow(10, 6), p: 0.15 },
          _25: { max: 250 * Math.pow(10, 6), p: 0.25 },
          _30: { max: 500 * Math.pow(10, 6), p: 0.3 },
        };

        //new-tarif
        if (parseInt(profile.tahunPajak) >= 2022) {
          progressif = {
            _5: { max: 60 * Math.pow(10, 6), p: 0.05 },
            _15: { max: 190 * Math.pow(10, 6), p: 0.15 },
            _25: { max: 250 * Math.pow(10, 6), p: 0.25 },
            _30: { max: 4500 * Math.pow(10, 6), p: 0.3 },
            _35: { max: 5000 * Math.pow(10, 6), p: 0.35 },
          };
        }

        let E4;
        let E12;
        let E13;
        let E22;
        let E23;
        let E32;
        let E33;
        let E42;
        let E43;
        let E52;
        let E53;
        if (profile.fghs === true) {
          E12 = number.format(data.E12);
          E13 = number.format(data.E13); //number.roundFormatted(number.format(E13), false)
          E22 = number.format(data.E22);
          E23 = number.format(data.E23); //number.roundFormatted(number.format(E23), false)
          E32 = number.format(data.E32);
          E33 = number.format(data.E33); //number.roundFormatted(number.format(E33), false)
          E42 = number.format(data.E42);
          E43 = number.format(data.E43); //number.roundFormatted(number.format(E43), false)
          E52 = number.format(data.E52);
          E53 = number.format(data.E53);
          E4 = number.roundFormatted(data.E4, false);
        } else {
          E12 = _D4 > progressif._5.max ? progressif._5.max : _D4;
          E13 = E12 * progressif._5.p;
          _D4 -= E12;

          E22 =
            _D4 > 0 ? (_D4 > progressif._15.max ? progressif._15.max : _D4) : 0;
          E23 = E22 * progressif._15.p;
          _D4 -= E22;

          E32 =
            _D4 > 0 ? (_D4 > progressif._25.max ? progressif._25.max : _D4) : 0;
          E33 = E32 * progressif._25.p;
          _D4 -= E32;

          E42 = _D4;
          E43 = E42 * progressif._30.p;
          E4 = number.format(E13 + E23 + E33 + E43);

          //new-tarif
          E52 = 0;
          E53 = 0;
          if (parseInt(profile.tahunPajak) >= 2022) {
            E42 =
              _D4 > 0
                ? _D4 > progressif._30.max
                  ? progressif._30.max
                  : _D4
                : 0;
            E43 = E42 * progressif._30.p;
            _D4 -= E42;

            E52 = _D4;
            E53 = E52 * progressif._35.p;
            E4 = number.format(E13 + E23 + E33 + E43 + E53);

            E52 = number.format(E52);
            E53 = number.format(Math.floor(E53));
          }

          E12 = number.format(E12);
          E13 = number.format(Math.floor(E13)); //number.roundFormatted(number.format(E13), false)
          E22 = number.format(E22);
          E23 = number.format(Math.floor(E23)); //number.roundFormatted(number.format(E23), false)
          E32 = number.format(E32);
          E33 = number.format(Math.floor(E33)); //number.roundFormatted(number.format(E33), false)
          E42 = number.format(E42);
          E43 = number.format(Math.floor(E43)); //number.roundFormatted(number.format(E43), false)
          E4 = number.roundFormatted(E4, false);
        }

        // let F4 = number.roundFormatted(number.format((number.clearFormat(A83)/number.clearFormat(B4))*number.clearFormat(E4)), false)
        // let G4 = number.roundFormatted(number.format((number.clearFormat(A84)/number.clearFormat(B4))*number.clearFormat(E4)), false)

        let F4 = number.format(
          Math.round(
            (number.clearFormat(A83) * number.clearFormat(E4)) /
              number.clearFormat(B4)
          )
        );
        let G4 = number.format(
          Math.round(
            (number.clearFormat(A84) * number.clearFormat(E4)) /
              number.clearFormat(B4)
          )
        );

        resolve({
          A63,
          A64,
          A83,
          A84,
          B4,
          C2,
          C3,
          C2Status,
          C3Status,
          C2Tanggungan: String(C2Tanggungan),
          C3Tanggungan: String(C3Tanggungan),
          C4,
          D4,
          E12,
          E13,
          E22,
          E23,
          E32,
          E33,
          E42,
          E43,
          E52,
          E53,
          E4,
          F4,
          G4,
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const parseC = (sourceData) => {
    let arrC2 = sourceData.C2.split("/");
    let C2Tanggungan = String(arrC2.pop());
    let C2Status = String(
      `${arrC2[0]}${arrC2.length === 3 ? `/${arrC2[1]}` : ""}`
    );
    let arrC3 = sourceData.C3.split("/");
    let C3Tanggungan = String(arrC3.pop());
    C3Tanggungan = C3Tanggungan === "undefined" ? "0" : C3Tanggungan;
    let C3Status = String(
      `${arrC3[0]}${arrC3.length === 3 ? `/${arrC3[1]}` : ""}`
    );
    return { C2Tanggungan, C2Status, C3Tanggungan, C3Status };
  };

  useEffect(async () => {
    try {
      const A33 = headerLampI.jumlahBA; //induk.A.A3

      const initData = isPhmt ? { A33 } : {};
      const defData = storage.get(storage.keys.SPT_1770S_PHMT);
      console.log(
        "🚀 ~ file: PHMT.js ~ line 240 ~ useEffect ~ defData",
        defData
      );
      let data = Object.assign(isPhmt ? phmt || defData : dto(), initData);

      const update = await getUpdateData(data);
      let newObj = Object.assign(data, update);
      // let newObj = Object.assign(data);
      newObj = isPhmt ? Object.assign(newObj, parseC(newObj)) : newObj;

      let tmpTanggunganPasangan = [];
      for (let i = 0; i <= 3 - jumlahTanggungan; i++) {
        tmpTanggunganPasangan.push(String(i));
      }
      setTanggunganPasangan(tmpTanggunganPasangan);

      storage.update(storage.keys.SPT_1770S_PHMT, newObj);
      setDefaultData(newObj);
      setReady(true);
    } catch (error) {
      toast.errorRequest(error);
    }
  }, []);

  return ready ? (
    <DataForm
      baseId="spt-1770S-phmt"
      hintMessage={
        props.errorMessage ||
        (!isPhmt && t.translate("modules.submission.nonPhmtMessage"))
      }
      style={{ maxWidth: 800 }}
      editable={props.isEditable}
      defaultData={defaultData}
      definitions={
        isPhmt
          ? [
              {
                inputType: inputTypes.SELECT,
                label: t.translate("sentence.asSuamiOrIstri"),
                key: "as",
                options: [
                  { value: "Suami", label: "Suami" },
                  { value: "Isteri", label: "Isteri" },
                ],
                show: isPhmt,
              },
              // {
              //   inputType: inputTypes.CHECKBOX,
              //   label: t.translate('sentence.asKK'),
              //   key: 'asKK',
              //   className: 'mpk-margin-M margin-bottom',
              //   show: isPhmt
              // },
              {
                render: (
                  <p className="mpk-full full-width">
                    {t
                      .translate("modules.submission.sptForm.1770S.PHMT.A13")
                      .replace("*as", "")}
                  </p>
                ),
              },
              {
                inputType: inputTypes.INPUT,
                label: as,
                key: "A13",
                disabled: true,
                show: isPhmt,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: as === "Suami" ? "Isteri" : "Suami",
                key: "A14",
                show: isPhmt,
                width: "50%",
              },
              {
                render: (
                  <p className="mpk-full full-width">
                    {t
                      .translate("modules.submission.sptForm.1770S.PHMT.A23")
                      .replace("*as", "")}
                  </p>
                ),
              },
              {
                inputType: inputTypes.INPUT,
                label: as,
                key: "A23",
                disabled: isPhmt ? false : true,
                show: isPhmt,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: as === "Suami" ? "Isteri" : "Suami",
                key: "A24",
                show: isPhmt,
                width: "50%",
              },
              {
                render: (
                  <p className="mpk-full full-width">
                    {t
                      .translate("modules.submission.sptForm.1770S.PHMT.A33")
                      .replace("*as", "")}
                  </p>
                ),
              },
              {
                inputType: inputTypes.INPUT,
                label: as,
                key: "A33",
                disabled: true,
                show: isPhmt,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: as === "Suami" ? "Isteri" : "Suami",
                key: "A34",
                show: isPhmt,
                width: "50%",
              },
              {
                render: (
                  <p className="mpk-full full-width">
                    {t
                      .translate("modules.submission.sptForm.1770S.PHMT.A43")
                      .replace("*as", "")}
                  </p>
                ),
              },
              {
                inputType: inputTypes.INPUT,
                label: as,
                key: "A43",
                show: isPhmt,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: as === "Suami" ? "Isteri" : "Suami",
                key: "A44",
                show: isPhmt,
                width: "50%",
              },
              {
                render: (
                  <p className="mpk-full full-width">
                    {t
                      .translate("modules.submission.sptForm.1770S.PHMT.A53")
                      .replace("*as", "")}
                  </p>
                ),
              },
              {
                inputType: inputTypes.INPUT,
                label: as,
                key: "A53",
                show: isPhmt,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: as === "Suami" ? "Isteri" : "Suami",
                key: "A54",
                show: isPhmt,
                width: "50%",
              },
              {
                render: (
                  <p className="mpk-full full-width">
                    {t
                      .translate("modules.submission.sptForm.1770S.PHMT.A63")
                      .replace("*as", "")}
                  </p>
                ),
              },
              {
                inputType: inputTypes.INPUT,
                label: as,
                key: "A63",
                disabled: true,
                show: isPhmt,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: as === "Suami" ? "Isteri" : "Suami",
                key: "A64",
                disabled: true,
                show: isPhmt,
                width: "50%",
              },
              {
                inputType: inputTypes.INPUT,
                label: t
                  .translate("modules.submission.sptForm.1770S.PHMT.A74")
                  .replace("*pasangan", as === "Suami" ? "Isteri" : "Suami"),
                key: "A74",
                show: isPhmt,
              },
              {
                render: (
                  <p className="mpk-full full-width">
                    {t
                      .translate("modules.submission.sptForm.1770S.PHMT.A83")
                      .replace("*as", "")}
                  </p>
                ),
              },
              {
                inputType: inputTypes.INPUT,
                label: as,
                key: "A83",
                disabled: true,
                show: isPhmt,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: as === "Suami" ? "Isteri" : "Suami",
                key: "A84",
                disabled: true,
                show: isPhmt,
                width: "50%",
              },
              {
                inputType: inputTypes.INPUT,
                label: t
                  .translate("modules.submission.sptForm.1770S.PHMT.B4")
                  .replace("*pasangan", as === "Suami" ? "Isteri" : "Suami"),
                key: "B4",
                disabled: true,
                show: isPhmt,
              },
              // {
              //   inputType: inputTypes.INPUT,
              //   label: t.translate(`modules.submission.sptForm.1770S.PHMT.C2`).replace('*as', as),
              //   key: 'C2',
              //   // options: props.envStore.env.spt.FlgPTKP,
              //   disabled: true,
              //   show: asKK
              // },
              {
                inputType: inputTypes.SELECT,
                label: t
                  .translate(`modules.submission.sptForm.1770S.PHMT.C2`)
                  .replace("*as", as),
                key: "C2Status",
                options: props.envStore.env.spt.FlgPTKPPHMT.filter(
                  (d) => d.code !== "TK"
                ).map((d) => ({ label: d.label, value: d.code })),
                width: "80%",
                className: "mpk-padding-N padding-right",
                disabled: false,
              },
              {
                inputType: inputTypes.INPUT,
                label: t
                  .translate(`column.tanggunganPasangan`)
                  .replace("*pasangan", as),
                key: "C2Tanggungan",
                options: tanggunganPasangan,
                width: "20%",
                disabled: true,
              },
              // {
              //   inputType: inputTypes.INPUT,
              //   label: t.translate(`modules.submission.sptForm.1770S.PHMT.C3`).replace('*pasangan', as === 'Suami' ? 'Isteri' : 'Suami'),
              //   key: 'C3',
              //   show: asKK
              // },
              {
                inputType: inputTypes.SELECT,
                label: t
                  .translate(`modules.submission.sptForm.1770S.PHMT.C3`)
                  .replace("*pasangan", as === "Suami" ? "Isteri" : "Suami"),
                key: "C3Status",
                options: C3Options.map((d) => ({
                  label: d.label,
                  value: d.code,
                })),
                width: "80%",
                className: "mpk-padding-N padding-right",
                disabled: false,
              },
              {
                inputType: inputTypes.SELECT,
                label: t
                  .translate(`column.tanggunganPasangan`)
                  .replace("*pasangan", as === "Suami" ? "Isteri" : "Suami"),
                key: "C3Tanggungan",
                options: tanggunganPasangan,
                width: "20%",
                disabled: false,
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate("modules.submission.sptForm.1770S.PHMT.C4"),
                key: "C4",
                disabled: true,
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.D4"
                )}`,
                key: "D4",
                disabled: true,
                show: true, //isPhmt
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.E12"
                )}%`,
                key: "E12",
                disabled: profile.fghs === true ? false : true,
                show: isPhmt,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.E13"
                )}%`,
                key: "E13",
                disabled: profile.fghs === true ? false : true,
                show: isPhmt,
                width: "50%",
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.E22"
                )}%`,
                key: "E22",
                disabled: profile.fghs === true ? false : true,
                show: isPhmt,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.E23"
                )}%`,
                key: "E23",
                disabled: profile.fghs === true ? false : true,
                show: isPhmt,
                width: "50%",
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.E32"
                )}%`,
                key: "E32",
                disabled: profile.fghs === true ? false : true,
                show: isPhmt,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.E33"
                )}%`,
                key: "E33",
                disabled: profile.fghs === true ? false : true,
                show: isPhmt,
                width: "50%",
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.E42"
                )}%`,
                key: "E42",
                disabled: profile.fghs === true ? false : true,
                show: isPhmt,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.E43"
                )}%`,
                key: "E43",
                disabled: profile.fghs === true ? false : true,
                show: isPhmt,
                width: "50%",
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.E52"
                )}%`,
                key: "E52",
                disabled: profile.fghs === true ? false : true,
                show:
                  isPhmt && parseInt(profile.tahunPajak) >= 2022 ? true : false,
                width: "50%",
                className: "mpk-padding-N padding-right",
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.E53"
                )}%`,
                key: "E53",
                disabled: profile.fghs === true ? false : true,
                show:
                  isPhmt && parseInt(profile.tahunPajak) >= 2022 ? true : false,
                width: "50%",
              },
              {
                inputType: inputTypes.INPUT,
                label: `${t.translate(
                  "modules.submission.sptForm.1770S.PHMT.E4"
                )}`,
                key: "E4",
                disabled: profile.fghs === true ? false : true,
                show: isPhmt,
              },
              {
                inputType: inputTypes.INPUT,
                label: t
                  .translate("modules.submission.sptForm.1770S.PHMT.F4")
                  .replace("*as", as),
                key: "F4",
                disabled: true,
                show: isPhmt,
              },
              {
                inputType: inputTypes.INPUT,
                label: t
                  .translate("modules.submission.sptForm.1770S.PHMT.G4")
                  .replace("*pasangan", as === "Suami" ? "Isteri" : "Suami"),
                key: "G4",
                disabled: true,
                show: isPhmt,
              },
            ]
          : []
      }
      onBeforeChange={(key, value) =>
        [
          "C2",
          "C3",
          "as",
          "asKK",
          "C2Status",
          "C2Tanggungan",
          "C3Status",
          "C3Tanggungan",
        ].indexOf(key) >= 0
          ? value
          : number.format(number.clearFormat(value.replace(/\D/g, "")))
      }
      onChange={async (data, key) => {
        if (key === "as") setAs(data.as);
        if (key === "asKK") setAsKK(data.asKK);
        console.log("🚀 ~ file: PHMT.js:825 ~ onChange={ ~ data:", data);
        data = await getUpdateData(data, key);
        return data;
      }}
      onSubmit={async (data, callback) => {
        console.log("🚀 ~ file: PHMT.js:828 ~ data:", data);
        props.onNextTab();
        if (isPhmt) {
          storage.update(storage.keys.SPT_1770S_INDUK, {
            A: {
              A1: data.A23,
              A3: data.A43,
              A5: data.A53,
            },
          });
        }
        const update = await getUpdateData(data);
        console.log("🚀 ~ file: PHMT.js:839 ~ onSubmit={ ~ update:", update);
        let newObj = Object.assign(data, update);
        console.log("🚀 ~ file: PHMT.js:841 ~ onSubmit={ ~ newObj:", newObj);
        storage.update(storage.keys.SPT_1770S_PHMT, newObj);
        callback(t.translate("sentence.savedItem"), false);
      }}
      submitLabel={t.translate("column.next")}
    />
  ) : (
    <LoaderInfo />
  );
};

PHMT.generate = generate;

export default inject("envStore")(observer(PHMT));
