import React, {useEffect, useState} from 'react'
import { ExpansionList, ExpansionPanel, usePanels } from 'react-md'
import { storage } from '../../../../../services'
import { LoaderInfo, Hint } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import HeaderLampI from './HeaderLampI'
import DetailLampIA from './DetailLampIA'
import DetailLampIB from './DetailLampIB'
import DetailLampIC from './DetailLampIC'
import DetailLampID from './DetailLampID'

const generate = () => {
  HeaderLampI.generate()
  DetailLampIA.generate()
  DetailLampIB.generate()
  DetailLampIC.generate()
  DetailLampID.generate()
}

const LampiranI = (props) => {
  const profile = storage.get(storage.keys.SPT_1770_PROFILE)
  const [ready, setReady] = useState(false)
  const [panels, onKeyDown] = usePanels({
    idPrefix: 'spt-1770-lampiran-I',
    count:4,
    defaultExpandedIndex: 0
  })

  const handleSave = (key, update) => {
    storage.update(key, update);
    updateHeader()
  }

  const updateHeader = () => {
    let header = storage.get(storage.keys.SPT_1770_HEADER_LAMP_I)
    let profile = storage.get(storage.keys.SPT_1770_PROFILE)
    let { tahunPajak, tahunMulai, tahunSelesai, bulanMulai, bulanSelesai, flagHitung, npwp, namaWP, kodePembetulan } = profile
    header = header || {
      npwp,
      namaWP,
      tahunPajak,
      kodePembetulan,
      bulanMulai,
      tahunMulai,
      bulanSelesai,
      tahunSelesai,
      flagHitung,
      jumlahBA4: '0',
      jumlahBB3: '0',
      jumlahJBB5: '0',
      jumlahItemC: '0',
      jumlahBC: '0',
      jumlahBD: '0'
    }

    let detailA = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IA)
    let detailB = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IB)
    let detailC = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IC)
    let detailD = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_ID)

    header.jumlahBA4 = detailA ? number.sumFormatted(
      detailA.A.A1e,
      detailA.A.A2l,
      `-${detailA.A.A3d}`
    ) : '0'

    let objB = detailB ? Object.keys(detailB.B).map(key => (detailB.B[key])) : '0'
    header.jumlahBB3 = detailB ? number.sumFormatted(
      detailB.B.B13,
      detailB.B.B23,
      detailB.B.B33,
      detailB.B.B43,
      detailB.B.B53,
    ): '0'

    header.jumlahJBB5 = detailB ? number.sumFormatted(
      detailB.B.B15,
      detailB.B.B25,
      detailB.B.B35,
      detailB.B.B45,
      detailB.B.B55,
    ) : '0'

    header.jumlahItemC = detailC ? String(detailC.daftarIC.length) : '0'
    header.jumlahBC = detailC ? number.sumFormatted(...detailC.daftarIC.map(d => (d.neto))) : '0'

    let objD = detailD ? Object.keys(detailD.D).filter(key => (key !== 'kodeForm')).map(key => (detailD.D[key])) : '0'
    header.jumlahBD = detailD ? number.sumFormatted(...objD) : '0'
    storage.set(storage.keys.SPT_1770_HEADER_LAMP_I, header)
  }

  useEffect(() => {
    updateHeader()
    setReady(true)
  }, [])

  const [panel1Props, panel2Props, panel3Props,panel4Props,panel5Props] = panels;
  return (
    <div 
      className="mod-submission-spt-1770-lampiran-I mpk-margin-C"
      style={{
        width: '100%',
        maxWidth: 800
      }}
    >
      { ready ? (
        <div>
          {props.errorMessage && 
            <div className="mpk-paper mpk-padding-N padding-all">
              <Hint>{props.errorMessage}</Hint>
            </div>
          }
          <ExpansionList onKeyDown={onKeyDown}>
            {[profile.flagHitung === '1' 
              ? {
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.title'),
                  render: (<DetailLampIA onSave={handleSave}/>),
                  panelProps: panel1Props
                }
              : {
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIB.title'),
                  render: (<DetailLampIB onSave={handleSave}/>),
                  expanded: profile.flagHitung === '2' ? true : undefined,
                  panelProps: panel1Props
                }
              ,...[
              {
                label: t.translate('modules.submission.sptForm.1770.DetailLampIC.title'),
                render: (<DetailLampIC onSave={handleSave}/>),
                panelProps: panel2Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770.DetailLampID.title'),
                render: (<DetailLampID onSave={handleSave}/>),
                panelProps: panel3Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770.HeaderLampI.title'),
                render: (<HeaderLampI {...props}/>),
                panelProps: panel4Props
              },
            ]].map((p, i) => (
              <ExpansionPanel 
                key={`spt-1770-induk-panel-${i}`}
                {...p.panelProps}
                header={<div className="mpk-font size-M weight-M mpk-align align-left">{p.label}</div>}
              >
                {p.render}
              </ExpansionPanel>
            ))}
          </ExpansionList>
        </div>
      ) : <LoaderInfo/> }  
    </div>
  )
}

LampiranI.generate = generate

export default inject('navigationStore', 'envStore', 'authStore')(observer(LampiranI))
