import { crud, http } from '../../libs/react-mpk/services';

let service = new crud('/submission');

export const supportGet = query => {
  return http.get(`/submission/support/index/1770`, query)
}

export const getByReferenceNo = (referenceNo, isSupport=false) => {
  return http.get(`/submission${isSupport?'/support' : ''}/${referenceNo}`)
}

export const getHistory = (referenceNo, isSupport=false) => {
  return http.get(`/submission${isSupport?'/support':''}/${referenceNo}/history`)
}

export const getRawData = name => {
  return http.get(`/submission/${name}/raw`)
}

export const supportGetRawData = name => {
  return http.get(`/submission/support/${name}/raw`)
}

export const post1770 = (data) => {
  return http.post(`/submission/spt/1770`, data)
}

export const post1770S = (data) => {
  return http.post(`/submission/spt/1770S`, data)
}

export const commitSPT = name => {
  return http.get(`/submission/${name}/commit`)
}

export const cancel = name => {
  return http.delete(`/submission/${name}`)
}

export const getPTKP = (tahun=2015, statusKawin=0, tanggungan=0) => {
  return http.get(`/ptkp/${tahun}/${statusKawin}/${tanggungan}`)
}

export const getPdf = (name) => {
  return http.get(`/submission/${name}/pdf`)
}

export const uploadPDF = (name, file, data) => {
  return http.upload(`/submission/${name}/pdf`, file, data)
}

export const deletePDF = (name, uploadId) => {
  return http.delete(`/submission/${name}/pdf/${uploadId}`)
}

export const downloadBpe = referenceNo => {
  return http.download(`/bpe/${referenceNo}/download`, {}, {headers:{responseType: 'blob'}})
}

export const getKlu = name => {
  return http.get(`/klu/${name.replace(/ /ig, '_').replace(/\//ig, '')}`)
}

export const parseFilename = (filename) => {
  let fileName = String(filename).replace('.csv', '').replace('.mpk', '');
  let splitFileName = fileName.split('F');
  let sptCode = `F${splitFileName[1]}`;
  return {
    fileName,
    npwp: splitFileName[0].substr(0, 15),
    periodBegin: splitFileName[0].substr(15,2),
    periodEnd: splitFileName[0].substr(17, 2),
    taxYear: splitFileName[0].substr(19, 4),
    pembetulan: splitFileName[0].substr(23, 2),
    sptCode
  }
} 

export const exportSpt = (submissionName, bagian, isSupport=false, kota, code) => {
  // return bagian.toLowerCase() === 'induk'
  //   ? http.download(`/submission/${isSupport ? 'support/' : ''}${submissionName}/export/${bagian}`)
    // : 
    return http.get(`/submission/${submissionName}/export/spt/${bagian}?kota=${'Jakarta'}&code=${code}`)
}

export const downloadSpt = (submissionName, path, fileName) => {
  return http.download(`/submission/${submissionName}/download/spt`, {path}, {method: 'post'}, {fileName})
}


export default service