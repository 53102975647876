import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { number } from '../../../../../libs/react-mpk/services'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_HEADER_LAMP_I, {
    jumlahBA4: '0',
    jumlahBB3: '0',
    jumlahJBB5: '0',
    jumlahItemC: '0',
    jumlahBC: '0',
    jumlahBD: '0'
  })

  return data
}

const { inputTypes } = DataForm
const HeaderLampI = (props) => {
  const profile = storage.get(storage.keys.SPT_1770_PROFILE)
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  useEffect(() => {
    let header = storage.get(storage.keys.SPT_1770_HEADER_LAMP_I)
    setDefaultData(header)
    setTimeout(() => {
      setReady(true)
    })
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-I"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      hintMessage={t.translate('sentence.headerLampiranHintText')}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderLampI.JBA4'),
          key:'jumlahBA4',
          disabled: true,
          show: profile.flagHitung === '1'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderLampI.JBB3'),
          key:'jumlahBB3',
          disabled: true,
          show: profile.flagHitung === '2'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderLampI.JBB5'),
          key:'jumlahJBB5',
          disabled: true,
          show: profile.flagHitung === '2'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderLampI.JItemC'),
          key:'jumlahItemC',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderLampI.JBC'),
          key:'jumlahBC',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderLampI.JBD'),
          key:'jumlahBD',
          disabled: true
        }
      ]}
      submitLabel={t.translate('column.next')}
      submitIconClassName="mdi mdi-arrow-right"
      onSubmit={() => {
        props.onNextTab()
      }}
    />
  ) : <LoaderInfo/>
}

HeaderLampI.generate = generate

export default HeaderLampI
