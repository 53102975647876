import React, {useState, useEffect} from 'react'
import { DataForm } from '../../../libs/react-mpk/components'
import t from 'counterpart'
import { http } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { storage, options } from '../../../services'

const { inputTypes } = DataForm

const SPTForm = ({
  visible        = false,
  onRequestClose = null,
  ...props
}) => {
  let [years, setYears] = useState(options.getYears(props.envStore.env.timeStamp, props.envStore.env.spt.maxYear))
  let [pembetulans, setPembetulans] = useState(options.getPembetulans())
  let [show, setShow] = useState({
    pembetulan: false,
    suamiIstri: false
  })

  useEffect(() => {
    const { spt, timeStamp } = props.envStore.env
    setYears(options.getYears(timeStamp, spt))
  }, [])
  
  const { spt } = props.envStore.env;

  return (
    <DataForm
      baseId="mod-submission-1770-form"
      title={t.translate('modules.submission.sptForm.title')}
      asDialog={true}
      visible={visible}
      onRequestClose={onRequestClose}
      defaultData={{
        sudahPernahLapor: false,
        tahunPajak : String(moment(props.envStore.env.timeStamp).year() - 1),
        kodePembetulan : String(0),
        profesi: spt.profesi[0].value,
        flagSuamiIstri: spt.flagSuamiIstri[0].value,
        isAbove: '1'
      }}
      definitions={[
        {
          inputType: inputTypes.DIVIDER,
          label: t.translate('column.statusSPT')
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate('column.tahunPajak'),
          key: 'tahunPajak',
          options: years
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate('sentence.sudahPernahLapor'),
          key: 'sudahPernahLapor',
        },
        {
          inputType:inputTypes.SELECT,
          label: t.translate('column.pembetulan'),
          key: 'kodePembetulan',
          options: pembetulans.filter(d => (d != '0')),
          show: show.pembetulan
        },
        {
          inputType: inputTypes.DIVIDER,
          label: t.translate('column.statusWP')
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate('column.profesi'),
          key: 'profesi',
          options: spt.profesi
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate('column.flagSuamiIstri'),
          key: 'flagSuamiIstri',
          options: spt.flagSuamiIstri,
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate('sentence.jmlPenghasilanNetto'),
          key: 'isAbove',
          options: [
            {label: t.translate('column.yes'), value: 1},
            {label: t.translate('column.no'), value: 0}
          ],
          show: false //d => (d.profesi === 'karyawan')
        }
      ]}
      style={{
        width: '100%',
        maxWidth: 640
      }}
      watchDefaultData={false}
      onChange={(data, key) => {
        let suamiIstri = data.statusPernikahan === spt.statusPernikahan[1].value;
        setShow( d => ({...d, ...{pembetulan: data.sudahPernahLapor, suamiIstri}}));
        if(key === 'sudahPernahLapor'){
          if(data.sudahPernahLapor) data.kodePembetulan = '1';
          else data.kodePembetulan = '0'
        }
        return data
      }}
      onSubmit={async (data, callback) => {
        try {
          if((data.profesi === 'karyawan' && data.isAbove === '1') || data.profesi === 'pekerjaanBebas'){
            data.npwp = props.temporaryStore.properties.npwp.npwp
            data.namaWP = props.temporaryStore.properties.npwp.name
            storage.set(storage.keys.SPT_INIT, data)
            setTimeout(() => {
              props.navigationStore.redirectTo(`/${props.envStore.env.applicationType}/submission/spt-${data.profesi === spt.profesi[0].value ? '1770S' : '1770'}/profile`)
              callback()
            })
          } else {
            props.modalStore.showInfo({
              title: t.translate('column.information'),
              children: t.translate('sentence.tidakWajibLapor')
            })
            callback()
          }
        } catch (error) {
          callback(error)
        }
      }}
    />
  )
}

export default inject('envStore', 'navigationStore', 'modalStore', 'temporaryStore', 'authStore')(observer(SPTForm))
