import React, {useState, useEffect} from 'react'
import { LoaderInfo } from '../../../libs/react-mpk/components'
import { config as dataFormConfig } from '../../../libs/react-mpk/components/DataForm'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import { storage, utils } from '../../../services'
import renderHTML from 'react-render-html'
import { merge } from 'lodash'

import Commit from './Commit'
import Greeting from './Greeting'
import MPN from '../MPN'
import { toast } from '../../../libs/react-mpk/services'
import { cancel } from '../Submission.service'

dataFormConfig.setOnBeforeChange((key, value) => {
  value = typeof value === 'string'
    ? value.replace(/[;:<>&\\`']/g,'')
    : value
  
  if(['npwp', 'npwpPemotong', 'npwpPasangan', 'npwpAkuntan', 'npwpKantorAkuntan', 'npwpKonsultan', 'npwpKantorKonsultan', 'npwpPemberiKerja', 'npwpPelapor'].indexOf(key) >= 0){
    // value = utils.unformatNpwp(value)
    value = String(value).substr(0, 15)
    // value = value.length === 15 ? utils.formatNpwp(value) : value
  } else if(['nik', 'ntpn'].indexOf(key) >= 0){
    value = String(value).substr(0, 16)
  } else if(['tahunPerolehan', 'tahunPinjaman'].indexOf(key) >= 0){
    let initData = storage.get(storage.keys.SPT_INIT)
    value = Number(value) > Number(initData.tahunPajak) ? initData.tahunPajak : value
  }

  return value
})

const sptWrapper = (options={}) => WrappedComponent => {
  let opt = merge({
    code: '1770S',
    onInit: () => (Promise.resolve())
  }, options)

  const Form = ({
    className     = '',
    ...props
  }) => {
    let initData = storage.get(storage.keys.SPT_INIT)
    const [ready, setReady] = useState(false)
    const [sptData, setSptData] = useState(null)
    const [showCommit, setShowCommit] = useState(false)
    const [saveOnly, setSaveOnly] = useState(true)
    const [ssp, setSsp] = useState(null)
    const [billingCode, setBillingCode] = useState(null)

    if(!initData) props.navigationStore.redirectTo(`/${props.envStore.env.applicationType}/submissions`)


    const handleBack = () => {
      let { submission } = props.temporaryStore.properties
      if(submission){
        props.temporaryStore.setProperties('submission', null)
        storage.clearAll()
      }
      props.navigationStore.redirectTo(`/${props.envStore.env.applicationType}/submissions`)
    }

    const handleCancel = () => {
      let { submission } = props.temporaryStore.properties
      props.modalStore.showConfirm({
        title: t.translate('modules.submission.sptForm.confirmation.cancelTitle'),
        children: t.translate('modules.submission.sptForm.confirmation.cancelMessage'),
        onSubmit: async (callback) => {
          try{
            if(submission) await cancel(submission.fileName)
            storage.clearAll()
            props.temporaryStore.setProperties('sptErrorData', null)
            callback()
            handleBack()
          }catch(error){
            callback()
            toast.errorRequest(error)
          }
        }
      })
    }

    const handlePembetulan = () => {
      let profile = storage.get(storage.keys[`SPT_${opt.code}_PROFILE`])
      if(Number(profile.kodePembetulan) < 99){
        const nextPembetulan = String(Number(profile.kodePembetulan)+1)
        props.modalStore.showConfirm({
          title: t.translate('modules.submission.sptForm.pembetulan.title'),
          children: t.translate('modules.submission.sptForm.pembetulan.confirmMessage'),
          onSubmit: (callback) => {
            setReady(false)
            props.temporaryStore.setProperties('submission', null)
            toast.success(t.translate('modules.submission.sptForm.pembetulan.dataCreated').replace('*pembetulan', nextPembetulan))
            callback()
            storage.remove(storage.keys.SPT_SUBMISSION)
            storage.update(storage.keys[`SPT_${opt.code}_PROFILE`], {
              kodePembetulan: String(Number(profile.kodePembetulan)+1)
            })
            setTimeout(() => setReady(true))
          }
        })
      } else {
        props.modalStore.showInfo({
          title: t.translate('modules.submission.sptForm.pembetulan.title'),
          children: t.translate('modules.submission.sptForm.pembetulan.deny')
        })
      }
    }

    const getIcon = (regexKey) => {
      return props.temporaryStore.properties.sptErrorData && Object.keys(props.temporaryStore.properties.sptErrorData).toString().match(regexKey)
        ? "mdi mdi-alert"
        : "mdi mdi-check"
    }

    const getErrorMessage = (keys=[]) => {
      if(props.temporaryStore.properties.sptErrorData){
        let message = ''
        for(let key of keys){
          let m = props.temporaryStore.properties.sptErrorData[key]
          if(m){
            for(let msg of m){
              message += `<li>${msg}</li>`
            }
          }
        }
        return message.length > 0 
          ? renderHTML(`<ul className="mpk-padding-N padding-left padding-right mpk-margin-NONE margin-all">${message}</ul>`)
          : null
      } return null
    }

    useEffect(async () => {
      let init = storage.get(storage.keys.SPT_INIT)
      let submission = storage.get(storage.keys.SPT_SUBMISSION)
      let isContinue = true

      if(init){
        if(submission) {
          if(props.envStore.widget.active || (props.authStore.user.isSupport || submission.createdBy === props.authStore.user.username))
            props.temporaryStore.setProperties('submission', submission);
          else {
            isContinue = false
            storage.clearAll()
            handleBack()
          }
        }

        if(isContinue){
          await opt.onInit(props)
          setReady(true)
        }
      }else handleBack()
    }, [])

    const submission = props.temporaryStore.properties.submission

    return ready ? (
      <>
        <WrappedComponent {...{
          submission,
          handleBack,
          handleCancel,
          handlePembetulan,
          getIcon,
          getErrorMessage,
          setSptData,
          setShowCommit,
          setSaveOnly,
          setBillingCode,
          setSsp,
          isEditable: submission ? submission.audit.isEditable : true,
          allowPembetulan: submission && (submission.isNtte || (submission.djpResponse && submission.djpResponse.message.match(/((?=sudah).*(?=dilaporkan)).*/ig))),
          ...props
        }}/>
        <Commit
          sptData={sptData}
          code={opt.code}
          visible={showCommit}
          onRequestClose={() => {
          setShowCommit(false)
            setSaveOnly(true)
          }}
          onBack={handleBack}
          onError={(errorData) => console.log(errorData)}
          saveOnly={saveOnly}
          // onSuccess={(d) => {
          //   if(d && d.submission){
          //     props.temporaryStore.setProperties('submission', submission)
          //   }
          // }}
        />
         <MPN
          visible={billingCode || ssp ? true : false}
          onRequestClose={() => {
            setSsp(null)
            setBillingCode(null)
          }}
          billingCode={billingCode}
          ssp={ssp}
          code={opt.code}
        />
        <Greeting
          code={opt.code}
        />
      </>
    ) : <LoaderInfo/>
  }

  return inject('navigationStore', 'envStore', 'modalStore', 'authStore', 'temporaryStore')(observer(Form))
}



export default sptWrapper
