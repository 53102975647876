import { http } from "../../libs/react-mpk/services"

export const getAggrement = () => {
  return http.get(`/static/legal/cert-registration-aggreement.html`, {}, {}, {useDefaultBaseUrl: false, useDefaultHost: false})
}

export const getUploadAggrement = () => {
  return http.get(`/static/legal/cert-upload-aggreement.html`, {}, {}, {useDefaultBaseUrl: false, useDefaultHost: false})
}

export const register = () => {
  return http.post()
}

export const upload = (data) => {
  return http.upload(`/npwp/cert-upload`, data.file, {password: data.password})
}