import React from 'react'
import { DataForm, LoadingButton } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import t from 'counterpart'
import { storage } from '../../../../../services'
import { inject, observer } from 'mobx-react'
import moment from 'moment'

const { inputTypes } = DataForm

const BagianE = ({
  defaultData = {},
  ...props
}) => {
  const indukData = storage.get(storage.keys.SPT_1770_INDUK)
  const init = storage.get(storage.keys.SPT_INIT)
  const getUpdateData = (data) => {
    if(number.clearFormat(data.E19) >= 0) data.E20 = '0'
    else data.E20 = data.E20 === '' ? '1' : data.E20
    return data
  }

  return (
    <DataForm
      baseId={`spt-1770-induk-E`}
      className="mpk-padding-NONE padding-all"
      asDialog={false}
      defaultData={{...defaultData, ...getUpdateData(defaultData)}}
      definitions={[
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.FlgE19`),
          key: 'flagE19',
          options: props.envStore.env.spt.FlgE19,
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.E19`),
          key: 'E19',
          readOnly: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.E19TglLunas`),
          key: 'E19TanggalLunas',
          type: 'date',
          rquired: true,
          max: moment(props.envStore.env.timeStamp).format('YYYY-MM-DD'),
          show: indukData.E.flagE19 === '1'
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.E20`),
          key: 'E20',
          options: props.envStore.env.spt.E20,
          show: indukData.E.flagE19 === '2'
        },
      ]}
      onBeforeChange={(key, value) => (
        ['E19TanggalLunas'].indexOf(key) >= 0 ? value : number.format(number.clearFormat(value.replace(/\D/g, '')))
      )}
      onChange={data => {
        const update = getUpdateData(data)
        return {...data, ...update}
      }}
      onSubmit={(data, callback) => {
        props.onSave({E:data});
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
      additionalAction={[
          {
            show: (d) => (String(d.flagE19) === '1'),
            render: (d) => (
              <LoadingButton 
                themeType="outline"
                iconClassName="mdi mdi-credit-card-outline"
                onClick={() => {
                  props.setSsp({
                    npwp: init.npwp,
                    tahunPajak: init.tahunPajak,
                    kap: '411125',
                    kjs: '200',
                    masaAwal: '01',
                    masaAkhir: '12',
                    amount: number.clearFormat(d.E19)
                  })
                }}
              >
                {t.translate('column.pay')}
              </LoadingButton>
            )
          }
        ]}
    />
  )
}

export default inject('envStore')(observer(BagianE))
