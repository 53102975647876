import React, { useState, useEffect } from "react";
import { DataForm, LoaderInfo } from "../../../../../libs/react-mpk/components";
import { number, utils } from "../../../../../libs/react-mpk/services";
import { storage } from "../../../../../services";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import moment from "moment";

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_DETAIL_LAMP_II, {
    daftarBuktiPotong: [],
  });

  return data;
};

const { inputTypes } = DataForm;
const DetailLampII = (props) => {
  const [ready, setReady] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_II);
    setDefaultData(defData);
    setReady(true);
  }, []);

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-IC"
      className="mpk-padding-NONE padding-all"
      style={{
        width: "100%",
        maxWidth: 800,
      }}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.LIST,
          label: t.translate("column.daftarBuktiPotong"),
          key: "daftarBuktiPotong",
          defaultData: {
            namaWP: "",
            npwp: "",
            noBukti: "",
            tanggalBukti: "",
            kodePasal: props.envStore.env.spt.KD_PASAL[0].value,
            jumlahPPH: "0",
          },
          definitions: [
            {
              inputType: inputTypes.INPUT,
              label: t.translate(
                "modules.submission.sptForm.1770.DetailLampII.NAMA_WP"
              ),
              key: "namaWP",
              validation: "min:4",
              helpText: t.translate("sentence.minimumName"),
              required: (item, index_list) => {
                return item.daftarBuktiPotong[index_list] &&
                  item.daftarBuktiPotong[index_list].kodePasal === "99"
                  ? false
                  : true;
              },
              disabled: (item, index_list) => {
                return item.daftarBuktiPotong[index_list] &&
                  item.daftarBuktiPotong[index_list].kodePasal === "99"
                  ? true
                  : false;
              },
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(
                "modules.submission.sptForm.1770.DetailLampII.NPWP"
              ),
              key: "npwp",
              validation: "digits:15",
              required: true,
              disabled: (item, index_list) => {
                return item.daftarBuktiPotong[index_list] &&
                  item.daftarBuktiPotong[index_list].kodePasal === "99"
                  ? true
                  : false;
              },
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(
                "modules.submission.sptForm.1770.DetailLampII.NO_BUKTI"
              ),
              key: "noBukti",
              validation: "max:200",
              required: true,
            },
            {
              inputType: inputTypes.DATE,
              label: t.translate(
                "modules.submission.sptForm.1770.DetailLampII.TAMGGAL_BUKTI"
              ),
              key: "tanggalBukti",
              type: "date",
              required: true,
              max: moment(props.envStore.env.timeStamp).format("YYYY-MM-DD"),
            },
            {
              inputType: inputTypes.SELECT,
              label: t.translate(
                "modules.submission.sptForm.1770.DetailLampII.KD_PASAL"
              ),
              key: "kodePasal",
              options: props.envStore.env.spt.KD_PASAL_1770,
              required: true,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(
                "modules.submission.sptForm.1770.DetailLampII.JML_PPH"
              ),
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              allowNegative: true,
              key: "jumlahPPH",
              required: true,
            },
          ],
        },
      ]}
      onChange={(data, key, value, parentKey, index_list) => {
        if (key === "kodePasal") {
          if (value === "99") {
            data[parentKey][index_list].npwp = "";
            data[parentKey][index_list].namaWP = "SKPPKP";
          } else {
            data[parentKey][index_list].npwp = "";
            data[parentKey][index_list].namaWP = "";
          }
        }
        return data;
      }}
      onBeforeChange={(key, value) => {
        // value =
        //   ["jumlahPPH"].indexOf(key) >= 0
        //     ? number.format(number.clearFormat(value.replace(/\D/g, "")))
        //     : ["noBukti", "npwp"].indexOf(key) >= 0
        //     ? value.replace(/\D/g, "")
        //     : value;
        return value;
      }}
      onSubmit={(data, callback) => {
        let isDuplicate = utils.isDuplicateObjectInArray(
          data.daftarBuktiPotong,
          ["noBukti", "npwp"]
        );

        if (isDuplicate) {
          callback(new Error(t.translate("sentence.duplicateBuktiPotong")));
        } else {
          props.onSave(storage.keys.SPT_1770_DETAIL_LAMP_II, data);
          callback(t.translate("sentence.savedItem"), false);
        }
      }}
      submitLabel={t.translate("column.save")}
    />
  ) : (
    <LoaderInfo />
  );
};

DetailLampII.generate = generate;

export default inject("envStore")(observer(DetailLampII));
