import React from "react";
import { DataForm } from "../../../../../libs/react-mpk/components";
import { number } from "../../../../../libs/react-mpk/services";
import t from "counterpart";
import { storage } from "../../../../../services";
import { inject, observer } from "mobx-react";

const { inputTypes } = DataForm;

const BagianF = ({ defaultData = {}, ...props }) => {
  const induk = storage.get(storage.keys.SPT_1770_INDUK);
  const getUpdateData = (data) => {
    console.log("🚀 ~ file: BagianF.js:13 ~ getUpdateData ~ data", data);
    let F21 =
      data.flagF21 === "0"
        ? "0"
        : data.flagF21 === "1"
        ? number.clearFormat(induk.D.D16) / 12
        : number.clearFormat(data.F21);

    F21 = Math.floor(F21);
    data.F21 = number.format(F21);
    return data;
  };

  return (
    <DataForm
      baseId={`spt-1770-induk-F`}
      className="mpk-padding-NONE padding-all"
      asDialog={false}
      defaultData={{ ...defaultData, ...getUpdateData(defaultData) }}
      definitions={[
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.FlgF21`),
          key: "flagF21",
          options: props.envStore.env.spt.FlgF21,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.F21`),
          key: "F21",
          show: (d) => d.flagF21 !== "0",
        },
      ]}
      onBeforeChange={(key, value) =>
        number.format(number.clearFormat(value.replace(/\D/g, "")))
      }
      onChange={(data) => {
        const update = getUpdateData(data);
        return { ...data, ...update };
      }}
      onSubmit={(data, callback) => {
        props.onSave({ F: data });
        callback(t.translate("sentence.savedItem"), false);
      }}
      submitLabel={t.translate("column.save")}
    />
  );
};

export default inject("envStore")(observer(BagianF));
