import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number, utils } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770S_DETAIL_LAMP_IIC, {
    daftarKewajiban:[]
  })

  return data
}

const { inputTypes } = DataForm
const DetailLampIIC = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IIC)
    setDefaultData(defData)
    setReady(true)
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770S-header-lamp-IIC"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      editable={props.isEditable}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.LIST,
          label: t.translate('column.daftarKewajiban'),
          key:'daftarKewajiban',
          defaultData: {
            kodeUtang: props.envStore.env.spt.KODE_UTANG[0].value,
            namaPemberiPinjaman: "",
            alamatPemberiPinjaman: "",
            tahunPinjaman: "",
            jumlahPinjaman: ""
          },
          definitions:[
            {
              inputType: inputTypes.SELECT,
              label: t.translate('modules.submission.sptForm.1770S.DetailLampIIC.JENIS_UTANG'),
              key: 'kodeUtang',
              options: props.envStore.env.spt.KODE_UTANG,
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770S.DetailLampIIC.NM_PBR_PINJAMAN'),
              key: 'namaPemberiPinjaman',
              required: true,
              validation: 'min:4',
              helpText: t.translate('sentence.minimumName'),
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770S.DetailLampIIC.ALMT_PBR_PINJAMAN'),
              key: 'alamatPemberiPinjaman',
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770S.DetailLampIIC.TH_PEMINJAMAN'),
              key: 'tahunPinjaman',
              validation:'digits:4',
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770S.DetailLampIIC.JML_PINJAMAN'),
              key: 'jumlahPinjaman',
              required: true
            }
          ]
        }
      ]}
      onBeforeChange={(key, value) => (
        ['jumlahPinjaman'].indexOf(key) >= 0
          ? number.format(number.clearFormat(value.replace(/\D/g, '')))
          : ['kodeUtang', 'tahunPinjaman'].indexOf(key) >= 0 
            ? value.replace(/\D/g, '')
            : value
      )}
      onSubmit={(data, callback) => {
        let isDuplicate = utils.isDuplicateObjectInArray(data.daftarKewajiban, [
          'kodeUtang', 'namaPemberiPinjaman', 'alamatPemberiPinjaman', 'tahunPinjaman', 'jumlahPinjaman'
        ])
        
        if(isDuplicate){
          callback(new Error(t.translate('sentence.duplicateKewajiban')))
        } else {
          props.onSave(storage.keys.SPT_1770S_DETAIL_LAMP_IIC, data)
          callback(t.translate('sentence.savedItem'), false)
        }
      }}
      submitLabel={t.translate('column.save')}
    />
  ) : <LoaderInfo/>
}

DetailLampIIC.generate = generate

export default inject('envStore')(observer(DetailLampIIC))
