import { find } from 'lodash';

export const formatNpwp = (npwp) => {
  let result = String(npwp).split('');
  result.splice(2, 0, '.');
  result.splice(6, 0, '.');
  result.splice(10, 0, '.');
  result.splice(12, 0, '-');
  result.splice(16, 0, '.');
  return result.toString().replace(/,/g,'');
}

export const unformatNpwp = (npwp) => {
  return npwp.replace(/\D/g, '')
}

export class Formatter{
  constructor(key='', func=() => {}){
    this.key = key 
    this.func = func
  }
}

export const dataFormatter = (data, formatters=[]) => {
  return new Promise(async resolve => {
    for(let key of Object.keys(data)){
      await fObject(data[key])
    }
    resolve()
  })
}

const fObject = (item, formatters=[]) => {
  return new Promise(async resolve => {
    for(let key of Object.keys(item)){
      let dataType = typeof item[key]
      if(dataType === 'object'){
        item[key] = Array.isArray(item[key]) 
          ? await fArray(item[key], formatters) 
          : await fObject(item[key], formatters)
      } else if(dataType === 'string'){
        item[key] = fString(key, item[key], formatters)
      }
    }
    resolve()
  })
}

const fArray = (item, formatters) => {
  return new Promise(async resolve => {
    for(let i = 0 ; i < item.length ; i++){
      let dataType = typeof item[i]
      if(dataType === 'object'){
        item[i] = Array.isArray(item[i])
          ? await fArray(item[i], formatters)
          : await fObject(item[i], formatters)
      }
    }
    resolve(item)
  })
}

const fString = (key, value, formatters) => {
  let fmt = find({key})
  if(fmt) return fmt(value)
  else return value
}

