
import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_DETAIL_PP46, {
    daftarPP46:[]
  })

  return data
}

const { inputTypes } = DataForm
const DetailPP46 = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  const profile = storage.get(storage.keys.SPT_1770_PROFILE)

  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770_DETAIL_PP46)
    setDefaultData(defData)
    setReady(true)
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-IC"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.LIST,
          label: t.translate('column.daftarPP46'),
          key:'daftarPP46',
          defaultData: {
            npwp: "",
            detailMasaPP46: "1",
            alamat: "",
            jumlahBruto: "0",
            jumlahPPHFinal: "0"
          },
          definitions:[
            {
              inputType: inputTypes.INPUT,
              label: 'NPWP',
              key: 'npwp',
              validation:'digits:15',
              required: true
            },
            {
              inputType: inputTypes.SELECT,
              label: t.translate('modules.submission.sptForm.1770.DetailPP46.DetailMasaPP46'),
              key: 'detailMasaPP46',
              required: true,
              options: ['1','2','3','4','5','6','7','8','9','10','11','12']
            },
            {
              inputType: inputTypes.TEXTAREA,
              label: t.translate('column.address'),
              key: 'alamat',
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailPP46.JmlBruto'),
              key: 'jumlahBruto',
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailPP46.JmlPPhFinal'),
              key: 'jumlahPPHFinal',
              required: true,
              disabled: true
            },
          ]
        }
      ]}
      onBeforeChange={(key, value) => {
        value = ['jumlahPPHFinal','jumlahBruto'].indexOf(key) >= 0
          ? number.format(number.clearFormat(value.replace(/\D/g, '')))
          : ['npwp'].indexOf(key) >= 0 
            ? value.replace(/\D/g, '')
            : value
        return value
      }}
      onChange={(data, key, value, parentKey, indexAtParent) => {
        if(['jumlahBruto', 'detailMasaPP46'].indexOf(key) >= 0){
          let pphFinal = 0
          let jumlahBruto = number.clearFormat(data[parentKey][indexAtParent].jumlahBruto)
          let detailMasaPP46 = Number(data[parentKey][indexAtParent].detailMasaPP46)
          let tahunPajak = Number(profile.tahunPajak)

          if(tahunPajak === 2018){
            if(detailMasaPP46 >= 7) pphFinal = jumlahBruto*0.005
            else pphFinal = jumlahBruto*0.01
          } else if(tahunPajak > 2018){
            pphFinal = jumlahBruto*0.005
          } else {
            pphFinal = jumlahBruto*0.01
          }

          pphFinal = Math.floor(pphFinal)
          data[parentKey][indexAtParent].jumlahPPHFinal = number.format(pphFinal)
        }
        return data
      }}
      onSubmit={(data, callback) => {
        let invalidNpwp = false
        let invalidIndex = 0
        for(let i = 0 ; i < data.daftarPP46.length ; i++){
          let d = data.daftarPP46[i]
          if(!d.npwp.startsWith(String(profile.npwp.substr(0, 9)))){
            invalidNpwp = true
            invalidIndex = i + 1
            break;
          }
        }

        if(invalidNpwp){
          callback(new Error(t.translate('sentence.invalidPP46Npwp').replace('*index', invalidIndex)))
        }else{
          props.onSave(storage.keys.SPT_1770_DETAIL_PP46, data)
          callback(t.translate('sentence.savedItem'), false)
        }
      }}
      submitLabel={t.translate('column.save')}
    />
  ) : <LoaderInfo/>
}

DetailPP46.generate = generate

export default inject('envStore')(observer(DetailPP46))
