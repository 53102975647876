import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_DETAIL_LAMP_IIIA, {
    A1: {
      A13: "0",
      A14: "0"
    },
    A2: {
      A23: "0",
      A24: "0"
    },
    A3: {
      A33: "0",
      A34: "0"
    },
    A4: {
      A43: "0",
      A44: "0"
    },
    A5: {
      A53: "0",
      A54: "0"
    },
    A6: {
      A63: "0",
      A64: "0"
    },
    A7: {
      A73: "0",
      A74: "0"
    },
    A8: {
      A83: "0",
      A84: "0"
    },
    A9: {
      A93: "0",
      A94: "0"
    },
    A10: {
      A103: "0",
      A104: "0"
    },
    A11: {
      A113: "0",
      A114: "0"
    },
    A12: {
      A123: "0",
      A124: "0"
    },
    A13: {
      A133: "0",
      A134: "0"
    },
    A14: {
      A143: "0",
      A144: "0"
    },
    A15: {
      A153: "0",
      A154: "0"
    },
    A16: {
      A163: "0",
      A164: "0"
    }
  })

  return data
}

const { inputTypes } = DataForm
const getA = (src) => {
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15, A16} = src
  return {A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15, A16}
}

const DetailLampIIIA = (props) => {
  const profile = storage.get(storage.keys.SPT_1770_PROFILE)
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  const [A, setA] = useState({})
  
  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IIIA)
    setDefaultData(defData)
    setA(getA(defData))
    setReady(true)
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-IA"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          render: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16'].map( idx => (
            <DataForm
              baseId={`spt-1770-header-lamp-IA${idx}`}
              className="mpk-padding-NONE padding-all"
              style={{maxWidth:'unset'}}
              asDIalog={false}
              defaultData={A[`A${idx}`]}
              definitions={[
                 {
                  render:(
                    <p className="mpk-full full-width">
                      {t.translate(`modules.submission.sptForm.1770.DetailLampIIIA.___A${idx}`).replace('*as', '')}
                    </p>
                  )
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate(`column.penghasilanBruto`),
                  key:`A${idx}3`,
                  width: '50%',
                  className: 'mpk-padding-N padding-right',
                  disabled: profile.pp46 
                    ? ['16', '13'].indexOf(idx) >= 0
                    : ['13'].indexOf(idx) >= 0
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate(`column.pphTerutang`),
                  key:`A${idx}4`,
                  width: '50%',
                  disabled: profile.pp46 
                    ? ['16', '13'].indexOf(idx) >= 0
                    : ['13'].indexOf(idx) >= 0
                },
              ]}
              onBeforeChange={(key, value) => (
                number.format(number.clearFormat(value.replace(/\D/g, '')))
              )}
              onChange={ data => {
                let obj = {}
                obj[`A${idx}`] = data
                setA(Object.assign(A, {...obj}))
                return data
              }}
            />
          ))
        }
      ]}
      onBeforeChange={(key, value) => (
        ['namaAkuntan', 'namaKantorAkuntan', 'namaKonsultan', 'namaKantorKonsultan'].indexOf(key) >= 0 
          ? value 
          : ( ['npwpAkuntan', 'npwpKantorAkuntan', 'npwpKonsultan', 'npwpKantorKonsultan'].indexOf(key) >= 0 
            ? value.replace(/\D/g, '')
            : number.format(number.clearFormat(value.replace(/\D/g, '')))
          )
      )}
      onSubmit={(data, callback) => {
        let newObj = Object.assign(data, getA(A))
        props.onSave(storage.keys.SPT_1770_DETAIL_LAMP_IIIA, newObj)
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  ) : <LoaderInfo/>
}

DetailLampIIIA.generate = generate

export default inject('envStore')(observer(DetailLampIIIA))
