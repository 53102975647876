import React, { useEffect, useState } from "react";
import { DataForm } from "../../../../../libs/react-mpk/components";
import { number } from "../../../../../libs/react-mpk/services";
import t from "counterpart";
import { storage } from "../../../../../services";
import { inject, observer } from "mobx-react";

const { inputTypes } = DataForm;

const BagianC = ({ defaultData = {}, ...props }) => {
  const indukData = storage.get(storage.keys.SPT_1770_INDUK);
  const profile = storage.get(storage.keys.SPT_1770_PROFILE);
  const [data, setData] = useState(defaultData);
  const [isPhmt] = useState(
    ["3", "4"].indexOf(String(profile.flagSuamiIstri)) >= 0
  );
  useEffect(() => {
    setData({ ...defaultData, ...getUpdateData(defaultData) });
  }, []);
  const getUpdateData = (data) => {
    // let B11 = number.clearFormat(indukData.B.B11)
    // let C12 = 0

    // const limits = [
    //   {limit: 50*Math.pow(10,6), val: .05},
    //   {limit: 200*Math.pow(10,6), val: .15},
    //   {limit: 250*Math.pow(10,6), val: .25},
    //   {limit: 500*Math.pow(10,6), val: .30}
    // ]

    // for(let l of limits){
    //   if(B11 > 0){
    //     if(B11 > l.limit){
    //       let count = l.limit * l.val;
    //       C12 += count
    //       B11 -= l.limit
    //     } else {
    //       C12 += B11 * l.val
    //       B11 -= l.limit
    //     }
    //   }
    // }

    // C12 = number.format(Math.floor(C12/1000)*1000)
    // const C14 = number.format(number.clearFormat(C12) + number.clearFormat(data.C13))
    // let obj = {
    //   C12, C14
    // }
    const C14 = number.sumFormatted(data.C12, data.C13);
    return { C14 };
  };

  return (
    <DataForm
      baseId={`spt-1770-induk-C`}
      className="mpk-padding-NONE padding-all"
      asDialog={false}
      defaultData={data}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.C12`),
          key: "C12",
          disabled: profile.fghs && !isPhmt ? false : true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.C13`),
          key: "C13",
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.C14`),
          key: "C14",
          disabled: true,
        },
      ]}
      onBeforeChange={(key, value) => {
        // if (key !== "checkSelfCount") {
        //   number.format(number.clearFormat(value.replace(/\D/g, "")));
        // }
        return value;
      }}
      onChange={(data, key, value) => {
        // if (key === "checkSelfCount") {
        // if (value) {
        //   data.C12 = 0;
        // } else {
        //   data.C12 = defaultData.C12;
        // }
        // }
        const update = getUpdateData(data);
        setData({ ...data, ...update });
        return { ...data, ...update };
      }}
      onSubmit={(data, callback) => {
        props.onSave({ C: data });
        callback(t.translate("sentence.savedItem"), false);
      }}
      submitLabel={t.translate("column.save")}
    />
  );
};

export default inject("envStore")(observer(BagianC));
