import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770S_DETAIL_LAMP_IB, {
    B: {
      B13: "0",
      // B14: "0",
      B23: "0",
      B33: "0",
      B43: "0",
      B53: "0",
      B63: "0"
    }
  })

  return data
}
const { inputTypes } = DataForm
const DetailLampIB = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  const [B, setB] = useState({})
  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IB)
    setB(defData.B)
    setDefaultData(defData)
    setReady(true)
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770S-header-lamp-IB"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          render: (
            <DataForm
              baseId="spt-1770S-header-lamp-IB-B"
              className="mpk-padding-NONE padding-all"
              style={{maxWidth: 'unset'}}
              asDialog={false}
              editable={props.isEditable}
              defaultData={B}
              definitions={[
                 {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770S.DetailLampIB.B13'),
                    key:'B13',
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770S.DetailLampIB.B23'),
                    key:'B23',
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770S.DetailLampIB.B33'),
                    key:'B33',
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770S.DetailLampIB.B43'),
                    key:'B43',
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770S.DetailLampIB.B53'),
                    key:'B53',
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770S.DetailLampIB.B63'),
                    key:'B63',
                  }
              ]}
              onBeforeChange={(key, value) => (
                number.format(number.clearFormat(value.replace(/\D/g, '')))
              )}
              onChange={ data => {
                setB(data)
                return data
              }}
              submitLabel={t.translate('column.save')}
            />
          )
        }
      ]}
      onSubmit={(data, callback) => {
        let newObj = Object.assign(data, {B})
        props.onSave(storage.keys.SPT_1770S_DETAIL_LAMP_IB, newObj)
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  ) : <LoaderInfo/>
}

DetailLampIB.generate = generate

export default inject('envStore')(observer(DetailLampIB))
