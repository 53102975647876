import React, { useEffect, useState } from "react";
import {
  ExpansionList,
  ExpansionPanel,
  LeakAddFontIcon,
  ListItemText,
  usePanels,
} from "react-md";
import { storage } from "../../../../../services";
import { LoaderInfo, Hint } from "../../../../../libs/react-mpk/components";
import { inject, observer } from "mobx-react";
import { number, toast } from "../../../../../libs/react-mpk/services";
import moment from "moment";
import t from "counterpart";
import Header from "./Header";
import BagianA from "./BagianA";
import BagianB from "./BagianB";
import BagianC from "./BagianC";
import BagianD from "./BagianD";
import BagianE from "./BagianE";
import BagianF from "./BagianF";
import CheckList from "./CheckList";
import { getPTKP } from "../../../Submission.service";
import { find } from "lodash";

const generate = (timeStamp) => {
  const init = storage.get(storage.keys.SPT_INIT);
  let data = storage.generate(storage.keys.SPT_1770_INDUK, {
    A: {
      A1: "0",
      A2: "0",
      A3: "0",
      A4: "0",
      A5: "0",
      A6: "0",
      A7: "0",
    },
    B: {
      B8: "0",
      B9: "0",
      flagPTKP: "0",
      jumlahTanggungan: "0",
      B10: "0",
      B11: "0",
    },
    C: {
      C12: "0",
      C13: "0",
      C14: "0",
    },
    D: {
      D15: "0",
      flagD16: "0",
      D16: "0",
      D17a: "0",
      D17b: "0",
      D18: "0",
    },
    E: {
      flagE19: "0",
      E19TanggalLunas: "",
      E19: "0",
      E20: "0",
    },
    F: {
      F21: "0",
      flagF21: "0",
    },
    checkList: {
      checkGa: false,
      checkGb: false,
      checkGc: false,
      checkGd: false,
      checkGe: false,
      checkGf: false,
      isiCheckGf: "0",
      checkGg: false,
      checkGh: false,
      isiCheckGh: "",
      checkGi: false,
      checkGj: false,
      checkGk: false,
      checkGl: false,
      isiCheckGl: "",
    },
    flagTandaTanganSPT: "0",
    tanggalSPT: moment(timeStamp).format("YYYY-MM-DD"),
    npwpPelapor: init.npwp,
    namaWPPelapor: init.namaWP,
  });

  return data;
};

const Induk = (props) => {
  const [ready, setReady] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const savedDefaultData = storage.get(storage.keys.SPT_1770_INDUK);
  const profile = storage.get(storage.keys.SPT_1770_PROFILE);
  const detailLampIVC = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IVC);
  const headerLampI = storage.get(storage.keys.SPT_1770_HEADER_LAMP_I);
  const headerLampII = storage.get(storage.keys.SPT_1770_HEADER_LAMP_II);
  const headerSSP = storage.get(storage.keys.SPT_1770_HEADER_SSP);
  const phmt = storage.get(storage.keys.SPT_1770_PHMT);
  const [isPhmt] = useState(
    ["3", "4"].indexOf(String(profile.flagSuamiIstri)) >= 0
  );
  const [panels, onKeyDown] = usePanels({
    idPrefix: "spt-induk",
    count: 7,
    defaultExpandedIndex: 0,
  });

  const handleSave = async (update) => {
    let newObj = Object.assign(defaultData, update);
    newObj.A = updateA(newObj);
    newObj.B = await updateB(newObj);
    newObj.C = updateC(newObj);
    newObj.D = updateD(newObj);
    newObj.E = updateE(newObj);
    newObj.F = updateF(newObj);
    newObj.checkList = updateCheckList(newObj);
    storage.update(storage.keys.SPT_1770_INDUK, newObj);
    setDefaultData(newObj);
  };

  const updateA = (data) => {
    const A1 =
      headerLampI[profile.flagHitung === "1" ? "jumlahBA4" : "jumlahJBB5"]; //isPhmt ? number.sumFormatted(phmt.A13, phmt.A14) : headerLampI.jumlahBA4
    const A2 = headerLampI.jumlahBC; //isPhmt ? number.sumFormatted(phmt.A23, phmt.A24) : headerLampI.jumlahBC
    const A3 = headerLampI.jumlahBD; //isPhmt ? number.sumFormatted(phmt.A33, phmt.A34) : headerLampI.jumlahBD
    // const A4 = defData.A4 || '0' //isPhmt ? number.sumFormatted(phmt.A43, phmt.A44) : defData.A4
    const A5 = number.sumFormatted(A1, A2, A3, data.A.A4);
    // const A6 = defData.A6 || '0' //isPhmt ? number.sumFormatted(phmt.A53, phmt.A54) : defData.A6
    const A7 = number.sumFormatted(A5, `-${data.A.A6}`);
    const A = Object.assign(data.A, { A1, A2, A3, A5, A7 });
    return A;
  };

  const updateB = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        // const flagPTKP = ['0', '2'].indexOf(Number(profile.flagSuamiIstri)) >= 0
        //   ? '0'
        //   : Number(profile.flagSuamiIstri) === '1'
        //     ? '1'
        //     : '3'
        const A7 = number.clearFormat(data.A.A7);
        const B8 = number.clearFormat(data.B.B8);

        const C2 = phmt.C2.split("/")[0];
        const C2Value = isPhmt
          ? find(props.envStore.env.spt.FlgPTKP1770, { code: C2 })
          : null;
        const flagPTKP = isPhmt
          ? C2Value
            ? C2Value.value
            : "0"
          : data.B.flagPTKP || "0";

        const __jumlahTanggungan = detailLampIVC.daftarKeluarga.filter(
          (d) => ["suami", "isteri"].indexOf(d.hubungan.toLowerCase()) < 0
        ).length;
        const jumlahTanggungan =
          __jumlahTanggungan > 3 ? 3 : __jumlahTanggungan;

        let B9 = A7 - B8;
        B9 = number.format(B9 < 0 ? 0 : B9);

        const resB10 = await getPTKP(
          profile.tahunPajak,
          flagPTKP,
          jumlahTanggungan
        );
        const B10 = isPhmt ? "0" : number.format(resB10.data.nominal);
        const B11 =
          number.clearFormat(B9) < number.clearFormat(B10)
            ? "0"
            : number.sumFormatted(B9, `-${B10}`);
        const B = Object.assign(data.B, {
          flagPTKP,
          jumlahTanggungan: String(jumlahTanggungan),
          B9,
          B10,
          B11: number.roundFormatted(B11, false),
        });

        resolve(B);
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateC = (data) => {
    let B11 = number.clearFormat(data.B.B11);
    let C12 = 0;

    if (isPhmt) {
      C12 = phmt.F4;
    } else {
      let limits = [
        { limit: 50 * Math.pow(10, 6), val: 0.05 },
        { limit: 200 * Math.pow(10, 6), val: 0.15 },
        { limit: 250 * Math.pow(10, 6), val: 0.25 },
        { limit: 500 * Math.pow(10, 6), val: 0.3 },
      ];
      if (parseInt(profile.tahunPajak) >= 2022) {
        limits = [
          { limit: 60 * Math.pow(10, 6), val: 0.05 },
          { limit: 190 * Math.pow(10, 6), val: 0.15 },
          { limit: 250 * Math.pow(10, 6), val: 0.25 },
          { limit: 4500 * Math.pow(10, 6), val: 0.3 },
          { limit: 5000 * Math.pow(10, 6), val: 0.35 },
        ];
      }

      for (let i = 0; i < limits.length; i++) {
        let l = limits[i];
        if (B11 > 0) {
          if (B11 > l.limit) {
            let count = (i === limits.length - 1 ? B11 : l.limit) * l.val;
            C12 += count;
            B11 -= l.limit;
          } else {
            C12 += B11 * l.val;
            B11 -= l.limit;
          }
        }
      }

      // C12 = number.roundFormatted(C12, false)
      if (profile.fghs === true) {
        C12 = data.C.C12;
      } else {
        C12 = number.format(C12);
      }
    }
    const C14 = number.sumFormatted(C12, data.C.C13);
    const C = Object.assign(data.C, { C12, C14 });
    return C;
  };

  const updateD = (data) => {
    const D15 = headerLampII.jumlahBA;
    const _C14 = number.clearFormat(data.C.C14);
    const _D15 = number.clearFormat(D15);

    // let _D16 = number.sumFormatted(data.C.C14, `-${D15}`);
    let _D16 = _C14 - _D15;
    _D16 = number.clearFormat(_D16);
    const flagD16 = _D16 > 0 ? "1" : _D16 < 0 ? "2" : "0";

    const D16 = number.format(_D16);
    const D = Object.assign(data.D, { D15, D16, flagD16 });
    return D;
  };

  const updateE = (data) => {
    const D16 = number.clearFormat(data.D.D16);
    const D18 = number.clearFormat(data.D.D18);
    let E19 = D16 - D18;

    const flagE19 = E19 > 0 ? "1" : E19 < -1000 ? "2" : "0";

    E19 = E19 >= -1000 && E19 < 0 ? 0 : E19;

    const E20 =
      E19 >= 0 ? "0" : ["", "0"].indexOf(data.E.E20) < 0 ? data.E.E20 : "1";
    const E = Object.assign(data.E, { flagE19, E19: number.format(E19), E20 });
    return E;
  };

  const updateF = (data) => {
    let F21 =
      data.F.flagF21 === "0"
        ? "0"
        : data.F.flagF21 === "1"
        ? number.clearFormat(data.D.D16) / 12
        : number.clearFormat(data.F.F21);

    F21 = Math.floor(F21);
    const F = Object.assign(data.F, { F21: number.format(F21) });
    return F;
  };

  const updateCheckList = (data) => {
    const checkGc = true;
    const checkGb = data.E.flagE19 === "1" ? true : false; //headerSSP.jumlahItem >= 0 ? true : false
    const checkGi = isPhmt;
    const checkGe = Number(headerLampII.jumlahItemA) > 0 ? true : false;
    const checkList = Object.assign(data.checkList, {
      checkGc,
      checkGb,
      checkGe,
      checkGi,
    });

    return checkList;
  };

  useEffect(async () => {
    try {
      let defData = storage.get(storage.keys.SPT_1770_INDUK);
      // BAGIAN A
      const A = updateA(defData);
      defData = Object.assign(defData, { A });

      //BAGIAN B, C & D
      const B = await updateB(defData);
      defData = Object.assign(defData, { B });
      const C = updateC(defData);
      defData = Object.assign(defData, { C });
      const D = updateD(defData);
      defData = Object.assign(defData, { D });
      const E = updateE(defData);
      defData = Object.assign(defData, { E });
      const F = updateF(defData);
      defData = Object.assign(defData, { F });
      const checkList = updateCheckList(defData);
      defData = Object.assign(defData, { checkList });

      setDefaultData(defData);
      storage.set(storage.keys.SPT_1770_INDUK, defData);
      setReady(true);
    } catch (error) {
      toast.errorRequest(error);
    }
  }, []);

  const [
    panel1Props,
    panel2Props,
    panel3Props,
    panel4Props,
    panel5Props,
    panel6Props,
    panel7Props,
  ] = panels;
  return (
    <div
      className="mod-submission-spt-1770-induk mpk-margin-C"
      style={{
        width: "100%",
        maxWidth: 800,
      }}
    >
      {ready ? (
        <div>
          {props.errorMessage && (
            <div className="mpk-paper mpk-padding-N padding-all">
              <Hint>{props.errorMessage}</Hint>
            </div>
          )}
          <ExpansionList
            onKeyDown={onKeyDown}
            className="mpk-margin-N margin-top"
          >
            {[
              {
                label: t.translate("modules.submission.sptForm.1770.Induk.A"),
                render: (
                  <BagianA
                    onSave={handleSave}
                    defaultData={defaultData.A}
                    isPhmt={isPhmt}
                  />
                ),
                panelProps: panel1Props,
              },
              {
                label: t.translate("modules.submission.sptForm.1770.Induk.B"),
                render: (
                  <BagianB
                    onSave={handleSave}
                    defaultData={defaultData.B}
                    isPhmt={isPhmt}
                  />
                ),
                panelProps: panel2Props,
              },
              {
                label: t.translate("modules.submission.sptForm.1770.Induk.C"),
                render: (
                  <BagianC
                    onSave={handleSave}
                    defaultData={defaultData.C}
                    isPhmt={isPhmt}
                  />
                ),
                panelProps: panel3Props,
              },
              {
                label: t.translate("modules.submission.sptForm.1770.Induk.D"),
                render: (
                  <BagianD
                    onSave={handleSave}
                    defaultData={defaultData.D}
                    isPhmt={isPhmt}
                  />
                ),
                panelProps: panel4Props,
              },
              {
                label: t.translate("modules.submission.sptForm.1770.Induk.E"),
                render: (
                  <BagianE
                    onSave={handleSave}
                    defaultData={defaultData.E}
                    isPhmt={isPhmt}
                    {...props}
                  />
                ),
                panelProps: panel5Props,
              },
              {
                label: t.translate("modules.submission.sptForm.1770.Induk.F"),
                render: (
                  <BagianF
                    onSave={handleSave}
                    defaultData={defaultData.F}
                    isPhmt={isPhmt}
                  />
                ),
                panelProps: panel6Props,
              },
              {
                label: t.translate(
                  "modules.submission.sptForm.1770.Induk.CheckList"
                ),
                render: (
                  <CheckList
                    onSave={handleSave}
                    defaultData={defaultData.checkList}
                    {...props}
                  />
                ),
                panelProps: panel7Props,
              },
            ].map((p, i) => (
              <ExpansionPanel
                key={`spt-1770-induk-panel-${i}`}
                {...p.panelProps}
                header={
                  <div className="mpk-font size-M weight-M">{p.label}</div>
                }
              >
                {p.render}
              </ExpansionPanel>
            ))}
          </ExpansionList>
          <Header
            defaultData={{
              flagTandaTanganSPT: defaultData.flagTandaTanganSPT,
              tanggalSPT: defaultData.tanggalSPT,
              npwpPelapor: defaultData.npwpPelapor,
              namaWPPelapor: defaultData.namaWPPelapor,
            }}
            onSave={handleSave}
            onNextTab={props.onNextTab}
          />
        </div>
      ) : (
        <LoaderInfo />
      )}
    </div>
  );
};

Induk.generate = generate;

export default inject(
  "navigationStore",
  "envStore",
  "authStore"
)(observer(Induk));
