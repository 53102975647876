import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { sso } from '../libs/react-mpk/hocs';
import { PageWrapper } from '../libs/react-mpk/wrapper';
import { http, toast } from '../libs/react-mpk/services'
import { Submission, Certificate, FormSample, DashboardSample } from '../modules';
import { LoaderInfo, Logo } from '../libs/react-mpk/components';
import t from 'counterpart'

class Internal extends React.Component{
  state = {
    checkingNpwp: true,
    showRegistration: false
  }

  async componentDidMount(){
    try {
      window.addEventListener('internal-event', (e) => {
        let { eventType } = e.detail
        switch(eventType){
          case 'show-registration':
            this.setState({showRegistration: true})
            break;
          default:
            return
        }
      })

      if(this.props.authStore.user.isSupport){
        this.setState({
          checkingNpwp: false,
          showRegistration: false
        })
      } else {
        let res = await http.get(`/npwp`)
        let npwp = res.data
        if(npwp) this.props.temporaryStore.setProperties('npwp', npwp);
        setTimeout(() => this.setState({checkingNpwp: false, showRegistration: !npwp}), 500)
      }
    } catch (error) {
      this.setState({checkingNpwp: false})
      toast.errorRequest(error)
    }
  }

  componentWillUnmount(){
    window.removeEventListener('invernal-event', () => {}, false)
  }

  render(){
    let { history } = this.props
    let { npwp } = this.props.temporaryStore.properties
    return this.state.isCheckingNpwp ? <LoaderInfo>{t.translate('sentence.checkingNpwp')}</LoaderInfo> : (
      <>
        <PageWrapper
          sidebarCollapsible={false}
          useSidebar={false}
          showNewsTicker={false}
          inverseTheme={true}
        >
          <Router history={history}>
            <Switch>
              <Route
                path='/internal/dashboard'
                render={props => (
                  <DashboardSample/>
                )}
              />
              <Route
                path='/internal/submissions'
                render={props => (
                  <Submission.List {...props}/>
                )}
              />
              <Route
                path='/internal/submission/spt-1770/:tab'
                render={props => (
                  <Submission.SPT.SPT1770 {...props}/>
                )}
              />
              <Route
                path='/internal/submission/spt-1770S/:tab'
                render={props => (
                  <Submission.SPT.SPT1770S {...props}/>
                )}
              />
              <Route
                path='/internal/form'
                render={props => (
                  <FormSample {...props}/>
                )}
              />
            </Switch>
          </Router>
        </PageWrapper>
        <Certificate.Upload
          visible={this.state.showRegistration}
          onRequestClose={() => {
            if(this.props.temporaryStore.properties.npwp){
              this.setState({showRegistration: false})
            } else {
              this.props.modalStore.showConfirm({
                title: t.translate('mpk.column.logout'),
                children: t.translate('mpk.sentence.logoutMessage'),
                onSubmit: () => {
                  this.props.authStore.logout();
                }
              })
            }
          }}
        />
      </>
    )
  }
}

export default sso({
  basePath: '/internal'
})(Internal);