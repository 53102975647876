import React from "react";
import { DataForm } from "../../../../../libs/react-mpk/components";
import { number } from "../../../../../libs/react-mpk/services";
import t from "counterpart";
import { storage } from "../../../../../services";
import { inject, observer } from "mobx-react";

const { inputTypes } = DataForm;

const CheckList = ({ defaultData = {}, ...props }) => {
  const indukData = storage.get(storage.keys.SPT_1770S_INDUK);
  const profile = storage.get(storage.keys.SPT_1770S_INDUK);
  const getUpdateData = (data) => {
    return data;
  };

  return (
    <DataForm
      baseId={`spt-1770S-induk-CheckList`}
      className="mpk-padding-NONE padding-all"
      asDialog={false}
      editable={props.isEditable}
      defaultData={{ ...defaultData, ...getUpdateData(defaultData) }}
      definitions={[
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.ChkGa`),
          key: "checkGa",
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.ChkGb`),
          key: "checkGb",
          disabled: true,
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.ChkGc`),
          key: "checkGc",
          show: true,
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.ChkGd`),
          key: "checkGd",
          disabled: true,
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.ChkGe`),
          key: "checkGe",
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.IsiChkGe`),
          key: "isiCheckGe",
          show: (d) => d.checkGe,
          disabled: profile.fghs === true ? true : false,
        },
      ]}
      onBeforeChange={(key, value) => {
        return value;
      }}
      onChange={(data, key, value) => {
        if (key === "checkGe") {
          if (profile.fghs === true && value) {
            data.isiCheckGe = "Lampiran Perhitungan Sendiri";
          } else {
            data.isiCheckGe = "";
          }
        }
        const update = getUpdateData(data);
        return { ...data, ...update };
      }}
      onSubmit={(data, callback) => {
        if (profile.fghs === true) {
          if (data.checkGe === true) {
            props.onSave({ checkList: data });
            callback(t.translate("sentence.savedItem"), false);
          } else {
            callback(
              t.translate(
                "modules.submission.sptForm.1770S.Induk.validasiPenghitunganSendiri"
              ),
              true
            );
          }
        } else {
          props.onSave({ checkList: data });
          callback(t.translate("sentence.savedItem"), false);
        }
      }}
      submitLabel={t.translate("column.save")}
    />
  );
};

export default inject("envStore")(observer(CheckList));
