import React, {useState} from 'react'
import { Flex, Modal } from '../../../../libs/react-mpk/components'
import t from 'counterpart'
import renderHTML from 'react-render-html'
import { inject, observer } from 'mobx-react'
import { Checkbox } from 'react-md'

const showKey = 'show-greeting'
const Greeting = ({
  onRequestClose  = () => {},
  code            = '1770S',
  ...props
}) => {
  const storageVisible = localStorage.getItem(showKey)
  const [visible, setVisible] = useState(storageVisible ? String(storageVisible) === '1' : true)
  const handleChange = (e) => {
    const v = e.target.checked
    localStorage.setItem(showKey, v ? '0' : '1')
  }
  return (
    <Modal.Info
      baseId="spt-greeting"
      visible={visible}
      onRequestClose={() => {
        setVisible(false)
      }}
      style={{
        maxWidth: 480
      }}
      {...props}
    >
      <Flex className="mpk-full full-width" align={Flex.properties.align.CENTER}>
        {/* <img 
          className="flex-none mpk-margin-N margin-right"
          src={`${process.env.PUBLIC_URL}/sonia.svg`}
          style={{
            width: 120,
            height: 120,
            objectFit: 'contain'
          }}
        /> */}
        <div>
          {renderHTML(t.translate('modules.submission.sptForm.greeting').replace('*name', props.authStore.user.name).replace('*code', code))}
          <Checkbox
            className="mpk-margin-N margin-top"
            baseId="spt-checkbox-greeting"
            label={<div className="mpk-font size-S">{t.translate('sentence.doNotShowAgain')}</div>}
            onChange={handleChange}
          />
        </div>
      </Flex>
    </Modal.Info>
  )
}

export default inject('authStore')(observer(Greeting))
