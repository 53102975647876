import React from "react";
import { DataForm } from "../../../../../libs/react-mpk/components";
import moment from "moment";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import { storage } from "../../../../../services";

const { inputTypes } = DataForm;
const Header = ({
  defaultData = {
    namaWPPelapor: "",
    npwpPelapor: "",
    flagTandaTanganSPT: "0",
    tanggalSPT: moment(props.envStore.env.timeStamp).format("YYYY-MM-DD"),
  },
  ...props
}) => {
  const profile = storage.get(storage.keys.SPT_1770_PROFILE);
  return (
    <div className="mpk-full full-width mpk-padding-N padding-top mpk-paper mpk-margin-N margin-top">
      <DataForm
        baseId={`spt-1770-header`}
        style={{
          width: "100%",
          maxWidth: "unset",
        }}
        asDialog={false}
        defaultData={defaultData}
        definitions={[
          {
            inputType: inputTypes.INPUT,
            label: "NPWP",
            value: profile.npwp,
            width: "30%",
            className: "mpk-padding-N padding-right",
            show: false,
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate("column.namaWP"),
            value: profile.namaWP,
            width: "70%",
            show: false,
          },
          {
            inputType: inputTypes.DATE,
            label: t.translate("modules.submission.sptForm.1770.Induk.TglSPT"),
            key: "tanggalSPT",
            max: moment(props.envStore.env.timeStamp).format("YYYY-MM-DD"),
            required: true,
          },
          {
            inputType: inputTypes.SELECT,
            label: t.translate(
              "modules.submission.sptForm.1770.Induk.FlgTtdSPT"
            ),
            key: "flagTandaTanganSPT",
            options: props.envStore.env.spt.FlgTtdSPT,
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(
              "modules.submission.sptForm.1770.Induk.NAMA_WP_PELAPOR"
            ),
            key: "namaWPPelapor",
            show: (d) => d.flagTandaTanganSPT !== "0",
            required: true,
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(
              "modules.submission.sptForm.1770.Induk.NPWP_PELAPOR"
            ),
            key: "npwpPelapor",
            validation: "digits:15",
            show: (d) => d.flagTandaTanganSPT !== "0",
            required: true,
          },
        ]}
        onSubmit={(data, callback) => {
          props.onSave(data);
          callback(t.translate("sentence.savedItem"), false);
          props.onNextTab();
        }}
        onChange={(data, key) => {
          if (key === "flagTandaTanganSPT") {
            data.namaWPPelapor =
              data.flagTandaTanganSPT === "0" ? profile.namaWP : "";
            data.npwpPelapor =
              data.flagTandaTanganSPT === "0" ? profile.npwp : "";
          }

          return data;
        }}
        submitLabel={t.translate("column.next")}
      />
    </div>
  );
};

export default inject("envStore")(observer(Header));
