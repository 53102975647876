import React, {useEffect, useState} from 'react'
import { ExpansionList, ExpansionPanel, usePanels } from 'react-md'
import { storage } from '../../../../../services'
import { LoaderInfo, Hint } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import HeaderLampII from './HeaderLampII'
import DetailLampIIA from './DetailLampIIA'
import DetailLampIIB from './DetailLampIIB'
import DetailLampIIC from './DetailLampIIC'
import DetailLampIID from './DetailLampIID'

const generate = () => {
  HeaderLampII.generate()
  DetailLampIIA.generate()
  DetailLampIIB.generate()
  DetailLampIIC.generate()
  DetailLampIID.generate()
}

const LampiranII = (props) => {
  const [ready, setReady] = useState(false)
  const [panels, onKeyDown] = usePanels({
    idPrefix: 'spt-1770S-lampiran-II',
    count:5,
    defaultExpandedIndex: 0
  })

  const handleSave = (key, update) => {
    storage.update(key, update);
    updateHeader()
  }

  const updateHeader = () => {
    let header = storage.get(storage.keys.SPT_1770S_HEADER_LAMP_II)
    let detailA = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IIA)
    let detailB = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IIB)
    let detailC = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IIC)
    let detailD = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IID)
    
    // header.jumlahBA = detailA ? number.sumFormatted(detailA.A14, detailA.A144) : '0'
    if(detailA){
      header.jumlahBA = number.sumFormatted(
        detailA.A14,
        detailA.A24,
        detailA.A34,
        detailA.A44,
        detailA.A54,
        detailA.A64,
        detailA.A74,
        detailA.A84,
        detailA.A94,
        detailA.A104,
        detailA.A114,
        detailA.A124,
        detailA.A134,
        detailA.A144,
      )
    }
    
    if(detailB){
      header.jumlahItemB = String(detailB.daftarHarta.length)
      header.jumlahBB = number.sumFormatted(
        ...detailB.daftarHarta.map( d => (d.hargaPerolehan))
      )
    }

     if(detailC){
      header.jumlahItemC = String(detailC.daftarKewajiban.length)
      header.jumlahBC = number.sumFormatted(
        ...detailC.daftarKewajiban.map( d => (d.jumlahPinjaman))
      )
    }

    if(detailD){
      header.jumlahItemD = String(detailD.daftarKeluarga.length)
    }

    storage.set(storage.keys.SPT_1770S_HEADER_LAMP_II, header)
  }

  useEffect(() => {
    updateHeader()
    setReady(true)
  }, [])

  const [panel1Props, panel2Props, panel3Props, panel4Props, panel5Props] = panels;
  return (
    <div 
      className="mod-submission-spt-1770S-lampiran-II mpk-margin-C"
      style={{
        width: '100%',
        maxWidth: 800
      }}
    >
      { ready ? (
        <div>
          {props.errorMessage && 
            <div className="mpk-paper mpk-padding-N padding-all">
              <Hint>{props.errorMessage}</Hint>
            </div>
          }
          <ExpansionList onKeyDown={onKeyDown}>
            {[
              {
                label: t.translate('modules.submission.sptForm.1770S.DetailLampIIA.title'),
                render: (<DetailLampIIA onSave={handleSave} {...props}/>),
                panelProps: panel1Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770S.DetailLampIIB.title'),
                render: (<DetailLampIIB onSave={handleSave} {...props}/>),
                panelProps: panel2Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770S.DetailLampIIC.title'),
                render: (<DetailLampIIC onSave={handleSave} {...props}/>),
                panelProps: panel3Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770S.DetailLampIID.title'),
                render: (<DetailLampIID onSave={handleSave} {...props}/>),
                panelProps: panel4Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770S.HeaderLampII.title'),
                render: (<HeaderLampII onSave={handleSave} {...props}/>),
                panelProps: panel5Props
              },
            ].map((p, i) => (
              <ExpansionPanel 
                key={`spt-1770S-induk-panel-${i}`}
                {...p.panelProps}
                header={<div className="mpk-font size-M weight-M">{p.label}</div>}
              >
                {p.render}
              </ExpansionPanel>
            ))}
          </ExpansionList>
        </div>
      ) : <LoaderInfo/> }  
    </div>
  )
}

LampiranII.generate = generate

export default inject('navigationStore', 'envStore', 'authStore')(observer(LampiranII))
