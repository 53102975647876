import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770S_DETAIL_LAMP_IA, {
    A: {
      A13: "0",
      A23: "0",
      A33: "0",
      A43: "0",
      A53: "0",
      A63: "0"
    }
  })
  
  return data
}

const { inputTypes } = DataForm
const DetailLampIA = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  const [A, setA] = useState({})
  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IA)
    setDefaultData(defData)
    setA(defData.A)
    setReady(true)
  }, [])

  const getUpdateData = data => {
    return data
  }

  return ready ? (
    <DataForm
      baseId="spt-1770S-header-lamp-IA"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      editable={props.isEditable}
      defaultData={defaultData}
      definitions={[
        {
          render:(
            <DataForm
              baseId="spt-1770S-header-lamp-IA"
              className="mpk-padding-NONE padding-all"
              style={{maxWidth: 'unset'}}
              asDIalog={false}
              defaultData={A}
              definitions={[
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770S.DetailLampIA.A13'),
                  key:'A13',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770S.DetailLampIA.A23'),
                  key:'A23',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770S.DetailLampIA.A33'),
                  key:'A33',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770S.DetailLampIA.A43'),
                  key:'A43',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770S.DetailLampIA.A53'),
                  key:'A53',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770S.DetailLampIA.A63'),
                  key:'A63',
                }
              ]}
              onBeforeChange={(key, value) => (
                number.format(number.clearFormat(value.replace(/\D/g, '')))
              )}
              onChange={ data => {
                const update = getUpdateData(data)
                const newObj = {...data, ...update}
                setA(newObj)
                return newObj
              }}
              submitLabel={t.translate('column.save')}
            />
          )
        },
      ]}
      onBeforeChange={(key, value) => (
        number.format(number.clearFormat(value.replace(/\D/g, '')))
      )}
      onSubmit={(data, callback) => {
        let newObj = Object.assign(data, {A})
        props.onSave(storage.keys.SPT_1770S_DETAIL_LAMP_IA, newObj)
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  ) : <LoaderInfo/>
}

DetailLampIA.generate = generate

export default inject('envStore')(observer(DetailLampIA))
