import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_DETAIL_LAMP_IA, {
    flagAudit: "0",
    opiniAkuntan: "",
    namaAkuntan: "",
    npwpAkuntan: "",
    namaKantorAkuntan: "",
    npwpKantorAkuntan: "",
    namaKonsultan: "",
    npwpKonsultan: "",
    namaKantorKonsultan: "",
    npwpKantorKonsultan: "",
    A: {
      A1a: "0",
      A1b: "0",
      A1c: "0",
      A1d: "0",
      A1e: "0",
      A2a: "0",
      A2b: "0",
      A2c: "0",
      A2d: "0",
      A2e: "0",
      A2f: "0",
      A2g: "0",
      A2h: "0",
      A2i: "0",
      A2j: "0",
      A2k: "0",
      A2l: "0",
      A3a: "0",
      A3b: "0",
      A3c: "0",
      A3d: "0"
    }
  })

  return data
}
const { inputTypes } = DataForm
const DetailLampIA = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  const [A, setA] = useState({})
  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IA)
    setDefaultData(defData)
    setA(defData.A)
    setReady(true)
  }, [])

  const getUpdateData = data => {
    const A1c = number.sumFormatted(data.A1a, `-${data.A1b}`)//number.format(number.clearFormat(data.A1a) - number.clearFormat(data.A1b))
    const A1e = number.sumFormatted(A1c, `-${data.A1d}`)//number.format(number.clearFormat(A1c) - number.clearFormat(data.A1d))
    const A2l = number.sumFormatted(data.A2a, data.A2b, data.A2c, data.A2d, data.A2e, data.A2f, data.A2g, data.A2h, data.A2i, data.A2j, data.A2k)
    const A3d = number.sumFormatted(data.A3a, data.A3b, data.A3c)
    return {
      A1c, A1e, A2l, A3d
    }
    return data
  }

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-IA"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.SELECT,
          label: t.translate('modules.submission.sptForm.1770.DetailLampIA.FlgAudit'),
          key:'flagAudit',
          options: props.envStore.env.spt.FlgAudit,
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate('modules.submission.sptForm.1770.DetailLampIA.OpnAkuntan'),
          key:'opiniAkuntan',
          options: props.envStore.env.spt.OpnAkuntan,
          show: (d) => (d.flagAudit === '1')
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.DetailLampIA.NamaAkuntan'),
          key:'namaAkuntan',
          validation: 'min:4',
          helpText: t.translate('sentence.minimumName'),
          show: (d) => (d.flagAudit === '1')
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.DetailLampIA.NPWPAkuntan'),
          key:'npwpAkuntan',
          validation: 'digits:15',
          show: (d) => (d.flagAudit === '1')
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.DetailLampIA.NamaKntrAkuntan'),
          key:'namaKantorAkuntan',
          validation: 'min:4',
          helpText: t.translate('sentence.minimumName'),
          show: (d) => (d.flagAudit === '1')
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.DetailLampIA.NPWPKntrAkuntan'),
          key:'npwpKantorAkuntan',
          validation: 'digits:15',
          show: (d) => (d.flagAudit === '1')
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.DetailLampIA.NamaKonsultan'),
          key:'namaKonsultan',
          validation: 'min:4',
          helpText: t.translate('sentence.minimumName'),
          show: (d) => (d.flagAudit === '1')
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.DetailLampIA.NPWPKonsultan'),
          key:'npwpKonsultan',
          validation: 'digits:15',
          show: (d) => (d.flagAudit === '1')
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.DetailLampIA.NamaKntrKonsultan'),
          key:'namaKantorKonsultan',
          validation: 'min:4',
          helpText: t.translate('sentence.minimumName'),
          show: (d) => (d.flagAudit === '1')
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.DetailLampIA.NPWPKntrKonsultan'),
          key:'npwpKantorKonsultan',
          validation: 'digits:15',
          show: (d) => (d.flagAudit === '1')
        },
        {
          render:(
            <DataForm
              baseId="spt-1770-header-lamp-IA"
              className="mpk-padding-NONE padding-all"
              style={{maxWidth: 'unset'}}
              asDIalog={false}
              defaultData={A}
              definitions={[
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A1a'),
                  key:'A1a',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A1b'),
                  key:'A1b',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A1c'),
                  key:'A1c',
                  disabled: true
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A1d'),
                  key:'A1d',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A1e'),
                  key:'A1e',
                  disabled: true
                },
                {
                  inputType: inputTypes.DIVIDER,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.beforeA2a')
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2a'),
                  helpText:t.translate('modules.submission.sptForm.1770.DetailLampIA.A2aHelp'),
                  key:'A2a',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2b'),
                  helpText:t.translate('modules.submission.sptForm.1770.DetailLampIA.A2bHelp'),
                  key:'A2b',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2c'),
                  helpText:t.translate('modules.submission.sptForm.1770.DetailLampIA.A2cHelp'),
                  key:'A2c',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2d'),
                  helpText:t.translate('modules.submission.sptForm.1770.DetailLampIA.A2dHelp'),
                  key:'A2d',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2e'),
                  key:'A2e',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2f'),
                  key:'A2f',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2g'),
                  key:'A2g',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2h'),
                  key:'A2h',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2i'),
                  key:'A2i',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2j'),
                  helpText:t.translate('modules.submission.sptForm.1770.DetailLampIA.A2jHelp'),
                  key:'A2j',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2k'),
                  key:'A2k',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A2l'),
                  key:'A2l',
                  disabled: true
                },
                {
                  inputType: inputTypes.DIVIDER,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.beforeA3a')
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A3a'),
                  helpText:t.translate('modules.submission.sptForm.1770.DetailLampIA.A3aHelp'),
                  key:'A3a',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A3b'),
                  key:'A3b',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A3c'),
                  key:'A3c',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('modules.submission.sptForm.1770.DetailLampIA.A3d'),
                  key:'A3d',
                  disabled: true
                },
              ]}
              onBeforeChange={(key, value) => (
                number.format(number.clearFormat(value.replace(/\D/g, '')))
              )}
              onChange={ data => {
                const update = getUpdateData(data)
                const newObj = {...data, ...update}
                setA(newObj)
                return newObj
              }}
              submitLabel={t.translate('column.save')}
            />
          )
        },
      ]}
      onBeforeChange={(key, value) => (
        ['namaAkuntan', 'namaKantorAkuntan', 'namaKonsultan', 'namaKantorKonsultan'].indexOf(key) >= 0 
          ? value 
          : ( ['npwpAkuntan', 'npwpKantorAkuntan', 'npwpKonsultan', 'npwpKantorKonsultan'].indexOf(key) >= 0 
            ? value.replace(/\D/g, '')
            : number.format(number.clearFormat(value.replace(/\D/g, '')))
          )
      )}
      onChange={(data, key) => {
        if(key === 'flagAudit' && data.opiniAkuntan === '' && data.flagAudit === '1'){
          data.opiniAkuntan = '4'
        }
        return data
      }}
      onSubmit={(data, callback) => {
        if(String(data.flagAudit) === '0') {
          data.opiniAkuntan = ""
          data.namaAkuntan = ""
          data.npwpAkuntan = ""
          data.namaKantorAkuntan = ""
          data.npwpKantorAkuntan = ""
          data.namaKonsultan = ""
          data.npwpKonsultan = ""
          data.namaKantorKonsultan = ""
          data.npwpKantorKonsultan = ""
        } 

        let newObj = Object.assign(data, {A})
        props.onSave(storage.keys.SPT_1770_DETAIL_LAMP_IA, newObj)
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  ) : <LoaderInfo/>
}

DetailLampIA.generate = generate

export default inject('envStore')(observer(DetailLampIA))
