import React from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import t from 'counterpart'
import { storage } from '../../../../../services'

const { inputTypes } = DataForm

const BagianA = ({
  defaultData = {},
  ...props
}) => {
  const phmt = storage.get(storage.keys.SPT_1770S_PHMT)
  return (
    <DataForm
      baseId={`spt-1770S-induk-A`}
      className="mpk-padding-NONE padding-all"
      asDialog={false}
      editable={props.isEditable}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.A1`),
          key: 'A1',
          // disabled: props.isPhmt
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.A2`),
          key: 'A2',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.A3`),
          key: 'A3',
          disabled: props.isPhmt
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.A4`),
          key: 'A4'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.A5`),
          key: 'A5',
          disabled: props.isPhmt
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.A6`),
          key: 'A6',
          disabled: true
        },
      ]}
      onBeforeChange={(key, value) => (
        number.format(number.clearFormat(value.replace(/\D/g, ''))))
      }
      onChange={(data, key) => {
        data.A4 = number.format(['A1', 'A2', 'A3'].map((a) => (Number(number.clearFormat(data[a])))).reduce((a,b) => (a+b), 0))
        data.A6 = number.format(Number(number.clearFormat(data.A4)) - Number(number.clearFormat(data.A5)))
        return data
      }}
      onSubmit={(data, callback) => {
        if(props.isPhmt){
          phmt.A43 = data.A3
          phmt.A53 = data.A5
          storage.update(storage.keys.SPT_1770S_PHMT, phmt)
        }
        props.onSave({A:data});
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  )
}

export default BagianA
