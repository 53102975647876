import React, { useState } from "react";
import { TabsWrapper } from "../../../../libs/react-mpk/wrapper";
import { Flex, LoadingButton } from "../../../../libs/react-mpk/components";
import t from "counterpart";
import Profile from "./Profile";
import { storage } from "../../../../services";
import Induk from "./Induk";
import LampiranI from "./LampiranI";
import LampiranII from "./LampiranII";
import LampiranIII from "./LampiranIII";
import LampiranIV from "./LampiranIV";
import PHMT from "./PHMT";
import PP46 from "./PP46";
import SSP from "./SSP";
import moment from "moment";
import { Button, FontIcon } from "react-md";
import { toast, number } from "../../../../libs/react-mpk/services";
import sptWrapper from "../spt.wrapper";
import ExportSPT from "./ExportSPT";

const Form1770 = ({
  className = "",
  handleBack,
  handleCancel,
  handlePembetulan,
  getIcon,
  getErrorMessage,
  setSptData,
  setShowCommit,
  setSaveOnly,
  setSsp,
  setBillingCode,
  submission,
  isEditable,
  ...props
}) => {
  const [inProgress, setInProgress] = useState("");

  const handleSubmit = (saveOnly = true) => {
    let { timestamp } = props.envStore.env;
    let profile = storage.get(storage.keys.SPT_1770_PROFILE);
    let induk = storage.get(storage.keys.SPT_1770_INDUK);
    if (
      String(induk.E.flagE19) === "2" &&
      Number(profile.tahunPajak) < Number(moment(timestamp).year()) - 3
    ) {
      props.modalStore.showConfirm({
        title: t.translate("column.alert"),
        children: t.translate("sentence.sspLB3yearAlert"),
        onSubmit: (callback) => {
          callback();
          execute(saveOnly);
        },
        dialogStyle: {
          maxWidth: 480,
        },
      });
    } else {
      execute(saveOnly);
    }
  };

  const execute = async (saveOnly = true) => {
    setInProgress(saveOnly ? "saving" : "comitting");
    try {
      const { npwp } = props.temporaryStore.properties.npwp;
      let profile = storage.get(storage.keys.SPT_1770_PROFILE);
      profile = await number.clearFormatBulk(
        profile,
        storage.formatKeys.spt1770.profile.f,
        storage.formatKeys.spt1770.profile.e
      );
      profile.namaWP =
        profile.namaWP.length < 4
          ? String(profile.namaWP).split("").toString().replace(/\,/g, " ")
          : profile.namaWP;
      profile.npwp = npwp;
      profile.fax = profile.fax === "" ? "0" : profile.fax;
      profile.fghs = profile.fghs ? "1" : "0";
      for (let k of [
        "tahunPajak",
        "kodePembetulan",
        "tahunSelesai",
        "tahunMulai",
        "flagHitung",
      ]) {
        profile[k] = Number(profile[k]);
      }
      let {
        tahunPajak,
        kodePembetulan,
        tahunMulai,
        tahunSelesai,
        flagHitung,
        bulanMulai,
        bulanSelesai,
        namaWP,
      } = profile;

      let induk = storage.get(storage.keys.SPT_1770_INDUK);
      induk = await number.clearFormatBulk(
        induk,
        storage.formatKeys.spt1770.induk.f,
        storage.formatKeys.spt1770.induk.e
      );
      // induk.E.E19TanggalLunas = induk.E.E19TanggalLunas === '' ? '0' : induk.E.E19TanggalLunas
      induk.E.E19TanggalLunas =
        induk.E.flagE19 !== "1" ? "" : induk.E.E19TanggalLunas;
      if (induk.C.checkSelfCount) {
        induk.C.checkSelfCount = induk.C.checkSelfCount ? "1" : "0";
        if (induk.C.checkSelfCount === "1") {
          if (!induk.checkList.checkGh) {
            throw new Error(
              t.translate(
                "modules.submission.sptForm.1770.Induk.validasiPenghitunganSendiri"
              )
            );
          }
        }
      } else {
        induk.C.checkSelfCount = "0";
      }
      for (let key of Object.keys(induk.checkList)) {
        if (key.startsWith("check")) {
          induk.checkList[key] = induk.checkList[key] ? "1" : "0";
        } else {
          if (key === "isiCheckGf")
            induk.checkList[key] = Number(induk.checkList[key]);
        }
      }

      let headerLampI = storage.get(storage.keys.SPT_1770_HEADER_LAMP_I);
      headerLampI = await number.clearFormatBulk(
        headerLampI,
        storage.formatKeys.spt1770.headerLampI.f,
        storage.formatKeys.spt1770.headerLampI.e
      );
      headerLampI = Object.assign(headerLampI, {
        tahunPajak,
        kodePembetulan,
        tahunMulai,
        tahunSelesai,
        flagHitung,
        bulanMulai,
        bulanSelesai,
        npwp,
        namaWP,
      });

      let detailLampIA = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IA);
      detailLampIA = await number.clearFormatBulk(
        detailLampIA,
        storage.formatKeys.spt1770.detailLampIA.f,
        storage.formatKeys.spt1770.detailLampIA.e
      );

      let detailLampIB = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IB);
      detailLampIB = await number.clearFormatBulk(
        detailLampIB,
        storage.formatKeys.spt1770.detailLampIB.f,
        storage.formatKeys.spt1770.detailLampIB.e
      );
      detailLampIB.B.B14 = Number(detailLampIB.B.B14);
      detailLampIB.B.B24 = Number(detailLampIB.B.B24);
      detailLampIB.B.B34 = Number(detailLampIB.B.B34);
      detailLampIB.B.B44 = Number(detailLampIB.B.B44);
      detailLampIB.B.B54 = Number(detailLampIB.B.B54);

      let detailLampIC = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IC);
      detailLampIC = await number.clearFormatBulk(
        detailLampIC,
        storage.formatKeys.spt1770.detailLampIC.f,
        storage.formatKeys.spt1770.detailLampIC.e
      );

      let detailLampID = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_ID);
      detailLampID = await number.clearFormatBulk(
        detailLampID,
        storage.formatKeys.spt1770.detailLampID.f,
        storage.formatKeys.spt1770.detailLampID.e
      );

      let headerLampII = storage.get(storage.keys.SPT_1770_HEADER_LAMP_II);
      headerLampII = await number.clearFormatBulk(
        headerLampII,
        storage.formatKeys.spt1770.headerLampII.f,
        storage.formatKeys.spt1770.headerLampII.e
      );
      headerLampII = Object.assign(headerLampII, {
        tahunPajak,
        kodePembetulan,
        tahunMulai,
        tahunSelesai,
        flagHitung,
        bulanMulai,
        bulanSelesai,
        npwp,
        namaWP,
      });

      let detailLampII = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_II);
      detailLampII = await number.clearFormatBulk(
        detailLampII,
        storage.formatKeys.spt1770.detailLampII.f,
        storage.formatKeys.spt1770.detailLampII.e
      );

      detailLampII.daftarBuktiPotong = detailLampII.daftarBuktiPotong.map(
        (d) => {
          d.tanggalBukti = moment(d.tanggalBukti).format("YYYY-MM-DD");
          return d;
        }
      );

      let headerPP46 = storage.get(storage.keys.SPT_1770_HEADER_PP46);
      headerPP46 = await number.clearFormatBulk(
        headerPP46,
        storage.formatKeys.spt1770.headerPP46.f,
        storage.formatKeys.spt1770.headerPP46.e
      );
      headerPP46 = Object.assign(headerPP46, { npwp, nama: namaWP });

      let detailPP46 = storage.get(storage.keys.SPT_1770_DETAIL_PP46);
      detailPP46 = await number.clearFormatBulk(
        detailPP46,
        storage.formatKeys.spt1770.detailPP46.f,
        storage.formatKeys.spt1770.detailPP46.e
      );

      let detailLampIIIA = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IIIA);
      detailLampIIIA = await number.clearFormatBulk(
        detailLampIIIA,
        storage.formatKeys.spt1770.detailLampIIIA.f,
        storage.formatKeys.spt1770.detailLampIIIA.e
      );

      let headerLampIII = storage.get(storage.keys.SPT_1770_HEADER_LAMP_III);
      headerLampIII = await number.clearFormatBulk(
        headerLampIII,
        storage.formatKeys.spt1770.headerLampIII.f,
        storage.formatKeys.spt1770.headerLampIII.e
      );
      headerLampIII = Object.assign(headerLampIII, {
        tahunPajak,
        kodePembetulan,
        tahunMulai,
        tahunSelesai,
        flagHitung,
        bulanMulai,
        bulanSelesai,
        npwp,
        namaWP,
      });

      let detailLampIIIB = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IIIB);
      detailLampIIIB = await number.clearFormatBulk(
        detailLampIIIB,
        storage.formatKeys.spt1770.detailLampIIIB.f,
        storage.formatKeys.spt1770.detailLampIIIB.e
      );

      let headerLampIV = storage.get(storage.keys.SPT_1770_HEADER_LAMP_IV);
      headerLampIV = await number.clearFormatBulk(
        headerLampIV,
        storage.formatKeys.spt1770.headerLampIV.f,
        storage.formatKeys.spt1770.headerLampIV.e
      );
      headerLampIV = Object.assign(headerLampIV, {
        tahunPajak,
        kodePembetulan,
        tahunMulai,
        tahunSelesai,
        flagHitung,
        bulanMulai,
        bulanSelesai,
        npwp,
        namaWP,
      });
      if (headerLampIV.jumlahItemA === 0) {
        throw new Error(t.translate("sentence.emptyDaftarHarta"));
      }

      let detailLampIVA = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IVA);
      detailLampIVA = await number.clearFormatBulk(
        detailLampIVA,
        storage.formatKeys.spt1770.detailLampIVA.f,
        storage.formatKeys.spt1770.detailLampIVA.e
      );

      let detailLampIVB = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IVB);
      detailLampIVB = await number.clearFormatBulk(
        detailLampIVB,
        storage.formatKeys.spt1770.detailLampIVB.f,
        storage.formatKeys.spt1770.detailLampIVB.e
      );

      let detailLampIVC = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IVC);

      let phmts = storage.get(storage.keys.SPT_1770_PHMT);
      let phmt = storage.get(storage.keys.SPT_1770_PHMT);
      phmt = await number.clearFormatBulk(
        phmt,
        storage.formatKeys.spt1770.PHMT.f,
        storage.formatKeys.spt1770.PHMT.e
      );
      let PHMT = {
        A: {
          A13: phmt.A13,
          A14: phmt.A14,
          A23: phmt.A23,
          A24: phmt.A24,
          A33: phmt.A33,
          A34: phmt.A34,
          A43: phmt.A43,
          A44: phmt.A44,
          A53: phmt.A53,
          A54: phmt.A54,
          A63: phmt.A63,
          A64: phmt.A64,
          A73: phmt.A73,
          A74: phmt.A74,
          A83: phmt.A83,
          A84: phmt.A84,
        },
        B4: phmt.B4,
        C: {
          C2: phmt.C2,
          C3: phmt.C3,
          C4: phmt.C4,
        },
        D4: phmt.D4,
        E: {
          E12: phmt.E12,
          E13: phmt.E13,
          E22: phmt.E22,
          E23: phmt.E23,
          E32: phmt.E32,
          E33: phmt.E33,
          E42: phmt.E42,
          E43: phmt.E43,
          E4: phmt.E4,
        },
        F4: phmt.F4,
        G4: phmt.G4,
        as: phmts.as,
      };
      if (parseInt(profile.tahunPajak) >= 2022) {
        PHMT.E.E52 = phmt.E52;
        PHMT.E.E53 = phmt.E53;
      }
      let headerSSP = storage.get(storage.keys.SPT_1770_HEADER_SSP);
      headerSSP = await number.clearFormatBulk(
        headerSSP,
        storage.formatKeys.spt1770.headerSSP.f,
        storage.formatKeys.spt1770.headerSSP.e
      );

      let detailSSP = storage.get(storage.keys.SPT_1770_DETAIL_SSP);
      detailSSP = await number.clearFormatBulk(
        detailSSP,
        storage.formatKeys.spt1770.detailSSP.f,
        storage.formatKeys.spt1770.detailSSP.e
      );
      if (induk.E.E19 <= 0) {
        detailSSP = { daftarSSP: [] };
        headerSSP.jumlahItem = 0;
        headerSSP.jumlahSetor = 0;
      }

      setSptData({
        profile,
        induk,
        headerLampI,
        detailLampIA,
        detailLampIB,
        detailLampIC,
        detailLampID,
        headerLampII,
        detailLampII,
        headerLampIII,
        detailLampIIIA,
        detailLampIIIB,
        headerLampIV,
        detailLampIVA,
        detailLampIVB,
        detailLampIVC,

        PHMT,
        headerPP46,
        detailPP46,
        headerSSP,
        detailSSP,
      });
      // let res = await post1770()
      setSaveOnly(saveOnly);
      setShowCommit(true);
      setInProgress("");
    } catch (error) {
      setInProgress("");
      if (error.status === 590) {
        error.message = t.translate(
          "modules.submission.sptForm.errorNotCompleteData"
        );
      }
      toast.errorRequest(error);
    }
  };

  return (
    <TabsWrapper
      title={`SPT 1770 ${submission ? ` | ${submission.fileName}` : ""}`}
      baseId="mod-submission-spt-1770"
      className={className}
      showIndex={true}
      iconAfter={true}
      defaultAsVerticalTabs={false}
      disabledToggleTabsOrientation={false}
      disabledTabsScrollControl={false}
      commandbar={{
        rightCorner: isEditable ? (
          <Flex align={Flex.properties.align.CENTER}>
            <LoadingButton
              theme="warning"
              themeType="contained"
              onClick={handleCancel}
              className="mpk-margin-N margin-right"
              iconClassName="mdi mdi-alert"
              disabled={inProgress !== ""}
            >
              {t.translate("column.cancel")}
            </LoadingButton>
            <ExportSPT
              submission={submission}
              kota={""}
              code={"1770"}
              className="mpk-margin-N margin-right"
            />
            <LoadingButton
              iconClassName="mdi mdi-content-save"
              theme="outline"
              themeType="contained"
              // className="mpk-margin-N margin-right"
              onClick={() => handleSubmit()}
              loading={inProgress === "saving"}
              disabled={inProgress !== ""}
            >
              {t.translate("modules.submission.sptForm.save.title")}
            </LoadingButton>
            {/* <LoadingButton
              theme="primary"
              onClick={() => handleSubmit(false)}
              themeType="outline"
              loading={inProgress === 'committing'}
              disabled={inProgress !== ''}
            >
              {t.translate('modules.submission.sptForm.commit.title')}
            </LoadingButton> */}
          </Flex>
        ) : props.allowPembetulan && !props.authStore.user.isSupport ? (
          <>
            <ExportSPT
              submission={submission}
              kota={""}
              code={"1770"}
              className="mpk-margin-N margin-right"
            />
            <LoadingButton
              iconClassName="mdi mdi-plus"
              theme="outline"
              themeType="contained"
              onClick={() => handlePembetulan()}
            >
              {t.translate("modules.submission.sptForm.pembetulan.title")}
            </LoadingButton>
          </>
        ) : (
          <ExportSPT
            submission={submission}
            kota={""}
            code={"1770"}
            className="mpk-margin-N margin-right"
          />
        ),
        leftCorner: (
          <Button buttonType="icon" onClick={handleBack}>
            <FontIcon iconClassName="mdi mdi-arrow-left" />
          </Button>
        ),
      }}
      tabs={[
        {
          label: t.translate("column.profile"),
          key: "profile",
          iconClassName: getIcon(/Profil/gi),
          render: (tabProps) => (
            <Profile {...tabProps} errorMessage={getErrorMessage(["Profil"])} />
          ),
        },
        {
          label: t.translate("column.lampiranIV"),
          key: "lampiranIV",
          disabled: true,
          iconClassName: getIcon(
            /HeaderLampIV|DetailLampIVA|DetailLampIVB|DetailLampIVC/gi
          ),
          render: (tabProps) => (
            <LampiranIV
              {...tabProps}
              errorMessage={getErrorMessage([
                "HeaderLampIV",
                "DetailLampIVA",
                "DetailLampIVB",
                "DetailLampIVC",
              ])}
            />
          ),
        },
        {
          label: t.translate("column.lampiranIII"),
          key: "lampiranIII",
          disabled: true,
          iconClassName: getIcon(
            /HeaderLampIII|DetailLampIIIA|DetailLampIIIB/gi
          ),
          render: (tabProps) => (
            <LampiranIII
              {...tabProps}
              errorMessage={getErrorMessage([
                "HeaderLampIII",
                "DetailLampIIIA",
                "DetailLampIIIB",
              ])}
            />
          ),
        },
        {
          label: t.translate("column.lampiranII"),
          key: "lampiranII",
          disabled: true,
          iconClassName: getIcon(/HeaderLampII|DetailLampII/gi),
          render: (tabProps) => (
            <LampiranII
              {...tabProps}
              errorMessage={getErrorMessage(["HeaderLampII", "DetailLampII"])}
            />
          ),
        },
        {
          label: t.translate("column.lampiranI"),
          key: "lampiranI",
          disabled: true,
          iconClassName: getIcon(
            /HeaderLampI|DetailLampIA|DetailLampIB|DetailLampIC|DetailLampID/gi
          ),
          render: (tabProps) => (
            <LampiranI
              {...tabProps}
              errorMessage={getErrorMessage([
                "HeaderLampI",
                "DetailLampIA",
                "DetailLampIB",
                "DetailLampIC",
                "DetailLampID",
              ])}
            />
          ),
        },
        {
          label: "PP46",
          key: "pp46",
          disabled: true,
          iconClassName: getIcon(/HeaderPP46|DetailPP46/gi),
          render: (tabProps) => (
            <PP46
              {...tabProps}
              errorMessage={getErrorMessage(["HeaderPP46", "DetailPP46"])}
            />
          ),
        },
        {
          label: "PHMT",
          key: "phmt",
          disabled: true,
          iconClassName: getIcon(/PHMT/gi),
          render: (tabProps) => (
            <PHMT {...tabProps} errorMessage={getErrorMessage(["PHMT"])} />
          ),
        },
        {
          label: t.translate("column.induk"),
          key: "induk",
          disabled: true,
          iconClassName: getIcon(/Induk/gi),
          render: (tabProps) => (
            <Induk
              {...tabProps}
              errorMessage={getErrorMessage(["Induk"])}
              setSsp={setSsp}
            />
          ),
        },
        {
          label: "SSP",
          key: "ssp",
          disabled: true,
          iconClassName: getIcon(/HeaderSSP|DetailSSP/gi),
          render: (tabProps) => (
            <SSP
              {...tabProps}
              errorMessage={getErrorMessage(["HeaderSSP", "DetailSSP"])}
              setSsp={setSsp}
              setBillingCode={setBillingCode}
            />
          ),
        },
      ]}
      onChange={(tab, callback) => {
        props.navigationStore.redirectTo(
          `/${props.envStore.env.applicationType}/submission/spt-1770/${tab.key}`
        );
        callback();
      }}
    />
  );
};

export default sptWrapper({
  code: "1770",
  onInit: (props) => {
    return new Promise(async (resolve) => {
      await Profile.generate(props.authStore.user, props.envStore.env);
      await Induk.generate(props.envStore.env.timeStamp);
      await LampiranI.generate();
      await LampiranII.generate();
      await LampiranIII.generate();
      await LampiranIV.generate();
      await PHMT.generate();
      await PP46.generate();
      await SSP.generate();
      resolve();
    });
  },
})(Form1770);
