
import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number, utils } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import { Button } from 'react-md'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import moment from 'moment'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_DETAIL_SSP, {
    daftarSSP:[]
  })

  return data
}

const { inputTypes } = DataForm
const DetailSSP = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})

  const initDefaultData = (forceUpdate=false) => {
    let defData = storage.get(storage.keys.SPT_1770_DETAIL_SSP)
    if(forceUpdate) DataForm.forceUpdateData('spt-1770-header-lamp-IC', defData);
    else setDefaultData(defData)
  }

  useEffect(() => {
    initDefaultData()
    setReady(true)

    window.addEventListener('mpn-update', () => initDefaultData(true))
    return function cleanup(){
      window.removeEventListener('mpn-update', () => {}, false)
    }
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-IC"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.LIST,
          label: t.translate('column.daftarSSP'),
          key:'daftarSSP',
          defaultData: {
            kodeMataAnggaranPenerimaan: "411125",
            kodeJenisSetoran: "200",
            tanggalSSP: "",
            jumlahSSP: "0",
            ntpn: ""
          },
          definitions:[
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailSSP.KD_MAP'),
              key: 'kodeMataAnggaranPenerimaan',
              validation:'digits:6',
              disabled: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailSSP.KD_JNS_STR'),
              key: 'kodeJenisSetoran',
              validation: 'digits:3',
              disabled: true
            },
            {
              inputType: inputTypes.DATE,
              label: t.translate('modules.submission.sptForm.1770.DetailSSP.TGL_SSP'),
              key: 'tanggalSSP',
              type: 'date',
              max: moment(props.envStore.env.timeStamp).format('YYYY-MM-DD'),
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailSSP.JML_SSP'),
              key: 'jumlahSSP',
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('column.billingCode'),
              key: 'billingCode',
              disabled: true,
              show: (d) => (d.billingCode ? true : false)
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailSSP.NTPN'),
              key: 'ntpn',
              validation: 'max:16|min:16',
              required: true
            },
          ]
        }
      ]}
      onBeforeChange={(key, value) => {
        value = ['jumlahSSP'].indexOf(key) >= 0
          ? number.format(number.clearFormat(value.replace(/\D/g, '')))
          : ['kodeJenisSetoran', 'kodeMataAnggaranPenerimaan'].indexOf(key) >= 0 
            ? value.replace(/\D/g, '')
            : value
        return value
      }}
      onSubmit={(data, callback) => {
        let isDuplicate = utils.isDuplicateObjectInArray(data.daftarSSP, ['ntpn'])

        if(isDuplicate){
          callback(new Error(t.translate('sentence.duplicateSSP')))
        } else {
          props.onSave(storage.keys.SPT_1770_DETAIL_SSP, data)
          callback(t.translate('sentence.savedItem'), false)
        }
      }}
      submitLabel={t.translate('column.save')}
      additionalListAction={d => d.billingCode ? (
        <Button
          theme="primary"
          onClick={() => props.setBillingCode(d.billingCode)}
        >
          {t.translate('column.checkMpn')}
        </Button>
      ) : null}
    />
  ) : <LoaderInfo/>
}

DetailSSP.generate = generate

export default inject('envStore')(observer(DetailSSP))
