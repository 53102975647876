import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770S_HEADER_SSP, {
    jumlahItem: "0",
    jumlahSetor: "0"
  })

  return data
}

const { inputTypes } = DataForm
const HeaderSSP = (props) => {
  const induk = storage.get(storage.keys.SPT_1770S_INDUK)
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  useEffect(() => {
    let header = storage.get(storage.keys.SPT_1770S_HEADER_SSP)
    setDefaultData(header)
    setTimeout(() => setReady(true))
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770S-header-SSP"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      hintMessage={induk.E.flagE16 === '1' ? t.translate('sentence.headerLampiranHintText') : t.translate('modules.submission.nonKurangBayarMessage')}
      asDialog={false}
      editable={props.isEditable}
      defaultData={defaultData}
      definitions={induk.E.flagE16 === '1' ? [
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770S.HeaderSSP.JmlItem'),
          key:'jumlahItem',
          disabled: true
        },
         {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770S.HeaderSSP.JmlSetor'),
          key:'jumlahSetor',
          disabled: true
        }
      ] : []}
      submitLabel={t.translate('column.next')}
      submitIconClassName="mdi mdi-arrow-right"
      onSubmit={null}
    />
  ) : <LoaderInfo/>
}

HeaderSSP.generate = generate

export default HeaderSSP
