import React, {useEffect, useState} from 'react'
import { ExpansionList, ExpansionPanel, usePanels } from 'react-md'
import { storage } from '../../../../../services'
import { LoaderInfo, Hint } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import HeaderLampI from './HeaderLampI'
import DetailLampIA from './DetailLampIA'
import DetailLampIB from './DetailLampIB'
import DetailLampIC from './DetailLampIC'

const generate = () => {
  HeaderLampI.generate()
  DetailLampIA.generate()
  DetailLampIB.generate()
  DetailLampIC.generate()
}

const LampiranI = (props) => {
  const [ready, setReady] = useState(false)
  const [panels, onKeyDown] = usePanels({
    idPrefix: 'spt-1770S-lampiran-I',
    count:4,
    defaultExpandedIndex: 0
  })

  const handleSave = (key, update) => {
    storage.update(key, update);
    updateHeader()
  }

  const updateHeader = () => {
    let header = storage.get(storage.keys.SPT_1770S_HEADER_LAMP_I)
    let detailA = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IA)
    let detailB = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IB)
    let detailC = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IC)

    header.jumlahBA = detailA ? number.sumFormatted(
      detailA.A.A13,
      detailA.A.A23,
      detailA.A.A33,
      detailA.A.A43,
      detailA.A.A53,
      detailA.A.A63,
    ) : '0'

    header.jumlahBB = detailB ? number.sumFormatted(
      detailB.B.B13,
      detailB.B.B23,
      detailB.B.B33,
      detailB.B.B43,
      detailB.B.B53,
      detailB.B.B63,
    ): '0'

    header.jumlahItemC = detailC ? String(detailC.daftarBuktiPotong.length) : '0'
    header.jumlahBC = detailC ? number.sumFormatted(...detailC.daftarBuktiPotong.map(d => (d.jumlahPPH))) : '0'

    storage.set(storage.keys.SPT_1770S_HEADER_LAMP_I, header)
  }

  useEffect(() => {
    updateHeader()
    setReady(true)
  }, [])

  const [panel1Props, panel2Props, panel3Props,panel4Props] = panels;
  return (
    <div 
      className="mod-submission-spt-1770S-lampiran-I mpk-margin-C"
      style={{
        width: '100%',
        maxWidth: 800
      }}
    >
      { ready ? (
        <div>
          {props.errorMessage && 
            <div className="mpk-paper mpk-padding-N padding-all">
              <Hint>{props.errorMessage}</Hint>
            </div>
          }
          <ExpansionList onKeyDown={onKeyDown}>
            {[
              {
                label: t.translate('modules.submission.sptForm.1770S.DetailLampIA.title'),
                render: (<DetailLampIA onSave={handleSave} {...props}/>),
                panelProps: panel1Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770S.DetailLampIB.title'),
                render: (<DetailLampIB onSave={handleSave} {...props}/>),
                panelProps: panel2Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770S.DetailLampIC.title'),
                render: (<DetailLampIC onSave={handleSave} {...props}/>),
                panelProps: panel3Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770S.HeaderLampI.title'),
                render: (<HeaderLampI {...props}/>),
                panelProps: panel4Props
              },
            ].map((p, i) => (
              <ExpansionPanel 
                key={`spt-1770-induk-panel-${i}`}
                {...p.panelProps}
                header={<div className="mpk-font size-M weight-M">{p.label}</div>}
              >
                {p.render}
              </ExpansionPanel>
            ))}
          </ExpansionList>
        </div>
      ) : <LoaderInfo/> }  
    </div>
  )
}

LampiranI.generate = generate

export default inject('navigationStore', 'envStore', 'authStore')(observer(LampiranI))
