import React from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import t from 'counterpart'
import { storage } from '../../../../../services'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm

const BagianF = ({
  defaultData = {},
  ...props
}) => {
  const induk = storage.get(storage.keys.SPT_1770S_INDUK)
  const getUpdateData = (data) => {
    const F18 = data.flagF18 === '0' 
      ?  '0'
      : data.flagF18 === '1'
        ? number.clearFormat(induk.D.D13)/12
        : number.clearFormat(data.F18)

    data.F18 = number.format(Math.floor(F18))
    return data
  }

  return (
    <DataForm
      baseId={`spt-1770S-induk-F`}
      className="mpk-padding-NONE padding-all"
      asDialog={false}
      editable={props.isEditable}
      defaultData={{...defaultData, ...getUpdateData(defaultData)}}
      definitions={[
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.FlgF18`),
          key: 'flagF18',
          options: props.envStore.env.spt.FlgF18
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.F18`),
          key: 'F18',
          show: d => (d.flagF18 !== '0')
        },
      ]}
      onBeforeChange={(key, value) => (
        number.format(number.clearFormat(value.replace(/\D/g, '')))
      )}
      onChange={data => {
        const update = getUpdateData(data)
        return {...data, ...update}
      }}
      onSubmit={(data, callback) => {
        props.onSave({F:data});
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  )
}

export default inject('envStore')(observer(BagianF))
