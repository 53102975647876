import React from 'react'
import { DashboardWrapper } from '../../libs/react-mpk/wrapper'
import * as service from './DashboardSample.service'

const DashboardSample = () => {
  return (
    <DashboardWrapper
      baseId="mod-dashboard-sample-id"
      title="Dashboard Sample"
      className="mpk-full full-height"
      definitions={[
        new DashboardWrapper.definition(null, [
          new DashboardWrapper.summaries(
            service.getSummary, 
            data => (data.map(d => (
              new DashboardWrapper.summaryData(d.label, d.value, d.unit, d.trend, d.trendUnit)
            )))
          )
        ], [
          new DashboardWrapper.chart(
            'Chart Sample',
            () => (new Promise(resolve => resolve({data: [
              {
                category: 'A',
                key: '2021-1-1',
                value: 100
              },
              {
                category: 'A',
                key: '2021-1-2',
                value: 200
              },
              {
                category: 'B',
                key: '2021-1-1',
                value: 150
              },
              {
                category: 'B',
                key: '2021-1-1',
                value: 350
              }
            ]}))),
            data => (data.map(d => (
              new DashboardWrapper.chartData(d.category, d.key, d.value, {type: 'scatter'}, {})
            )))
          )
        ])
      ]}
    />
  )
}

export default DashboardSample
