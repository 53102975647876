import React, { useState } from "react";
import { TabsWrapper } from "../../../../libs/react-mpk/wrapper";
import { LoadingButton, Flex } from "../../../../libs/react-mpk/components";
import t from "counterpart";
import Profile from "./Profile";
import { storage } from "../../../../services";
import LampiranI from "./LampiranI";
import LampiranII from "./LampiranII";
import SSP from "./SSP";
import Induk from "./Induk";
import PHMT from "./PHMT";
import moment from "moment";
import { Button, FontIcon } from "react-md";
import { toast, number } from "../../../../libs/react-mpk/services";
import sptWrapper from "../spt.wrapper";
import ExportSPT from "./ExportSPT";

const Form1770S = ({
  className = "",
  handleBack,
  handleCancel,
  handlePembetulan,
  getIcon,
  getErrorMessage,
  setSptData,
  setShowCommit,
  setSaveOnly,
  setSsp,
  setBillingCode,
  submission,
  isEditable,
  ...props
}) => {
  const [inProgress, setInProgress] = useState("");
  let induk = storage.get(storage.keys.SPT_1770S_INDUK);
  // console.log("🚀 ~ file: SPT1770S.js:36 ~ induk", induk);
  const handleSubmit = (saveOnly = true) => {
    let { timestamp } = props.envStore.env;
    let profile = storage.get(storage.keys.SPT_1770S_PROFILE);
    let induk = storage.get(storage.keys.SPT_1770S_INDUK);
    if (
      String(induk.E.flagE16) === "2" &&
      Number(profile.tahunPajak) < Number(moment(timestamp).year()) - 3
    ) {
      props.modalStore.showConfirm({
        title: t.translate("column.alert"),
        children: t.translate("sentence.sspLB3yearAlert"),
        onSubmit: (callback) => {
          callback();
          execute(saveOnly);
        },
        dialogStyle: {
          maxWidth: 480,
        },
      });
    } else {
      execute(saveOnly);
    }
  };

  const execute = async (saveOnly = true) => {
    setInProgress(saveOnly ? "saving" : "comitting");
    try {
      let profile = storage.get(storage.keys.SPT_1770S_PROFILE);
      profile = await number.clearFormatBulk(
        profile,
        storage.formatKeys.spt1770S.profile.f,
        storage.formatKeys.spt1770S.profile.e
      );
      profile.tahunPajak = Number(profile.tahunPajak);
      profile.kodePembetulan = Number(profile.kodePembetulan);
      profile.namaWP =
        profile.namaWP.length < 4
          ? String(profile.namaWP).split("").toString().replace(/\,/g, " ")
          : profile.namaWP;
      let { tahunPajak, kodePembetulan, npwp, namaWP } = profile;
      delete profile.kodeForm;
      delete profile.tahunSelesai;
      profile.fax = profile.fax === "" ? "0" : profile.fax;
      profile.fghs = profile.fghs ? "1" : "0";

      let induk = storage.get(storage.keys.SPT_1770S_INDUK);
      induk = await number.clearFormatBulk(
        induk,
        storage.formatKeys.spt1770S.induk.f,
        storage.formatKeys.spt1770S.induk.e
      );
      induk.E.E16TanggalLunas =
        induk.E.flagE16 !== "1" ? "" : induk.E.E16TanggalLunas;
      delete induk.kodeForm;
      if (induk.C.checkSelfCount) {
        induk.C.checkSelfCount = induk.C.checkSelfCount ? "1" : "0";
        if (induk.C.checkSelfCount === "1") {
          if (!induk.checkList.checkGe) {
            throw new Error(
              t.translate(
                "modules.submission.sptForm.1770S.Induk.validasiPenghitunganSendiri"
              )
            );
          }
        }
      } else {
        induk.C.checkSelfCount = "0";
      }
      for (let key of Object.keys(induk.checkList)) {
        if (key.startsWith("check")) {
          induk.checkList[key] = induk.checkList[key] ? "1" : "0";
        }
      }

      let headerLampI = storage.get(storage.keys.SPT_1770S_HEADER_LAMP_I);
      headerLampI = await number.clearFormatBulk(
        headerLampI,
        storage.formatKeys.spt1770S.headerLampI.f,
        storage.formatKeys.spt1770S.headerLampI.e
      );
      headerLampI = Object.assign(headerLampI, { tahunPajak, npwp, namaWP });
      delete headerLampI.kodeForm;

      let detailLampIA = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IA);
      detailLampIA = await number.clearFormatBulk(
        detailLampIA,
        storage.formatKeys.spt1770S.detailLampIA.f,
        storage.formatKeys.spt1770S.detailLampIA.e
      );

      let detailLampIB = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IB);
      detailLampIB = await number.clearFormatBulk(
        detailLampIB,
        storage.formatKeys.spt1770S.detailLampIB.f,
        storage.formatKeys.spt1770S.detailLampIB.e
      );

      let detailLampIC = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IC);
      detailLampIC = await number.clearFormatBulk(
        detailLampIC,
        storage.formatKeys.spt1770S.detailLampIC.f,
        storage.formatKeys.spt1770S.detailLampIC.e
      );
      detailLampIC.daftarBuktiPotong = detailLampIC.daftarBuktiPotong.map(
        (d) => {
          d.tanggalBukti = moment(d.tanggalBukti).format("YYYY-MM-DD");
          return d;
        }
      );

      let headerLampII = storage.get(storage.keys.SPT_1770S_HEADER_LAMP_II);
      headerLampII = await number.clearFormatBulk(
        headerLampII,
        storage.formatKeys.spt1770S.headerLampII.f,
        storage.formatKeys.spt1770S.headerLampII.e
      );
      headerLampII = Object.assign(headerLampII, { tahunPajak, npwp, namaWP });
      delete headerLampII.kodeForm;
      if (headerLampII.jumlahItemB === 0) {
        throw new Error(t.translate("sentence.emptyDaftarHarta"));
      }

      let detailLampIIA = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IIA);
      detailLampIIA = await number.clearFormatBulk(
        detailLampIIA,
        storage.formatKeys.spt1770S.detailLampIIA.f,
        storage.formatKeys.spt1770S.detailLampIIA.e
      );

      const {
        A13,
        A14,
        A23,
        A24,
        A33,
        A34,
        A43,
        A44,
        A53,
        A54,
        A63,
        A64,
        A73,
        A74,
        A83,
        A84,
        A93,
        A94,
        A103,
        A104,
        A113,
        A114,
        A123,
        A124,
        A133,
        A134,
        A143,
        A144,
      } = detailLampIIA;
      detailLampIIA = {
        A1: { A13, A14 },
        A2: { A23, A24 },
        A3: { A33, A34 },
        A4: { A43, A44 },
        A5: { A53, A54 },
        A6: { A63, A64 },
        A7: { A73, A74 },
        A8: { A83, A84 },
        A9: { A93, A94 },
        A10: { A103, A104 },
        A11: { A113, A114 },
        A12: { A123, A124 },
        A13: { A133, A134 },
        A14: { A143, A144 },
      };

      let detailLampIIB = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IIB);
      detailLampIIB = await number.clearFormatBulk(
        detailLampIIB,
        storage.formatKeys.spt1770S.detailLampIIB.f,
        storage.formatKeys.spt1770S.detailLampIIB.e
      );

      let detailLampIIC = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IIC);
      detailLampIIC = await number.clearFormatBulk(
        detailLampIIC,
        storage.formatKeys.spt1770S.detailLampIIC.f,
        storage.formatKeys.spt1770S.detailLampIIC.e
      );

      let detailLampIID = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IID);

      let phmts = storage.get(storage.keys.SPT_1770S_PHMT);
      let phmt = storage.get(storage.keys.SPT_1770S_PHMT);
      phmt = await number.clearFormatBulk(
        phmt,
        storage.formatKeys.spt1770S.PHMT.f,
        storage.formatKeys.spt1770S.PHMT.e
      );
      let PHMT = {
        A: {
          A13: phmt.A13,
          A14: phmt.A14,
          A23: phmt.A23,
          A24: phmt.A24,
          A33: phmt.A33,
          A34: phmt.A34,
          A43: phmt.A43,
          A44: phmt.A44,
          A53: phmt.A53,
          A54: phmt.A54,
          A63: phmt.A63,
          A64: phmt.A64,
          A73: phmt.A73,
          A74: phmt.A74,
          A83: phmt.A83,
          A84: phmt.A84,
        },
        B4: phmt.B4,
        C: {
          C2: phmt.C2,
          C3: phmt.C3,
          C4: phmt.C4,
        },
        D4: phmt.D4,
        E: {
          E12: phmt.E12,
          E13: phmt.E13,
          E22: phmt.E22,
          E23: phmt.E23,
          E32: phmt.E32,
          E33: phmt.E33,
          E42: phmt.E42,
          E43: phmt.E43,
          E4: phmt.E4,
        },
        F4: phmt.F4,
        G4: phmt.G4,
        as: phmts.as,
      };
      if (parseInt(profile.tahunPajak) >= 2022) {
        PHMT.E.E52 = phmt.E52;
        PHMT.E.E53 = phmt.E53;
      }
      delete PHMT.kodeForm;

      let headerSSP = storage.get(storage.keys.SPT_1770S_HEADER_SSP);
      headerSSP = await number.clearFormatBulk(
        headerSSP,
        storage.formatKeys.spt1770S.headerSSP.f,
        storage.formatKeys.spt1770S.headerSSP.e
      );
      delete headerSSP.kodeForm;

      let detailSSP = storage.get(storage.keys.SPT_1770S_DETAIL_SSP);
      detailSSP = await number.clearFormatBulk(
        detailSSP,
        storage.formatKeys.spt1770S.detailSSP.f,
        storage.formatKeys.spt1770S.detailSSP.e
      );
      if (induk.E.E16 <= 0) {
        detailSSP = { daftarSSP: [] };
        headerSSP.jumlahItem = 0;
        headerSSP.jumlahSetor = 0;
      }

      setSptData({
        profile,
        induk,
        headerLampI,
        detailLampIA,
        detailLampIB,
        detailLampIC,
        headerLampII,
        detailLampIIA,
        detailLampIIB,
        detailLampIIC,
        detailLampIID,
        PHMT,
        headerSSP,
        detailSSP,
      });

      setSaveOnly(saveOnly);
      setShowCommit(true);
      setInProgress("");
    } catch (error) {
      setInProgress("");
      if (error.status === 590) {
        error.message = t.translate(
          "modules.submission.sptForm.errorNotCompleteData"
        );
      }
      toast.errorRequest(error);
    }
  };

  return (
    <TabsWrapper
      title={`SPT 1770S ${submission ? ` | ${submission.fileName}` : ""}`}
      baseId="mod-submission-spt-1770"
      className={className}
      showIndex={true}
      iconAfter={true}
      defaultAsVerticalTabs={false}
      disabledToggleTabsOrientation={false}
      disabledTabsScrollControl={false}
      commandbar={{
        rightCorner: isEditable ? (
          <Flex align={Flex.properties.align.CENTER}>
            <LoadingButton
              theme="warning"
              themeType="contained"
              onClick={handleCancel}
              className="mpk-margin-N margin-right"
              iconClassName="mdi mdi-alert"
              disabled={inProgress !== ""}
            >
              {t.translate("column.cancel")}
            </LoadingButton>
            <ExportSPT
              submission={submission}
              // kota={induk.kota}
              kota={""}
              code={"1770S"}
              className="mpk-margin-N margin-right"
            />
            <LoadingButton
              iconClassName="mdi mdi-content-save"
              theme="outline"
              themeType="contained"
              onClick={() => handleSubmit()}
              loading={inProgress === "saving"}
              disabled={inProgress !== ""}
            >
              {t.translate("modules.submission.sptForm.save.title")}
            </LoadingButton>
            {/* <LoadingButton
                theme="primary"
                onClick={() => handleSubmit(false)}
                themeType="outline"
                loading={inProgress === 'committing'}
                disabled={inProgress !== ''}
              >
                {t.translate('modules.submission.sptForm.commit.title')}
              </LoadingButton> */}
          </Flex>
        ) : props.allowPembetulan && !props.authStore.user.isSupport ? (
          <>
            <ExportSPT
              submission={submission}
              // kota={induk.kota}
              kota={""}
              code={"1770S"}
              className="mpk-margin-N margin-right"
            />
            <LoadingButton
              iconClassName="mdi mdi-plus"
              theme="outline"
              themeType="contained"
              onClick={() => handlePembetulan()}
            >
              {t.translate("modules.submission.sptForm.pembetulan.title")}
            </LoadingButton>
          </>
        ) : (
          <ExportSPT
            submission={submission}
            // kota={induk.kota}
            kota={""}
            code={"1770S"}
            className="mpk-margin-N margin-right"
          />
        ),
        leftCorner: (
          <Button buttonType="icon" onClick={handleBack}>
            <FontIcon iconClassName="mdi mdi-arrow-left" />
          </Button>
        ),
      }}
      tabs={[
        {
          label: t.translate("column.profile"),
          iconClassName: getIcon(/profil/gi),
          key: "profile",
          render: (tabProps) => (
            <Profile
              {...tabProps}
              isEditable={isEditable}
              errorMessage={getErrorMessage(["profil"])}
            />
          ),
        },
        {
          label: t.translate("column.lampiranII"),
          iconClassName: getIcon(
            /HeaderLampII|DetailLampIIA|DetailLampIIB|DetailLampIIC|DetailLampIID/gi
          ),
          key: "lampiranII",
          disabled: true,
          render: (tabProps) => (
            <LampiranII
              {...tabProps}
              isEditable={isEditable}
              errorMessage={getErrorMessage([
                "HeaderLampII",
                "DetailLampIIA",
                "DetailLampIIB",
                "DetailLampIIC",
                "DetailLampIID",
              ])}
            />
          ),
        },
        {
          label: t.translate("column.lampiranI"),
          iconClassName: getIcon(
            /HeaderLampI|DetailLampIA|DetailLampIB|DetailLampIC/gi
          ),
          key: "lampiranI",
          disabled: true,
          render: (tabProps) => (
            <LampiranI
              {...tabProps}
              isEditable={isEditable}
              errorMessage={getErrorMessage([
                "HeaderLampI",
                "DetailLampIA",
                "DetailLampIB",
                "DetailLampIC",
              ])}
            />
          ),
        },
        {
          label: "PHMT",
          iconClassName: getIcon(/PHMT/gi),
          key: "phmt",
          disabled: true,
          render: (tabProps) => (
            <PHMT
              {...tabProps}
              isEditable={isEditable}
              errorMessage={getErrorMessage(["PHMT"])}
            />
          ),
        },
        {
          label: t.translate("column.induk"),
          iconClassName: getIcon(/Induk/gi),
          key: "induk",
          disabled: true,
          render: (tabProps) => (
            <Induk
              {...tabProps}
              isEditable={isEditable}
              errorMessage={getErrorMessage(["Induk"])}
              setSsp={setSsp}
            />
          ),
        },
        {
          label: "SSP",
          iconClassName: getIcon(/HeaderSSP|DetailSSP/gi),
          key: "ssp",
          disabled: true,
          render: (tabProps) => (
            <SSP
              {...tabProps}
              isEditable={isEditable}
              errorMessage={getErrorMessage(["HeaderSSP", "DetailSSP"])}
              setSsp={setSsp}
              setBillingCode={setBillingCode}
            />
          ),
        },
      ]}
      onChange={(tab, callback) => {
        props.navigationStore.redirectTo(
          `/${props.envStore.env.applicationType}/submission/spt-1770S/${tab.key}`
        );
        callback();
      }}
    />
  );
};

export default sptWrapper({
  code: "1770S",
  onInit: (props) => {
    return new Promise(async (resolve) => {
      await Profile.generate(props.authStore.user, props.envStore.env);
      await LampiranI.generate();
      await LampiranII.generate();
      await SSP.generate();
      await Induk.generate(props.envStore.env.timeStamp);
      await PHMT.generate();
      resolve();
    });
  },
})(Form1770S);
