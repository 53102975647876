import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number, utils } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_DETAIL_LAMP_IVA, {
    daftarHarta: []
  })

  return data
}

const { inputTypes } = DataForm
const DetailLampIVA = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IVA)
    setDefaultData(defData)
    setReady(true)
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-IVA"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.LIST,
          label: t.translate('column.daftarHarta'),
          key:'daftarHarta',
          defaultData: {
            kodeHarta: props.envStore.env.spt.JNS_HARTA[0].value,
            jenisHarta: props.envStore.env.spt.JNS_HARTA[0].label,
            tahunPerolehan: "",
            hargaPerolehan: "0",
            keterangan: ""
          },
          definitions:[
            {
              inputType: inputTypes.SELECT,
              label: t.translate('modules.submission.sptForm.1770.DetailLampIVA.JNS_HARTA'),
              key: 'kodeHarta',
              options: props.envStore.env.spt.JNS_HARTA,
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailLampIVA.TH_PEROLEHAN'),
              key: 'tahunPerolehan',
              validation:'digits:4',
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailLampIVA.HARGA_PEROLEHAN'),
              key: 'hargaPerolehan',
              required: true

            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailLampIVA.KETERANGAN'),
              key: 'keterangan',
              validation: 'max:50|min:1',
              required: true
            },
          ]
        }
      ]}
      onBeforeChange={(key, value, parentIndex, changeOther) => {
        value = ['hargaPerolehan'].indexOf(key) >= 0
          ? number.format(number.clearFormat(value.replace(/\D/g, '')))
          : ['kodeHarta', 'tahunPerolehan'].indexOf(key) >= 0 
            ? value.replace(/\D/g, '')
            : value

        if(key === 'kodeHarta'){
          let harta = find(props.envStore.env.spt.JNS_HARTA, {value})
          changeOther({jenisHarta: harta.label.toUpperCase()})
        }
        return value
      }}
      onSubmit={(data, callback) => {
        let isDuplicate = utils.isDuplicateObjectInArray(data.daftarHarta, ['kodeHarta', 'jenisHarta', 'tahunPerolehan', 'hargaPerolehan'])

        props.onSave(storage.keys.SPT_1770_DETAIL_LAMP_IVA, data)
        callback(t.translate(isDuplicate 
          ? 'sentence.duplicateHarta'
          :'sentence.savedItem'), 
        false)
      }}
      submitLabel={t.translate('column.save')}
    />
  ) : <LoaderInfo/>
}

DetailLampIVA.generate = generate

export default inject('envStore')(observer(DetailLampIVA))
