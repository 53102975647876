import React, { useState } from 'react'
import { DataForm, LoadingButton } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import t from 'counterpart'
import { storage } from '../../../../../services'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
// import { Button } from 'react-md'
// import MPN from '../../../MPN'

const { inputTypes } = DataForm

const BagianE = ({
  defaultData = {},
  ...props
}) => {
  const induk = storage.get(storage.keys.SPT_1770S_INDUK)
  const init = storage.get(storage.keys.SPT_INIT)
  const getUpdateData = (data) => {
    if(number.clearFormat(data.E16) >= 0) data.E17 = ''
    else data.E17 = data.E17 === '' ? '1' : data.E17
    return data
  }

  return (
    <>
      <DataForm
        baseId={`spt-1770S-induk-E`}
        className="mpk-padding-NONE padding-all"
        asDialog={false}
        editable={props.isEditable}
        defaultData={{...defaultData, ...getUpdateData(defaultData)}}
        definitions={[
          {
            inputType: inputTypes.SELECT,
            label: t.translate(`modules.submission.sptForm.1770S.Induk.FlgE16`),
            key: 'flagE16',
            options: props.envStore.env.spt.FlgE16,
            disabled: true
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`modules.submission.sptForm.1770S.Induk.E16`),
            key: 'E16',
            readOnly: true
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`modules.submission.sptForm.1770S.Induk.E16TglLunas`),
            key: 'E16TanggalLunas',
            type: 'date',
            required: true,
            show: induk.E.flagE16 === '1',
            max: moment(props.envStore.env.timeStamp).format('YYYY-MM-DD'),
          },
          {
            inputType: inputTypes.SELECT,
            label: t.translate(`modules.submission.sptForm.1770S.Induk.E17`),
            key: 'E17',
            options: props.envStore.env.spt.E20,
            show: induk.E.flagE16 === '2'
          },
        ]}
        onBeforeChange={(key, value) => (
          ['E16TanggalLunas'].indexOf(key) >= 0 ? value : number.format(number.clearFormat(value.replace(/\D/g, '')))
        )}
        onChange={(data, key) => {
          const update = getUpdateData(data, key)
          return {...data, ...update}
        }}
        onSubmit={(data, callback) => {
          props.onSave({E:data});
          callback(t.translate('sentence.savedItem'), false)
        }}
        submitLabel={t.translate('column.save')}
        additionalAction={[
          {
            show: (d) => (String(d.flagE16) === '1'),
            render: (d) => (
              <LoadingButton 
                themeType="outline"
                iconClassName="mdi mdi-credit-card-outline"
                onClick={() => {
                  props.setSsp({
                    npwp: init.npwp,
                    tahunPajak: init.tahunPajak,
                    kap: '411125',
                    kjs: '200',
                    masaAwal: '01',
                    masaAkhir: '12',
                    amount: number.clearFormat(d.E16)
                  })
                }}
              >
                {t.translate('column.pay')}
              </LoadingButton>
            )
          }
        ]}
      />
    </>
  )
}

export default inject('envStore')(observer(BagianE))
