import React from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import t from 'counterpart'
import { storage } from '../../../../../services'
import { inject, observer } from 'mobx-react'

const { inputTypes } = DataForm

const BagianD = ({
  defaultData = {},
  ...props
}) => {
  const indukData = storage.get(storage.keys.SPT_1770_INDUK)
  const getUpdateData = (data) => {
    data.D18 = number.sumFormatted(data.D17a, data.D17b)
    return data
  }

  return (
    <DataForm
      baseId={`spt-1770-induk-D`}
      className="mpk-padding-NONE padding-all"
      asDialog={false}
      defaultData={{...defaultData, ...getUpdateData(defaultData)}}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.D15`),
          helptext: t.translate(`modules.submission.sptForm.1770.Induk.D15Help`),
          key: 'D15',
          disabled: true
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.FlgD16`),
          key: 'flagD16',
          options: props.envStore.env.spt.FlgD16,
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: (d) => (t.translate(`modules.submission.sptForm.1770.Induk.D16-${d.flagD16}`)),
          key: 'D16',
          readOnly: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.D17a`),
          key: 'D17a',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.D17b`),
          key: 'D17b',
        },
         {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.D18`),
          key: 'D18',
          disabled: true
        }
      ]}
      onBeforeChange={(key, value) => (
        number.format(number.clearFormat(value.replace(/\D/g, '')))
      )}
      onChange={data => {
        const update = getUpdateData(data)
        return {...data, ...update}
      }}
      onSubmit={(data, callback) => {
        props.onSave({D:data});
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  )
}

export default inject('envStore')(observer(BagianD))
