import React, {useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import { LoadingButton } from '../../libs/react-mpk/components'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service, {parseFilename, getByReferenceNo, getHistory, getRawData, downloadBpe, supportGet, supportGetRawData} from './Submission.service'
import { SPTForm } from './SPTForm'
import { storage } from '../../services'
import { toast } from '../../libs/react-mpk/services'
import History from './History'
import moment from 'moment'
import BPE from './BPE/BPE'
import Commit from './SPTForm/Commit'
import * as Certificate from '../Certificate'

const baseId = "mod-submission-list"
const statusTypes = TableWrapper.Status.types

const initSubmission = item => {
  let meta = parseFilename(item.fileName)
  return Object.assign(item, meta)
}

const List = ({
  className   = '',
  ...props
}) => {
  const [showSPTForm, setShowSPTForm] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [showBpe, setShowBpe] = useState(false)
  const [showCommit, setShowCommit] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const updateDataItem = async (item) => {
    try {
      let res = await getByReferenceNo(item.referenceNo, props.authStore.user.isSupport)
      let itemData = initSubmission(res.data)
      TableWrapper.updateDataItem(baseId, {referenceNo: item.referenceNo}, itemData)
    } catch (error) {
      toast.errorRequest(error)
    }
  }

  const parseStatus = (item) => {
    // if(item.history){
      if(item.isNtte){
        return {
          flag: statusTypes.SUCCESS,
          label: t.translate('column.success'),
          onClick: () => {
            setShowBpe(true)
            setSelectedItem(item)
          }
        }
      } else if(item.history) {
        const { error } = item.history
        const { isError } = item.audit
        return {
          flag: isError ? statusTypes.ERROR : statusTypes.PROGRESS,
          error,
          label: isError 
            ? t.translate('column.failed') 
            : t.translate('column.inProcess'),
          // onClick: item.isNtte 
          //   ? () => {
          //     setShowBpe(true)
          //     setSelectedItem(item)
          //   }//downloadBpe(item.referenceNo)
          //   : item.audit.checkStatus
          //     ? () => {
          //         updateDataItem(item)
          //       }
          //     : null
        }
      } else {
        return {
          flag: statusTypes.IDLE,
          label: t.translate('column.draft')
        }
      }
      
      

      // if(isError){
      //   result.error = error
      // }else if(item.djpResponse){
      //   result.label = item.djpResponse.message
      // }

    // } else return {
    //   flag: statusTypes.IDLE,
    //   error: null,
    //   label: t.translate('sentence.sptNoHistory'),
    //   onClick: () => {
    //     handleGetRawData(item.fileName)
    //   }
    // }
  }

  const handleGetRawData = async item => {
    try {
      let res = await (props.authStore.user.isSupport ? supportGetRawData(item.fileName) : getRawData(item.fileName))
      let code = props.envStore.env.spt.code[item.sptCode]
      if(code){
        await storage.populate(code, res.data.data)
        storage.set(storage.keys.SPT_SUBMISSION, item)
        props.navigationStore.redirectTo(`/${props.envStore.env.applicationType}/submission/spt-${code}/profile`)
      } else {
        throw new Error(t.translate('sentence.sptNotFoundCode').replace('*code', item.sptCode))
      }
    } catch (error) {
      toast.errorRequest(error)
    }
  }

  const handleDownloadBPE = async item => {
    try {
      await downloadBpe(item.referenceNo)
    } catch (error) {
      toast.errorRequest(error)
    }
  }

  const handleSend = async item => {
    setShowCommit(true)
    setSelectedItem(item)
  }

  const getErrorMessage = (item) => {
    if(item.isNtte){
      return t.translate('sentence.sptSuccess')
    } else {
      let message = item.djpResponse
        ? item.djpResponse.message
        : item.history
          ? (item.audit.isError ? item.history.error : '-')
          : t.translate('modules.submission.history.empty')

      return message //message.split('-').pop()
    }
  }

  return (
    <>
      <TableWrapper
        baseId={baseId}
        title={t.translate('modules.submission.title')}
        className={`${className} mpk-padding-N padding-left`}
        hintMessage={t.translate('modules.submission.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate('modules.submission.hint.more')}
        defaultData={[]}
        defaultSortBy="createdAt"
        defaultSort={TableWrapper.sorts.descending}
        defaultQuery={{column: 'fileName'}}
        sideHeader={ !props.envStore.widget.active && !props.authStore.user.isSupport && (
          <div>
            <div className="mpk-paper mpk-margin-N margin-bottom mpk-padding-N padding-all">
               <a
                href={props.envStore.env.documentation.url}
                target="blank"
              >
                <LoadingButton
                  theme="primary"
                  themeType="contained"
                  className="mpk-full full-width"
                  iconClassName="mdi mdi-file-document"
                >
                  {t.translate('column.guide')}
                </LoadingButton>
              </a>
            </div>
            <Certificate.Info/>
          </div>
        )}
        columns={[
          {
            label: t.translate('column.fileName'),
            key: 'fileName',
            searchable: true,
            render: item => (
              <div
                className={"mpk-link"}
                onClick={() => {
                  // if(item.isNtte){
                  //   handleDownloadBPE(item)
                  // } else {
                  //   handleGetRawData(item)
                  // }
                  handleGetRawData(item)
                }}
              >
                {item.fileName}
              </div>
            )
          },
          {
            label: t.translate('column.channel'),
            key: 'channel',
            searchable: true,
            render: item => <pre>{item.channel}</pre>,
            show: props.authStore.user.isSupport
          },
          {
            label: 'Status',
            key: 'status',
            searchable: false,
            render: item => {
              let stat = parseStatus(item)
              return(
                <div>
                  <TableWrapper.Status
                    type={stat.flag}
                    onClick={stat.onClick}
                  >
                    {stat.label}
                  </TableWrapper.Status>
                </div>
              )
            }
          },
          {
            label: t.translate('column.message'),
            key: 'message',
            searchable: false,
            render: item => (
              <div className={`${item.audit && item.audit.isError ? 'mpk-min-width-L mpk-max-width-L' : ''} mpk-font size-S`}>
                {getErrorMessage(item)}
              </div>
            )
          },
          {
            label: t.translate('column.masaPajak'),
            key: 'masaPajak',
            searchable: false,
            render: item => (`${item.periodBegin}/${item.periodEnd}`)
          },
          {
            label: t.translate('column.tahunPajak'),
            key: 'tahunPajak',
            searchable: false,
            render: item => (`${item.taxYear}`)
          },
          {
            label: t.translate('column.pembetulan'),
            key: 'pembetulan',
            searchable: false,
            render: item => (`${item.pembetulan}`)
          },
          {
            label: 'NTPA',
            key: 'ntpa',
            searchable: false,
            render: item => (item.ntpa || '-')
          },
          {
            label: t.translate('column.createdAt'),
            key: 'createdAt',
            searchable: false,
            type: TableWrapper.dataTypes.DATE,
            render: item => (moment(item.createdAt).format('lll'))
          },
          {
            label: t.translate('column.createdBy'),
            key: 'createdBy',
            searchable: true,
            render: item => item.createdBy
          },
          {
            label: t.translate('column.companyId'),
            key: 'companyId',
            searchable: true,
            render: item => <pre>{item.companyId || '-'}</pre>,
            show: props.authStore.user.isSupport
          },
        ]}
        
        actions={[
          {
            label: t.translate('column.add'),
            iconClassName: 'mdi mdi-plus',
            onClick: () => {
              const initData = storage.get(storage.keys.SPT_INIT)
              const { spt } = props.envStore.env
              if(initData){
                props.navigationStore.redirectTo(`/${props.envStore.env.applicationType}/submission/spt-${initData.profesi === spt.profesi[0].value ? '1770S' : '1770'}/profile`)
              } else setShowSPTForm(true)
            },
            show: !props.authStore.user.isSupport
          }
        ]}
        itemActions={[
          {
            label: t.translate('modules.submission.sptForm.commit.title'),
            iconClassName: 'mdi mdi-send',
            onClick: handleSend,
            // show: item => (!item.isNtte && (item.history ? item.history.isError : true))
            show: item => (item.audit.isResumable)
          },
          {
            label: t.translate('column.edit'),
            iconClassName: 'mdi mdi-pencil',
            onClick: handleGetRawData,
            show: item => (item.audit.isEditable)
          },
          {
            label: t.translate('column.history'),
            iconClassName: 'mdi mdi-history',
            onClick: (item) => {
              setSelectedItem(item)
              setShowHistory(true)
            },
            show: item => (item.history && !item.isNtte)
          }
        ]}
        onFetchData={ async query => {
          return new Promise(async (resolve, reject) => {
            try {
              let res = await (props.authStore.user.isSupport ? supportGet(query) : service.get(query))
              res.data = res.data.map( d => {
                d = initSubmission(d)
                return d
              })
              resolve(res)
            } catch (error) {
              reject(error)
            }
          })
          return service.get(query) 
        }}
        showCommandbar={true}
        showFilterPeriod={false}
        useCriteria={false}
        useFilter={true}
        defaultSize={5}
        showActionColumn={true}
      />
      <SPTForm 
        visible={showSPTForm}
        onRequestClose={() => setShowSPTForm(false)}
      />
      <History
        visible={showHistory}
        onRequestClose={() => {
          setShowHistory(false)
          setSelectedItem(null)
        }}
        item={selectedItem}
      />
      <BPE
        visible={showBpe}
        onRequestClose={() => {
          setShowBpe(false)
          setSelectedItem(null)
        }}
        submission={selectedItem}
      />
      <Commit
        submission={selectedItem}
        visible={showCommit}
        onSuccess={() => TableWrapper.reload(baseId)}
        onRequestClose={() => {
          setShowCommit(false)
        }}
        onError={(errorData) => console.log(errorData)}
      />
    </>
  )
}

export default inject('navigationStore', 'envStore', 'temporaryStore', 'authStore')(observer(List))
