import React, {useEffect, useState} from 'react'
import { ExpansionList, ExpansionPanel, usePanels } from 'react-md'
import { storage } from '../../../../../services'
import { LoaderInfo, Hint } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import HeaderLampIV from './HeaderLampIV'
import DetailLampIVA from './DetailLampIVA'
import DetailLampIVB from './DetailLampIVB'
import DetailLampIVC from './DetailLampIVC'

const generate = () => {
  HeaderLampIV.generate()
  DetailLampIVA.generate()
  DetailLampIVB.generate()
  DetailLampIVC.generate()
}

const LampiranIV = (props) => {
  const [ready, setReady] = useState(false)
  const [panels, onKeyDown] = usePanels({
    idPrefix: 'spt-1770-lampiran-IV',
    count:4,
    defaultExpandedIndex: 0
  })

  const handleSave = (key, update) => {
    storage.update(key, update);
    updateHeader()
  }

  const updateHeader = () => {
    let header = storage.get(storage.keys.SPT_1770_HEADER_LAMP_IV)
    let detailIVA = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IVA)
    let detailIVB = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IVB)
    let detailIVC = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IVC)
    let profile = storage.get(storage.keys.SPT_1770_PROFILE)
    let { tahunPajak, tahunMulai, tahunSelesai, bulanMulai, bulanSelesai, flagHitung, npwp, namaWP, kodePembetulan } = profile
    header = header || {
      npwp,
      namaWP,
      tahunPajak,
      kodePembetulan,
      bulanMulai,
      tahunMulai,
      bulanSelesai,
      tahunSelesai,
      flagHitung,
      jumlahItemA: "0",
      jumlahBA: "0",
      jumlahItemB: "0",
      jumlahBB: "0",
      jumlahItemC:"0"
    }

    if(detailIVA){
      header.jumlahItemA = String(detailIVA.daftarHarta.length)
      header.jumlahBA = number.sumFormatted(
        ...detailIVA.daftarHarta.map( d => (d.hargaPerolehan))
      )
    }

    if(detailIVB){
      header.jumlahItemB = String(detailIVB.daftarKewajiban.length)
      header.jumlahBB = number.sumFormatted(
        ...detailIVB.daftarKewajiban.map( d => (d.jumlahPinjaman))
      )
    }

    if(detailIVC){
      header.jumlahItemC = String(detailIVC.daftarKeluarga.length)
    }

    storage.set(storage.keys.SPT_1770_HEADER_LAMP_IV, header)
  }

  useEffect(() => {
    updateHeader()
    setReady(true)
  })

  const [panel1Props, panel2Props, panel3Props, panel4Props] = panels;
  return (
    <div 
      className="mod-submission-spt-1770-lampiran-IV mpk-margin-C"
      style={{
        width: '100%',
        maxWidth: 800
      }}
    >
      { ready ? (
        <div>
          {props.errorMessage && 
            <div className="mpk-paper mpk-padding-N padding-all">
              <Hint>{props.errorMessage}</Hint>
            </div>
          }
          <ExpansionList onKeyDown={onKeyDown}>
            {[
              {
                label: t.translate('modules.submission.sptForm.1770.DetailLampIVA.title'),
                render: (<DetailLampIVA onSave={handleSave}/>),
                panelProps: panel1Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770.DetailLampIVB.title'),
                render: (<DetailLampIVB onSave={handleSave}/>),
                panelProps: panel2Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770.DetailLampIVC.title'),
                render: (<DetailLampIVC onSave={handleSave}/>),
                panelProps: panel3Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770.HeaderLampIV.title'),
                render: (<HeaderLampIV {...props}/>),
                panelProps: panel4Props
              },
            ].map((p, i) => (
              <ExpansionPanel 
                key={`spt-1770-induk-panel-${i}`}
                {...p.panelProps}
                header={<div className="mpk-font size-M weight-M">{p.label}</div>}
              >
                {p.render}
              </ExpansionPanel>
            ))}
          </ExpansionList>
        </div>
      ) : <LoaderInfo/> }  
    </div>
  )
}

LampiranIV.generate = generate

export default inject('navigationStore', 'envStore', 'authStore')(observer(LampiranIV))
