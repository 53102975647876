import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_HEADER_PP46, {
    alamat: "",
    jumlahDetail: "0",
    jumlahPenghasilanBruto: "0",
    jumlahPPHFinal: "0"
  })

  return data
}

const { inputTypes } = DataForm
const HeaderPP46 = (props) => {
  const profile = storage.get(storage.keys.SPT_1770_PROFILE)
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  useEffect(() => {
    let header = storage.get(storage.keys.SPT_1770_HEADER_PP46)
    setDefaultData(header)
    setTimeout(() => setReady(true))
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770-header-pp46"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      hintMessage={profile.pp46 ? t.translate('sentence.headerLampiranHintText') : t.translate('modules.submission.nonPp46Message')}
      asDialog={false}
      defaultData={defaultData}
      definitions={profile.pp46 ? [
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderPP46.JmlDtl'),
          key:'jumlahDetail',
          disabled: true
        },
         {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderPP46.JmlBruto'),
          key:'jumlahPenghasilanBruto',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderPP46.JmlPPhFinal'),
          key:'jumlahPPHFinal',
          disabled: true
        }
      ] : []}
      submitLabel={t.translate('column.next')}
      submitIconClassName="mdi mdi-arrow-right"
      onSubmit={() => {
        props.onNextTab()
      }}
    />
  ) : <LoaderInfo/>
}

HeaderPP46.generate = generate

export default HeaderPP46
