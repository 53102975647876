import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { number } from '../../../../../libs/react-mpk/services'

const generate = () => {
  const data = storage.generate(storage.keys.SPT_1770S_HEADER_LAMP_I, {
    jumlahBA: "0",
    jumlahBB: "0",
    jumlahItemC: "0",
    jumlahBC: ""
  })

  return data
}

const { inputTypes } = DataForm
const HeaderLampI = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  useEffect(() => {
    let header = storage.get(storage.keys.SPT_1770S_HEADER_LAMP_I)
    setDefaultData(header)
    setTimeout(() => {
      setReady(true)
    })
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770S-header-lamp-I"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      hintMessage={t.translate('sentence.headerLampiranHintText')}
      asDialog={false}
      editable={props.isEditable}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770S.HeaderLampI.JBA'),
          key:'jumlahBA',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770S.HeaderLampI.JBB'),
          key:'jumlahBB',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770S.HeaderLampI.JItemC'),
          key:'jumlahItemC',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770S.HeaderLampI.JBC'),
          key:'jumlahBC',
          disabled: true
        }
      ]}
      submitLabel={t.translate('column.next')}
      submitIconClassName="mdi mdi-arrow-right"
      onSubmit={() => {
        props.onNextTab()
      }}
    />
  ) : <LoaderInfo/>
}

HeaderLampI.generate = generate

export default HeaderLampI
