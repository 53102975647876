import md5 from 'fast-md5';
import SplitFile from 'js-split-file';
import {uid} from 'uid';

export const checkSum = file => {
  return new Promise(function(resolve,reject){
    let fr = new FileReader();
    fr.readAsBinaryString(file);
    fr.onload = function(e){
      resolve(md5(e.target.result.toString()))
    }
  })
}

export const chunk = (file, chunkSize = 1024, getChecksum = false, randomChecksum = false) => {
  return new Promise((resolve, reject) => {
    if(file){
      let reader = new FileReader();
      let result = {blobs:[]};
      reader.readAsArrayBuffer(file);
      reader.onload = function(){
        let binary = new Uint8Array(reader.result);

        const splitFile = new SplitFile();
        const blocks = splitFile.split(binary, file.name, chunkSize);

        for(let block of blocks){
          let piece = splitFile.decodeBlock(block);
          result.blobs.push(new Blob([piece.binary], {type: file.mimetype}))
        }

        if(getChecksum) {
          if(randomChecksum){
            result.checksum = uid(32);
          }else{
            let binaryString = new TextEncoder().encode(binary);//String.fromCharCode.apply(null, binary);
            binaryString = binaryString.toString();
            result.checksum = md5(binaryString);
          }
        }

        resolve(result);
      }
    }else{
      reject('file is required');
    }
  })
}

export const b64ToBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, {type: contentType});
  return blob;
}