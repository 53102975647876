import React, { useEffect, useState } from "react";
import { DataForm } from "../../../../../libs/react-mpk/components";
import { number } from "../../../../../libs/react-mpk/services";
import t from "counterpart";
import { storage } from "../../../../../services";
import { inject, observer } from "mobx-react";

const { inputTypes } = DataForm;

const BagianC = ({ defaultData = {}, ...props }) => {
  const induk = storage.get(storage.keys.SPT_1770S_INDUK);
  const profile = storage.get(storage.keys.SPT_1770S_PROFILE);
  const [data, setData] = useState(defaultData);

  const [isPhmt] = useState(
    ["3", "4"].indexOf(String(profile.flagSuamiIstri)) >= 0 ? true : false
  );
  useEffect(() => {
    setData({ ...defaultData, ...getUpdateData(defaultData) });
  }, []);
  const getUpdateData = (data) => {
    const C11 = number.sumFormatted(data.C9, data.C10);
    return { C11 };
  };

  return (
    <DataForm
      baseId={`spt-1770S-induk-C`}
      className="mpk-padding-NONE padding-all"
      asDialog={false}
      editable={props.isEditable}
      defaultData={data}
      definitions={[
        // {
        //   inputType: inputTypes.CHECKBOX,
        //   label: t.translate(
        //     `modules.submission.sptForm.1770.Induk.C12_manual_input`
        //   ),
        //   key: "checkSelfCount",
        //   disabled: false,
        //   show: isPhmt ? false : true,
        // },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.C9`),
          key: "C9",
          disabled: profile.fghs && !isPhmt ? false : true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.C10`),
          key: "C10",
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.C11`),
          key: "C11",
          disabled: true,
        },
      ]}
      onBeforeChange={(key, value) => {
        // if (key !== "checkSelfCount") {
        //   number.format(number.clearFormat(value.replace(/\D/g, "")));
        // }
        return value;
      }}
      onChange={(data, key, value) => {
        // if (key === "checkSelfCount") {
        //   if (value) {
        //     data.C9 = 0;
        //   } else {
        //     data.C9 = defaultData.C9;
        //   }
        // }
        const update = getUpdateData(data);
        setData({ ...data, ...update });
        return { ...data, ...update };
      }}
      onSubmit={(data, callback) => {
        props.onSave({ C: data });
        callback(t.translate("sentence.savedItem"), false);
      }}
      submitLabel={t.translate("column.save")}
    />
  );
};

export default inject("envStore")(observer(BagianC));
