import React, { useEffect, useState } from "react";
import { ExpansionList, ExpansionPanel, usePanels } from "react-md";
import { storage } from "../../../../../services";
import { LoaderInfo, Hint } from "../../../../../libs/react-mpk/components";
import { number } from "../../../../../libs/react-mpk/services";
import { inject, observer } from "mobx-react";
import t from "counterpart";
import HeaderPP46 from "./HeaderPP46";
import DetailPP46 from "./DetailPP46";

const generate = () => {
  HeaderPP46.generate();
  DetailPP46.generate();
};

const PP46 = (props) => {
  const profile = storage.get(storage.keys.SPT_1770_PROFILE);
  let detailIIIA = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IIIA);

  const [ready, setReady] = useState(false);
  const [panels, onKeyDown] = usePanels({
    idPrefix: "spt-1770-pp46",
    count: 2,
    defaultExpandedIndex: 0,
  });

  const handleSave = (key, update) => {
    storage.update(key, update);
    updateHeader();
  };

  const updateHeader = () => {
    let header = storage.get(storage.keys.SPT_1770_HEADER_PP46);
    let detailPP46 = storage.get(storage.keys.SPT_1770_DETAIL_PP46);

    if (detailPP46) {
      header.jumlahDetail = String(detailPP46.daftarPP46.length);
      header.jumlahPenghasilanBruto = number.sumFormatted(
        ...detailPP46.daftarPP46.map((d) => d.jumlahBruto)
      );
      header.jumlahPPHFinal = number.sumFormatted(
        ...detailPP46.daftarPP46.map((d) => d.jumlahPPHFinal)
      );

      if (profile.pp46) {
        storage.update(storage.keys.SPT_1770_DETAIL_LAMP_IIIA, {
          A16: {
            A163: header.jumlahPenghasilanBruto,
            A164: header.jumlahPPHFinal,
          },
        });
      }

      let jumlahBA = 0;
      if (detailIIIA) {
        jumlahBA = number.sumFormatted(
          detailIIIA.A1.A14,
          detailIIIA.A2.A24,
          detailIIIA.A3.A34,
          detailIIIA.A4.A44,
          detailIIIA.A5.A54,
          detailIIIA.A6.A64,
          detailIIIA.A7.A74,
          detailIIIA.A8.A84,
          detailIIIA.A9.A94,
          detailIIIA.A10.A104,
          detailIIIA.A11.A114,
          detailIIIA.A12.A124,
          detailIIIA.A13.A134,
          detailIIIA.A14.A144,
          detailIIIA.A15.A154,
          detailIIIA.A16.A164
        );
      }
      storage.update(storage.keys.SPT_1770_HEADER_LAMP_III, {
        jumlahBA: jumlahBA,
      });
    }

    storage.set(storage.keys.SPT_1770_HEADER_PP46, header);
  };

  useEffect(() => {
    updateHeader();
    setReady(true);
  }, []);

  const [panel1Props, panel2Props] = panels;
  return (
    <div
      className="mod-submission-spt-1770-pp46 mpk-margin-C"
      style={{
        width: "100%",
        maxWidth: 800,
      }}
    >
      {ready ? (
        <div>
          {props.errorMessage && (
            <div className="mpk-paper mpk-padding-N padding-all">
              <Hint>{props.errorMessage}</Hint>
            </div>
          )}
          <ExpansionList onKeyDown={onKeyDown}>
            {[
              ...(profile.pp46
                ? [
                    {
                      label: t.translate(
                        "modules.submission.sptForm.1770.DetailPP46.title"
                      ),
                      render: <DetailPP46 onSave={handleSave} />,
                      panelProps: panel1Props,
                    },
                  ]
                : []),
              ...[
                {
                  label: t.translate(
                    "modules.submission.sptForm.1770.HeaderPP46.title"
                  ),
                  render: <HeaderPP46 {...props} />,
                  panelProps: profile.pp46 ? panel2Props : panel1Props,
                },
              ],
            ].map((p, i) => (
              <ExpansionPanel
                key={`spt-1770-pp46-panel-${i}`}
                {...p.panelProps}
                header={
                  <div className="mpk-font size-M weight-M">{p.label}</div>
                }
              >
                {p.render}
              </ExpansionPanel>
            ))}
          </ExpansionList>
        </div>
      ) : (
        <LoaderInfo />
      )}
    </div>
  );
};

PP46.generate = generate;

export default inject(
  "navigationStore",
  "envStore",
  "authStore"
)(observer(PP46));
