import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770S_DETAIL_LAMP_IIA, {
    A13: "0",
    A14: "0",
    A23: "0",
    A24: "0",
    A33: "0",
    A34: "0",
    A43: "0",
    A44: "0",
    A53: "0",
    A54: "0",
    A63: "0",
    A64: "0",
    A73: "0",
    A74: "0",
    A83: "0",
    A84: "0",
    A93: "0",
    A94: "0",
    A93: "0",
    A94: "0",
    A103: "0",
    A104: "0",
    A113: "0",
    A114: "0",
    A123: "0",
    A124: "0",
    A133: "0",
    A134: "0",
    A143: "0",
    A144: "0"
  })

  return data
}

const { inputTypes } = DataForm
const DetailLampII = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IIA)
    setDefaultData(defData)
    setReady(true)
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770S-header-lamp-IC"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      editable={props.isEditable}
      defaultData={defaultData}
      definitions={[
        '___A1', 'A13','A14',
        '___A2', 'A23','A24',
        '___A3', 'A33','A34',
        '___A4', 'A43','A44',
        '___A5', 'A53','A54',
        '___A6', 'A63','A64',
        '___A7', 'A73','A74',
        '___A8', 'A83','A84',
        '___A9', 'A93','A94',
        '___A10', 'A103','A104',
        '___A11', 'A113','A114',
        '___A12', 'A123','A124',
        '___A13', 'A133','A134',
        '___A14', 'A143','A144',
      ].map(d => {
        let isFirstColumn = String(d).endsWith('3')
        return (
          d.startsWith('_') ? {
            render: (
              <p className="mpk-full full-width">
                {t.translate(`modules.submission.sptForm.1770S.DetailLampIIA.${d}`)}
              </p>
            )
          } : {
            inputType: inputTypes.INPUT,
            label: t.translate(isFirstColumn ? 'column.penghasilanBruto' : 'column.pphTerutang'),
            className: isFirstColumn ? 'mpk-padding-N padding-right' : '',
            width: '50%',
            key: d,
            disabled:['A113', 'A114'].indexOf(d) >= 0
          })
      })}
      onBeforeChange={(key, value) => (
        number.format(number.clearFormat(value.replace(/\D/g, '')))
      )}
      onSubmit={(data, callback) => {
        props.onSave(storage.keys.SPT_1770S_DETAIL_LAMP_IIA, data)
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  ) : <LoaderInfo/>
}

DetailLampII.generate = generate

export default inject('envStore')(observer(DetailLampII))
