import React from 'react'
import { inject, observer } from 'mobx-react'
import { Hint, Label, Link, LoadingButton } from '../../libs/react-mpk/components'
import t from 'counterpart'
import moment from 'moment'
import { utils } from '../../services'

const Info = (props) => {
  let { npwp } = props.temporaryStore.properties
  return npwp ? (
    <div className="mod-cert-info mpk-padding-N padding-all mpk-paper mpk-margin-N margin-bottom">
      <Label>{t.translate('modules.certificate.info.title')}</Label>
      <div className="mpk-margin-N margin-bottom">
        <div className="mpk-font size-S mpk-font color-D3">NPWP</div>
        <div className="mpk-font size-L weight-B">{utils.formatNpwp(npwp.npwp)}</div>
      </div>
      
      <div className="mpk-margin-N margin-bottom">
        <div className="mpk-font size-S mpk-font color-D3">{t.translate('column.expiredAt')}</div>
        <div className="mpk-font size-N">{moment(npwp.expiredAt).format('lll')}</div>
      </div>

      {moment(props.envStore.env.timeStamp).isAfter(npwp.expiredAt) && (
        <Hint 
          showIcon={false}
          className="mpk-margin-N margin-bottom"
        >
          {t.translate('sentence.expiredDc')}
        </Hint>
      )}
      
      <div className="mpk-border border-top dark solid mpk-padding-N padding-top mpk-flex align-center justify-between">
        <div 
          className="mpk-link" 
          onClick={() => {
            dispatchEvent(new CustomEvent('internal-event', {detail:{eventType: 'show-registration'}}))
          }}
        >
          {t.translate('column.update')}
        </div>
      </div>
    </div>
  ) : null
}

export default inject('temporaryStore', 'envStore')(observer(Info))
