import React, {useEffect, useState} from 'react'
import { ExpansionList, ExpansionPanel, usePanels } from 'react-md'
import { storage } from '../../../../../services'
import { LoaderInfo, Hint } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import HeaderLampIII from './HeaderLampIII'
import DetailLampIIIA from './DetailLampIIIA'
import DetailLampIIIB from './DetailLampIIIB'

const generate = () => {
  HeaderLampIII.generate()
  DetailLampIIIA.generate()
  DetailLampIIIB.generate()
}


const updateHeader = () => {
  const profile = storage.get(storage.keys.SPT_1770_PROFILE)
  let header = storage.get(storage.keys.SPT_1770_HEADER_LAMP_III)
  let detailIIIA = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IIIA)
  let detailIIIB = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IIIB)
  const phmt = storage.get(storage.keys.SPT_1770_PHMT)
  const isPhmt = ['3', '4'].indexOf(String(profile.flagSuamiIstri)) >= 0

  if(detailIIIA){
    header.jumlahBA = number.sumFormatted(
      detailIIIA.A1.A14,
      detailIIIA.A2.A24,
      detailIIIA.A3.A34,
      detailIIIA.A4.A44,
      detailIIIA.A5.A54,
      detailIIIA.A6.A64,
      detailIIIA.A7.A74,
      detailIIIA.A8.A84,
      detailIIIA.A9.A94,
      detailIIIA.A10.A104,
      detailIIIA.A11.A114,
      detailIIIA.A12.A124,
      detailIIIA.A13.A134,
      detailIIIA.A14.A144,
      detailIIIA.A15.A154,
      detailIIIA.A16.A164
    )
  }

  if(detailIIIB){
    header.jumlahBB = number.sumFormatted(
      detailIIIB.B.B13,
      detailIIIB.B.B23,
      detailIIIB.B.B33,
      detailIIIB.B.B43,
      detailIIIB.B.B53,
      detailIIIB.B.B63
    )
  }

  header.C = isPhmt ? phmt.A84 : "0"
  storage.set(storage.keys.SPT_1770_HEADER_LAMP_III, header)
}

const LampiranIII = (props) => {
  const profile = storage.get(storage.keys.SPT_1770_PROFILE)
  const phmt = storage.get(storage.keys.SPT_1770_PHMT)
  const [ready, setReady] = useState(false)
  const [isPhmt] = useState(['3', '4'].indexOf(String(profile.flagSuamiIstri)) >= 0)
  const [panels, onKeyDown] = usePanels({
    idPrefix: 'spt-1770-lampiran-III',
    count:3,
    defaultExpandedIndex: 0
  })

  const handleSave = (key, update) => {
    storage.update(key, update);
    updateHeader()
  }

  useEffect(() => {
    updateHeader()
    setReady(true)
  })

  const [panel1Props, panel2Props, panel3Props] = panels;
  return (
    <div 
      className="mod-submission-spt-1770-lampiran-III mpk-margin-C"
      style={{
        width: '100%',
        maxWidth: 800
      }}
    >
      { ready ? (
        <div>
          {props.errorMessage && 
            <div className="mpk-paper mpk-padding-N padding-all">
              <Hint>{props.errorMessage}</Hint>
            </div>
          }
          <ExpansionList onKeyDown={onKeyDown}>
            {[
              {
                label: t.translate('modules.submission.sptForm.1770.DetailLampIIIA.title'),
                render: (<DetailLampIIIA isPhmt={isPhmt} onSave={handleSave}/>),
                panelProps: panel1Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770.DetailLampIIIB.title'),
                render: (<DetailLampIIIB isPhmt={isPhmt} onSave={handleSave}/>),
                panelProps: panel2Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770.HeaderLampIII.title'),
                render: (<HeaderLampIII isPhmt={isPhmt} {...props}/>),
                panelProps: panel3Props
              },
            ].map((p, i) => (
              <ExpansionPanel 
                key={`spt-1770-induk-panel-${i}`}
                {...p.panelProps}
                header={<div className="mpk-font size-M weight-M">{p.label}</div>}
              >
                {p.render}
              </ExpansionPanel>
            ))}
          </ExpansionList>
        </div>
      ) : <LoaderInfo/> }  
    </div>
  )
}

LampiranIII.generate = generate
LampiranIII.updateHeader = updateHeader

export default inject('navigationStore', 'envStore', 'authStore')(observer(LampiranIII))
