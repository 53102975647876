import React, { useState, useEffect } from "react";
import { DataForm, LoaderInfo } from "../../../../libs/react-mpk/components";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { storage, options } from "../../../../services";
import { find } from "lodash";

const generate = async (user, env) => {
  const initData = storage.get(storage.keys.SPT_INIT);
  const { npwp, namaWP, tahunPajak, kodePembetulan, flagSuamiIstri } = initData;
  const data = await storage.generate(storage.keys.SPT_1770S_PROFILE, {
    npwp,
    namaWP,
    tahunPajak,
    kodePembetulan,
    pekerjaan: env.spt.KLU1770S[0].label,
    KLU: env.spt.KLU1770S[0].value,
    telepon: "",
    fax: "",
    flagSuamiIstri,
    npwpPasangan: "",
    fghs: false,
  });

  return data;
};

const { inputTypes } = DataForm;
const Profile = (props) => {
  const [ready, setReady] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [show, setShow] = useState({
    npwpPasangan: false,
  });
  const { spt } = props.envStore.env;
  const [years] = useState(options.getYears(props.envStore.env.timeStamp));
  const [pembetulans] = useState(options.getPembetulans());

  const getUpdateData = (data) => {
    const isMandatoryNpwpPasangan =
      ["3", "4"].indexOf(data.flagSuamiIstri) >= 0;
    setShow({ ...show, ...{ npwpPasangan: isMandatoryNpwpPasangan } });
    data.npwpPasangan = isMandatoryNpwpPasangan ? data.npwpPasangan : "";

    return data;
  };

  useEffect(async () => {
    let profile = storage.get(storage.keys.SPT_1770S_PROFILE);
    profile = getUpdateData(profile);
    setDefaultData(profile);
    setReady(true);
  }, []);

  return ready ? (
    <DataForm
      baseId="mod-submission-spt-1770-profile"
      className="mpk-margin-C"
      hintMessage={props.errorMessage}
      asDialog={false}
      defaultData={defaultData}
      editable={props.isEditable}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: "NPWP",
          key: "npwp",
          readOnly: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate("column.namaWP"),
          key: "namaWP",
          readOnly: true,
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate("column.tahunPajak"),
          key: "tahunPajak",
          options: years,
          disabled: true,
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate("column.pembetulan"),
          key: "kodePembetulan",
          options: pembetulans,
          disabled: true,
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate("column.pekerjaan"),
          key: "KLU",
          className: "mpk-padding-N padding-right",
          options: props.envStore.env.spt.KLU1770S,
          width: "50%",
          required: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: "KLU",
          key: "KLU",
          width: "50%",
          readOnly: true,
          validation: "digits:5",
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate("column.phone"),
          key: "telepon",
          required: true,
          validation: "min:8|max:16",
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate("column.fax"),
          key: "fax",
          validation: "max:16",
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate("modules.submission.fghs"),
          className:
            "mpk-padding-S padding-top padding-bottom mpk-margin-N margin-bottom",
          key: "fghs",
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate("column.flagSuamiIstri"),
          key: "flagSuamiIstri",
          options: spt.flagSuamiIstri,
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate("column.npwpPasangan"),
          key: "npwpPasangan",
          required: true,
          validation: "digits:15",
          show: show.npwpPasangan,
        },
      ]}
      onBeforeChange={(key, value) => {
        if (["telepon", "fax", "KLU"].indexOf(key) >= 0)
          value = value.replace(/\D/g, "");
        else if (["pembetulan"].indexOf(key) >= 0)
          value = Number(value.replace(/\D/g, ""));
        return value;
      }}
      onChange={(data, key) => {
        if (key === "KLU") {
          let klu = find(props.envStore.env.spt.KLU1770S, { value: data.KLU });
          if (klu) data.pekerjaan = klu.label;
        }
        const isMandatoryNpwpPasangan =
          ["3", "4"].indexOf(data.flagSuamiIstri) >= 0;
        setShow({ ...show, ...{ npwpPasangan: isMandatoryNpwpPasangan } });
        data.npwpPasangan = isMandatoryNpwpPasangan ? data.npwpPasangan : "";

        return data;
      }}
      submitLabel={t.translate("column.next")}
      submitIconClassName="mdi mdi-arrow-right"
      onSubmit={(data, callback) => {
        const isMandatoryNpwpPasangan =
          ["3", "4"].indexOf(data.flagSuamiIstri) >= 0;
        if (isMandatoryNpwpPasangan && data.npwp === data.npwpPasangan) {
          callback(new Error(t.translate("sentence.errorNpwpPasangan")));
        } else {
          props.onNextTab();
          storage.set(storage.keys.SPT_1770S_PROFILE, data);
          callback();
        }
      }}
    />
  ) : (
    <LoaderInfo />
  );
};

Profile.generate = generate;

export default inject(
  "authStore",
  "envStore",
  "navigationStore",
  "temporaryStore"
)(observer(Profile));
