import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_HEADER_LAMP_III, {
    jumlahBA: "0",
    jumlahBB: "0",
    C: "0"
  })

  return data
}

const { inputTypes } = DataForm
const HeaderLampIII = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  useEffect(() => {
    let header = storage.get(storage.keys.SPT_1770_HEADER_LAMP_III)
    setDefaultData(header)
    setTimeout(() => setReady(true))
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-III"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      hintMessage={t.translate('sentence.headerLampiranHintText')}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderLampIII.JBA'),
          key:'jumlahBA',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderLampIII.JBB'),
          key:'jumlahBB',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderLampIII.C'),
          key:'C',
          disabled: true,
          show: props.isPhmt
        }
      ]}
      submitLabel={t.translate('column.next')}
      submitIconClassName="mdi mdi-arrow-right"
      onSubmit={() => {
        props.onNextTab()
      }}
    />
  ) : <LoaderInfo/>
}

HeaderLampIII.generate = generate

export default HeaderLampIII
