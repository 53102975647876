import React from 'react'
import { DataForm, Link } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import t from 'counterpart'
import { storage } from '../../../../../services'
import { inject, observer } from 'mobx-react'
import { FontIcon, Button } from 'react-md'
import { getPTKP } from '../../../Submission.service'

const { inputTypes } = DataForm

const BagianB = ({
  defaultData = {},
  ...props
}) => {
  const induk = storage.get(storage.keys.SPT_1770S_INDUK)
  const profile = storage.get(storage.keys.SPT_1770S_PROFILE)
  const getUpdateData = (data, key) => {
    return new Promise(async (resolve, reject) => {
      const A6 = induk.A.A6
      let B7 = data.B7
      
      if(key === 'flagPTKP' || key === 'jumlahTanggungan'){
        let resFlagPTKP = await getPTKP(profile.tahunPajak, data.flagPTKP, data.jumlahTanggungan)
        B7 = number.format(resFlagPTKP.data.nominal)
      }

      const B8 = number.clearFormat(A6) < number.clearFormat(B7) 
        ? '0'
        : number.sumFormatted(A6, `-${B7}`)
          
      resolve({B7, B8})
    })
  }

  return (
    <DataForm
      baseId={`spt-1770S-induk-B`}
      className="mpk-padding-NONE padding-all"
      asDialog={false}
      editable={props.isEditable}
      defaultData={{...defaultData, ...getUpdateData(defaultData)}}
      definitions={[
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.FlgPTKP`),
          key: 'flagPTKP',
          width:'70%',
          className:"mpk-padding-N padding-right",
          options: props.isPhmt 
            ? props.envStore.env.spt.FlgPTKP1770S
              .filter(d => (d.value !== '3')) 
            : profile.flagSuamiIstri === '2'
              ? props.envStore.env.spt.FlgPTKP1770S.filter(d => (d.code === 'TK'))
              : props.envStore.env.spt.FlgPTKP1770S,
          disabled: false,
          show: !props.isPhmt
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.JmlTanggungan`),
          key: 'jumlahTanggungan',
          width: '30%',
          type: 'number',
          disabled: true,
          show: !props.isPhmt
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.B7`),
          key: 'B7',
          disabled: true,
          show: !props.isPhmt
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770S.Induk.B8`),
          key: 'B8',
          disabled: true
        }
      ]}
      onBeforeChange={(key, value) => (
        ['B7', 'B8'].indexOf(key) >= 0
          ? number.format(number.clearFormat(value.replace(/\D/g, '')))
          : value
      )}
      onChange={async (data, key) => {
        const update = await getUpdateData(data, key)
        return {...data, ...update}
      }}
      onSubmit={(data, callback) => {
        props.onSave({B:data});
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  )
}

export default inject('envStore')(observer(BagianB))
