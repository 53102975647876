import { countBy, map, find, isEqual } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

export const updateDataField = (sourceData, key, value) => {
  let keys = key.split('.')
  let target = sourceData
  if(keys.length > 1){
    for(let i = 0 ; i < keys.length ; i++){
      let tKey = keys[i]
      if(target[tKey]){
        if(i !== keys.length - 1){
          target = target[tKey]
        } else {
          target[tKey] = value
        }
      }
    }
  }else sourceData[key] = value
  return sourceData
}

export const isDuplicateObjectInArray = (sourceData, keys=[]) => {
  let data = sourceData.map(d => {
    let obj = {}
    for(let key of keys){
      obj[key] = d[key]
    }
    return obj
  })

  let isDuplicate = false
  for(let i = data.length - 1 ; i >= 0; i--){
    let d = data[i]
    let item = find(data, d)
    isDuplicate = data.indexOf(item) !== i
    if(isDuplicate) break;
  }

  return isDuplicate
}

const isPromise = (f) => {
  return typeof f === 'object' && typeof f.then === 'function'
}

export const returnsPromise = (f) => {
  if (
    f.constructor.name === 'AsyncFunction' ||
    (typeof f === 'function' && isPromise(f()))
  ) {
    return true;
  }
  return false;
}

export const parseDateDiff = (startDate, endDate) => {
  let diff = moment(endDate).diff(startDate, 'second')
  diff = diff < 0 ? 0 : diff
  let hour = Math.floor(diff/3600)
  let minute = Math.floor((diff - (hour*3600))/60)
  minute = minute > 59 ? 59 : minute
  let second = diff - (hour*3600) - (minute*60)
  return `${numeral(hour).format('00')}:${numeral(minute).format('00')}:${numeral(second).format('00')}`
}