import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_DETAIL_LAMP_ID, {
    D: {
      D1: "0",
      D2: "0",
      D3: "0",
      D4: "0",
      D5: "0",
      D6: "0"
    }
  })

  return data
}
const { inputTypes } = DataForm
const DetailLampID = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  const [D, setD] = useState({})
  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_ID)
    setDefaultData(defData)
    setD(defData.D)
    setReady(true)
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-ID"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          render: (
            <DataForm
              baseId="spt-1770-header-lamp-ID-D"
              className="mpk-padding-NONE padding-all"
              style={{maxWidth: 'unset'}}
              asDialog={false}
              defaultData={D}
              definitions={[
                 {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampID.D1'),
                    key:'D1',
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampID.D2'),
                    key:'D2',
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampID.D3'),
                    key:'D3',
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampID.D4'),
                    key:'D4',
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampID.D5'),
                    key:'D5',
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampID.D6'),
                    key:'D6',
                  }
              ]}
              onBeforeChange={(key, value) => (
                number.format(number.clearFormat(value.replace(/\D/g, '')))
              )}
              onChange={ data => {
                setD(data)
                return data
              }}
              submitLabel={t.translate('column.save')}
            />
          )
        }
      ]}
      onSubmit={(data, callback) => {
        let newObj = Object.assign(data, {D})
        props.onSave(storage.keys.SPT_1770_DETAIL_LAMP_ID, newObj)
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  ) : <LoaderInfo/>
}

DetailLampID.generate = generate

export default inject('envStore')(observer(DetailLampID))
