import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { number } from '../../../../../libs/react-mpk/services'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_HEADER_LAMP_II, {
    // flagHitung: "1",
    jumlahItemA: "0",
    jumlahBA: "0"
  })

  return data
}
const { inputTypes } = DataForm
const HeaderLampII = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  useEffect(() => {
    let header = storage.get(storage.keys.SPT_1770_HEADER_LAMP_II)
    setDefaultData(header)
    setTimeout(() => setReady(true))
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-II"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      hintMessage={t.translate('sentence.headerLampiranHintText')}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderLampII.JitemA'),
          key:'jumlahItemA',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.submission.sptForm.1770.HeaderLampII.JBA'),
          key:'jumlahBA',
          disabled: true
        }
      ]}
      submitLabel={t.translate('column.next')}
      submitIconClassName="mdi mdi-arrow-right"
      onSubmit={() => {
        props.onNextTab()
      }}
    />
  ) : <LoaderInfo/>
}

HeaderLampII.generate = generate

export default HeaderLampII
