import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { find } from 'lodash'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_DETAIL_LAMP_IC, {
    daftarIC:[]
  })

  return data
}

const { inputTypes } = DataForm
const DetailLampIC = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IC)
    setDefaultData(defData)
    setReady(true)
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-IC"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.LIST,
          // label: t.translate('column.daftarIC'),
          key:'daftarIC',
          defaultData: {
            npwpPemberiKerja:"",
            namaPemberiKerja:"",
            bruto: "0",
            biaya: "0",
            neto: "0"
          },
          definitions:[
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailLampIC.NPWP_PK'),
              key: 'npwpPemberiKerja',
              validation:'digits:15',
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailLampIC.NAMA_PK'),
              key: 'namaPemberiKerja',
              validation: 'min:4',
              helpText: t.translate('sentence.minimumName'),
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailLampIC.BRUTO'),
              key: 'bruto',
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailLampIC.BIAYA'),
              key: 'biaya',
              required: true
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('modules.submission.sptForm.1770.DetailLampIC.NETO'),
              key: 'neto'
            },
          ]
        }
      ]}
      onBeforeChange={(key, value) => {
        value = ['bruto','biaya', 'neto'].indexOf(key) >= 0
          ? number.format(number.clearFormat(value.replace(/\D/g, '')))
          : ['npwpPemberiKerja'].indexOf(key) >= 0 
            ? value.replace(/\D/g, '')
            : value
        return value
      }}
      onChange={(data, key, value, parentKey, indexAtParent) => {
        if(parentKey === 'daftarIC' && ['bruto', 'biaya'].indexOf(key) >= 0){
          let item = data[parentKey][indexAtParent]
          const bruto = number.clearFormat(item.bruto)
          const biaya = number.clearFormat(item.biaya)
          data[parentKey][indexAtParent].neto = biaya > bruto ? '0' : number.sumFormatted(item.bruto, `-${item.biaya}`)
          
        }
        return data
      }}
      onSubmit={(data, callback) => {
        props.onSave(storage.keys.SPT_1770_DETAIL_LAMP_IC, data)
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  ) : <LoaderInfo/>
}

DetailLampIC.generate = generate

export default inject('envStore')(observer(DetailLampIC))
