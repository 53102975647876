import React, { useEffect, useState } from "react";
import {
  ExpansionList,
  ExpansionPanel,
  LeakAddFontIcon,
  usePanels,
} from "react-md";
import { storage } from "../../../../../services";
import { LoaderInfo, Hint } from "../../../../../libs/react-mpk/components";
import { inject, observer } from "mobx-react";
import { number, toast } from "../../../../../libs/react-mpk/services";
import moment from "moment";
import t from "counterpart";
import Header from "./Header";
import BagianA from "./BagianA";
import BagianB from "./BagianB";
import BagianC from "./BagianC";
import BagianD from "./BagianD";
import BagianE from "./BagianE";
import BagianF from "./BagianF";
import CheckList from "./CheckList";
import { getPTKP } from "../../../Submission.service";
import { find } from "lodash";

const generate = (timeStamp) => {
  const init = storage.get(storage.keys.SPT_INIT);
  let data = storage.generate(storage.keys.SPT_1770S_INDUK, {
    A: {
      A1: "0",
      A2: "0",
      A3: "0",
      A4: "0",
      A5: "0",
      A6: "0",
    },
    B: {
      flagPTKP: "0",
      jumlahTanggungan: "0",
      B7: "0",
      B8: "0",
    },
    C: {
      C9: "0",
      C10: "0",
      C11: "0",
    },
    D: {
      D12: "0",
      flagD13: "0",
      D13: "0",
      D14a: "0",
      D14b: "0",
      D15: "0",
    },
    E: {
      flagE16: "0",
      E16TanggalLunas: "",
      E16: "0",
      E17: "0",
    },
    F: {
      F18: "0",
      flagF18: "0",
    },
    checkList: {
      checkGa: false,
      checkGb: false,
      checkGc: false,
      checkGd: false,
      checkGe: false,
      isiCheckGe: "",
    },
    flagTandaTanganSPT: "0",
    tanggalSPT: moment(timeStamp).format("YYYY-MM-DD"),
    npwpPelapor: init.npwp,
    namaWPPelapor: init.namaWP,
  });

  return data;
};

const Induk = (props) => {
  const [ready, setReady] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const savedDefaultData = storage.get(storage.keys.SPT_1770S_INDUK);
  const profile = storage.get(storage.keys.SPT_1770S_PROFILE);
  const detailLampIID = storage.get(storage.keys.SPT_1770S_DETAIL_LAMP_IID);
  const headerLampI = storage.get(storage.keys.SPT_1770S_HEADER_LAMP_I);
  const headerSSP = storage.get(storage.keys.SPT_1770S_HEADER_SSP);
  const phmt = storage.get(storage.keys.SPT_1770S_PHMT);
  const [isPhmt] = useState(
    ["3", "4"].indexOf(String(profile.flagSuamiIstri)) >= 0 ? true : false
  );
  const [panels, onKeyDown] = usePanels({
    idPrefix: "spt-induk",
    count: 7,
    defaultExpandedIndex: 0,
  });

  const handleSave = async (update) => {
    let newObj = Object.assign(defaultData, update);
    newObj.A = updateA(newObj);
    newObj.B = await updateB(newObj);
    newObj.C = updateC(newObj);
    newObj.D = updateD(newObj);
    newObj.E = updateE(newObj);
    newObj.F = updateF(newObj);
    newObj.checkList = updateCheckList(newObj);
    storage.update(storage.keys.SPT_1770S_INDUK, newObj);
    setDefaultData(newObj);
  };

  const updateA = (data) => {
    const A1 = data.A.A1; //isPhmt ? number.sumFormatted(phmt.A13, phmt.A14) : headerLampI.jumlahBA4
    const A2 = headerLampI.jumlahBA; //isPhmt ? number.sumFormatted(phmt.A23, phmt.A24) : headerLampI.jumlahBC
    const A3 = data.A.A3;
    const A4 = number.sumFormatted(A1, A2, A3);
    const A5 = data.A.A5;
    const A6 = number.sumFormatted(A4, `-${data.A.A5}`);
    const A = Object.assign(data.A, { A1, A2, A3, A4, A5, A6 });
    return A;
  };

  const updateB = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        // const flagPTKP = ['0', '2'].indexOf(Number(profile.flagSuamiIstri)) >= 0
        //   ? '0'
        //   : Number(profile.flagSuamiIstri) === '1'
        //     ? '1'p
        //     : '3'
        const C2 = phmt.C2.split("/")[0];
        const C2Value = isPhmt
          ? find(props.envStore.env.spt.FlgPTKP1770S, { code: C2 })
          : null;
        const flagPTKP = isPhmt
          ? C2Value
            ? C2Value.value
            : "0"
          : data.B.flagPTKP || "0";

        // const flagPTKP = data.B.flagPTKP
        const __jumlahTanggungan = detailLampIID.daftarKeluarga.filter(
          (d) => ["suami", "isteri"].indexOf(d.hubungan.toLowerCase()) < 0
        ).length;
        const jumlahTanggungan =
          __jumlahTanggungan > 3 ? 3 : __jumlahTanggungan;

        const resB7 = await getPTKP(
          profile.tahunPajak,
          flagPTKP,
          jumlahTanggungan
        );
        const B7 = isPhmt ? "0" : number.format(resB7.data.nominal);
        const A6 = data.A.A6;
        const B8 =
          number.clearFormat(A6) < number.clearFormat(B7)
            ? "0"
            : number.sumFormatted(A6, `-${B7}`);
        const B = Object.assign(data.B, {
          flagPTKP,
          jumlahTanggungan: String(jumlahTanggungan),
          B7,
          B8: number.roundFormatted(B8, false),
        });

        resolve(B);
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateC = (data) => {
    let B8 = number.clearFormat(data.B.B8);
    let C9 = 0;

    if (isPhmt) {
      C9 = phmt.F4;
    } else {
      let limits = [
        { limit: 50 * Math.pow(10, 6), val: 0.05 },
        { limit: 200 * Math.pow(10, 6), val: 0.15 },
        { limit: 250 * Math.pow(10, 6), val: 0.25 },
        { limit: 500 * Math.pow(10, 6), val: 0.3 },
      ];
      if (parseInt(profile.tahunPajak) >= 2022) {
        limits = [
          { limit: 60 * Math.pow(10, 6), val: 0.05 },
          { limit: 190 * Math.pow(10, 6), val: 0.15 },
          { limit: 250 * Math.pow(10, 6), val: 0.25 },
          { limit: 4500 * Math.pow(10, 6), val: 0.3 },
          { limit: 5000 * Math.pow(10, 6), val: 0.35 },
        ];
      }

      for (let i = 0; i < limits.length; i++) {
        let l = limits[i];
        if (B8 > 0) {
          if (B8 > l.limit) {
            let count = (i === limits.length - 1 ? B8 : l.limit) * l.val;
            C9 += count;
            B8 -= l.limit;
          } else {
            C9 += B8 * l.val;
            B8 -= l.limit;
          }
        }
      }

      // C9 = number.roundFormatted(C9, false)
      if (profile.fghs === true) {
        C9 = data.C.C9;
      } else {
        C9 = number.format(C9);
      }
    }
    const C11 = number.sumFormatted(C9, data.C.C10);
    const C = Object.assign(data.C, { C9, C11 });
    return C;
  };

  const updateD = (data) => {
    const D12 = headerLampI.jumlahBC || "0";
    const _C11 = number.clearFormat(data.C.C11);
    const _D12 = number.clearFormat(D12);
    // let _D13 = number.sumFormatted(data.C.C11, `-${D12}`);
    let _D13 = _C11 - _D12;
    _D13 = number.clearFormat(_D13);
    const flagD13 = _D13 > 0 ? "1" : _D13 < 0 ? "2" : "0";

    const D13 = number.format(_D13);
    const D15 = number.sumFormatted(data.D.D14a, data.D.D14b);
    const D = Object.assign(data.D, { D12, D13, D15, flagD13 });
    return D;
  };

  const updateE = (data) => {
    const D13 = number.clearFormat(data.D.D13);
    const D15 = number.clearFormat(data.D.D15);
    let E16 = D13 - D15;

    const flagE16 = E16 > 0 ? "1" : E16 < -1000 ? "2" : "0";

    E16 = E16 >= -1000 && E16 < 0 ? 0 : E16;

    const E17 =
      E16 >= 0 ? "" : ["", "0"].indexOf(data.E.E17) < 0 ? data.E.E17 : "1";

    const E = Object.assign(data.E, { flagE16, E16: number.format(E16), E17 });
    return E;
  };

  const updateF = (data) => {
    let F18 =
      data.F.flagF18 === "0"
        ? "0"
        : data.F.flagF18 === "1"
        ? number.clearFormat(data.D.D13) / 12
        : number.clearFormat(data.F.F18);

    F18 = Math.floor(F18);
    const F = Object.assign(data.F, { F18: number.format(F18) });

    return F;
  };

  const updateCheckList = (data) => {
    const checkGb = data.E.flagE16 === "1" ? true : false; //headerSSP.jumlahItem >= 0 ? true : false
    const checkGd = isPhmt;
    const checkList = Object.assign(data.checkList, { checkGb, checkGd });
    return checkList;
  };

  useEffect(async () => {
    try {
      let defData = storage.get(storage.keys.SPT_1770S_INDUK);
      // BAGIAN A
      const A = updateA(defData);
      defData = Object.assign(defData, { A });

      //BAGIAN B, C & D
      const B = await updateB(defData);
      defData = Object.assign(defData, { B });
      const C = updateC(defData);
      defData = Object.assign(defData, { C });
      const D = updateD(defData);
      defData = Object.assign(defData, { D });
      const E = updateE(defData);
      defData = Object.assign(defData, { E });
      const F = updateF(defData);
      defData = Object.assign(defData, { F });
      const checkList = updateCheckList(defData);
      defData = Object.assign(defData, { checkList });

      setDefaultData(defData);
      storage.update(storage.keys.SPT_1770S_INDUK, defData);
      setReady(true);
    } catch (error) {
      toast.errorRequest(error);
    }
  }, []);

  const [
    panel1Props,
    panel2Props,
    panel3Props,
    panel4Props,
    panel5Props,
    panel6Props,
    panel7Props,
  ] = panels;
  return (
    <div
      className="mod-submission-spt-1770S-induk mpk-margin-C"
      style={{
        width: "100%",
        maxWidth: 800,
      }}
    >
      {ready ? (
        <div>
          {props.errorMessage && (
            <div className="mpk-paper mpk-padding-N padding-all">
              <Hint>{props.errorMessage}</Hint>
            </div>
          )}
          <ExpansionList
            onKeyDown={onKeyDown}
            className="mpk-margin-N margin-top"
          >
            {[
              {
                label: t.translate("modules.submission.sptForm.1770S.Induk.A"),
                render: (
                  <BagianA
                    onSave={handleSave}
                    defaultData={defaultData.A}
                    isPhmt={isPhmt}
                    {...props}
                  />
                ),
                panelProps: panel1Props,
              },
              {
                label: t.translate("modules.submission.sptForm.1770S.Induk.B"),
                render: (
                  <BagianB
                    onSave={handleSave}
                    defaultData={defaultData.B}
                    isPhmt={isPhmt}
                    {...props}
                  />
                ),
                panelProps: panel2Props,
              },
              {
                label: t.translate("modules.submission.sptForm.1770S.Induk.C"),
                render: (
                  <BagianC
                    onSave={handleSave}
                    defaultData={defaultData.C}
                    isPhmt={isPhmt}
                    {...props}
                  />
                ),
                panelProps: panel3Props,
              },
              {
                label: t.translate("modules.submission.sptForm.1770S.Induk.D"),
                render: (
                  <BagianD
                    onSave={handleSave}
                    defaultData={defaultData.D}
                    isPhmt={isPhmt}
                    {...props}
                  />
                ),
                panelProps: panel4Props,
              },
              {
                label: t.translate("modules.submission.sptForm.1770S.Induk.E"),
                render: (
                  <BagianE
                    onSave={handleSave}
                    defaultData={defaultData.E}
                    isPhmt={isPhmt}
                    {...props}
                  />
                ),
                panelProps: panel5Props,
              },
              {
                label: t.translate("modules.submission.sptForm.1770S.Induk.F"),
                render: (
                  <BagianF
                    onSave={handleSave}
                    defaultData={defaultData.F}
                    isPhmt={isPhmt}
                    {...props}
                  />
                ),
                panelProps: panel6Props,
              },
              {
                label: t.translate(
                  "modules.submission.sptForm.1770S.Induk.CheckList"
                ),
                render: (
                  <CheckList
                    onSave={handleSave}
                    defaultData={defaultData.checkList}
                    {...props}
                  />
                ),
                panelProps: panel7Props,
              },
            ].map((p, i) => (
              <ExpansionPanel
                key={`spt-1770S-induk-panel-${i}`}
                {...p.panelProps}
                header={
                  <div className="mpk-font size-M weight-M">{p.label}</div>
                }
              >
                {p.render}
              </ExpansionPanel>
            ))}
          </ExpansionList>
          <Header
            defaultData={{
              flagTandaTanganSPT: defaultData.flagTandaTanganSPT,
              tanggalSPT: defaultData.tanggalSPT,
              npwpPelapor: defaultData.npwpPelapor,
              namaWPPelapor: defaultData.namaWPPelapor,
            }}
            onSave={handleSave}
            onNextTab={props.onNextTab}
          />
        </div>
      ) : (
        <LoaderInfo />
      )}
    </div>
  );
};

Induk.generate = generate;

export default inject(
  "navigationStore",
  "envStore",
  "authStore"
)(observer(Induk));
