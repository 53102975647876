import { merge } from "lodash";
import { storage } from ".";
import { number } from "../libs/react-mpk/services";
import moment from "moment";

export const set = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const get = (key) => {
  let item = localStorage.getItem(key);
  return JSON.parse(item);
};

export const remove = (key) => {
  localStorage.removeItem(key);
};

export const update = (key, value) => {
  let item = localStorage.getItem(key);
  if (item) value = Object.assign(JSON.parse(item), value);
  set(key, value);
};

export const generate = (key, value) => {
  return new Promise((resolve) => {
    let item = get(key);
    if (item) resolve(item);
    else {
      set(key, value);
      resolve(value);
    }
  });
};

export const formatKeys = {
  spt1770: {
    profile: {
      f: [],
      e: [
        "tahunPajak",
        "kodePembetulan",
        "tahunMulai",
        "tahunSelesai",
        "flagHitung",
      ],
      u: (d) => {
        d.kodePembetulan = String(d.kodePembetulan);
        d.flagHitung = String(d.flagHitung);
        if (d.fghs) {
          d.fghs = d.fghs === "1" ? true : false;
        }
        return d;
      },
    },
    induk: {
      f: [
        "A.*",
        "B.B8",
        "B.B9",
        "B.B10",
        "B.B11",
        "C.C12",
        "C.C13",
        "C.C14",
        "D.D15",
        "D.D16",
        "D.D17a",
        "D.D17b",
        "D.D18",
        "E.E19",
        "F.F21",
        "flagTandaTanganSPT",
      ],
      e: [],
      u: (d) => {
        if (d.C.checkSelfCount) {
          d.C.checkSelfCount = d.C.checkSelfCount === "1" ? true : false;
        }
        for (let key of Object.keys(d.checkList)) {
          if (key.startsWith("check")) {
            d.checkList[key] = d.checkList[key] === "1" ? true : false;
          } else {
            d.checkList[key] = String(d.checkList[key]);
          }
        }
        return d;
      },
    },
    headerLampI: {
      f: ["*"],
      e: ["npwp", "namaWP", "bulanMulai", "bulanSelesai"],
    },
    detailLampIA: {
      f: ["flagAudit", "A.*"],
      e: [],
    },
    detailLampIB: {
      // f:['B.*'],
      f: [
        "B.B13",
        "B.B15",
        "B.B23",
        "B.B25",
        "B.B33",
        "B.B35",
        "B.B43",
        "B.B45",
        "B.B53",
        "B.B55",
      ],
      e: [],
      u: (d) => {
        d.B.B14 = String(d.B.B14);
        d.B.B24 = String(d.B.B24);
        d.B.B34 = String(d.B.B34);
        d.B.B44 = String(d.B.B44);
        d.B.B54 = String(d.B.B54);
        return d;
      },
    },
    detailLampIC: {
      f: ["daftarIC.#.biaya", "daftarIC.#.bruto", "daftarIC.#.neto"],
      e: [],
    },
    detailLampID: {
      f: ["D.*"],
      e: [],
    },
    headerLampII: {
      f: ["*"],
      e: ["npwp", "namaWP", "bulanMulai", "bulanSelesai"],
    },
    detailLampII: {
      f: ["daftarBuktiPotong.#.jumlahPPH"],
      e: [],
    },
    headerLampIII: {
      f: ["*"],
      e: ["npwp", "namaWP", "bulanMulai", "bulanSelesai"],
    },
    detailLampIIIA: {
      f: [
        "A1.*",
        "A2.*",
        "A3.*",
        "A4.*",
        "A5.*",
        "A6.*",
        "A7.*",
        "A8.*",
        "A9.*",
        "A10.*",
        "A11.*",
        "A12.*",
        "A13.*",
        "A14.*",
        "A15.*",
        "A16.*",
      ],
      e: [],
    },
    detailLampIIIB: {
      f: ["B.*"],
      e: [],
    },
    headerLampIV: {
      f: ["*"],
      e: ["npwp", "namaWP", "bulanMulai", "bulanSelesai"],
    },
    detailLampIVA: {
      f: ["daftarHarta.#.hargaPerolehan", "daftarHarta.#.tahunPerolehan"],
      e: [],
      u: (d) => {
        d.daftarHarta = d.daftarHarta.map((item) => {
          item.tahunPerolehan = String(number.clearFormat(item.tahunPerolehan));
          return item;
        });
        return d;
      },
    },
    detailLampIVB: {
      f: [
        "daftarKewajiban.#.tahunPinjaman",
        "daftarKewajiban.#.jumlahPinjaman",
      ],
      e: [],
      u: (d) => {
        d.daftarKewajiban = d.daftarKewajiban.map((item) => {
          item.tahunPinjaman = String(number.clearFormat(item.tahunPinjaman));
          return item;
        });
        return d;
      },
    },
    detailLampIVC: {
      f: [],
      e: [],
    },
    PHMT: {
      f: ["*"],
      e: ["C2", "C3"],
    },
    headerPP46: {
      f: ["jumlahDetail", "jumlahPPHFinal", "jumlahPenghasilanBruto"],
      e: [],
    },
    detailPP46: {
      f: ["daftarPP46.#.*"],
      e: ["alamat", "npwp"],
    },
    headerSSP: {
      f: ["jumlahItem", "jumlahSetor"],
      e: [],
    },
    detailSSP: {
      f: ["daftarSSP.#.jumlahSSP"],
      e: [],
    },
  },
  spt1770S: {
    profile: {
      f: [],
      e: ["tahunPajak", "kodePembetulan", "flagHitung"],
      u: (d) => {
        if (d.fghs) {
          d.fghs = d.fghs === "1" ? true : false;
        }
        d.kodePembetulan = String(d.kodePembetulan);
        d.flagHitung = String(d.flagHitung);
        return d;
      },
    },
    induk: {
      f: [
        "A.*",
        "B.*",
        "C.C9",
        "C.C10",
        "C.C11",
        "D.*",
        "E.E16",
        "F.*",
        "flagTandaTanganSPT",
      ],
      e: ["flagD13", "flagE16", "flagF18", "flagPTKP", "jumlahTanggungan"],
      u: (d) => {
        if (d.C.checkSelfCount) {
          d.C.checkSelfCount = d.C.checkSelfCount === "1" ? true : false;
        }
        for (let key of Object.keys(d.checkList)) {
          if (key.startsWith("check")) {
            d.checkList[key] = d.checkList[key] === "1" ? true : false;
          }
        }
        return d;
      },
    },
    headerLampI: {
      f: ["*"],
      e: [],
    },
    detailLampIA: {
      f: ["A.*"],
      e: [],
    },
    detailLampIB: {
      f: ["B.*"],
      e: [],
    },
    detailLampIC: {
      f: ["daftarBuktiPotong.#.jumlahPPH"],
      e: [],
    },
    headerLampII: {
      f: ["*"],
      e: [],
    },
    detailLampIIA: {
      f: ["*"],
      e: [],
    },
    detailLampIIB: {
      f: ["daftarHarta.#.hargaPerolehan", "daftarHarta.#.tahunPerolehan"],
      e: [],
      u: (d) => {
        d.daftarHarta = d.daftarHarta.map((item) => {
          item.tahunPerolehan = String(number.clearFormat(item.tahunPerolehan));
          return item;
        });
        return d;
      },
    },
    detailLampIIC: {
      f: [
        "daftarKewajiban.#.tahunPinjaman",
        "daftarKewajiban.#.jumlahPinjaman",
      ],
      e: [],
      u: (d) => {
        d.daftarKewajiban = d.daftarKewajiban.map((item) => {
          item.tahunPinjaman = String(number.clearFormat(item.tahunPinjaman));
          return item;
        });
        return d;
      },
    },
    detailLampIID: {
      f: [],
      e: [],
    },
    PHMT: {
      f: ["*"],
      e: ["C2", "C3"],
    },
    headerSSP: {
      f: ["jumlahItem", "jumlahSetor"],
      e: [],
    },
    detailSSP: {
      f: ["daftarSSP.#.jumlahSSP"],
      e: [],
    },
  },
};

export const populate = (code, data) => {
  return new Promise(async (resolve) => {
    for (let key of Object.keys(formatKeys[`spt${code}`])) {
      let src = data[key];
      if (src) {
        if (key === "PHMT") {
          src = {
            ...src.A,
            B4: src.B4,
            ...src.C,
            D4: src.D4,
            ...src.E,
            F4: src.F4,
            G4: src.G4,
            as: src.as,
          };
        }
        if (code === "1770S" && key === "detailLampIIA") {
          let newSrc = {};
          for (let x = 1; x <= 14; x++) {
            let k3 = `A${x}3`;
            let k4 = `A${x}4`;
            newSrc[k3] = src[`A${x}`][k3];
            newSrc[k4] = src[`A${x}`][k4];
            // newSrc[`A${x}`] = {}
            // newSrc[`A${x}`][k3] = src[k3]
            // newSrc[`A${x}`][k4] = src[k4]
          }
          src = newSrc;
        }

        src = await number.clearFormatBulk(
          src,
          formatKeys[`spt${code}`][key].f,
          [
            ...formatKeys[`spt${code}`][key].e,
            "tahunPajak",
            "tahunPerolehan",
            "tahunSelesai",
            "tahunMulai",
            "as",
          ],
          true
        );
        let format = formatKeys[`spt${code}`][key];
        src = format && format.u ? formatKeys[`spt${code}`][key].u(src) : src;

        if (src.tahunPajak) src.tahunPajak = String(src.tahunPajak);
        set(`${code}-${key}`, src);
      }
    }

    set(keys.SPT_INIT, {
      tahunPajak: data.profile.tahunPajak,
      kodePembetulan: data.profile.kodePembetulan,
      npwp: data.profile.npwp,
      namaWP: data.profile.namaWp,
    });
    resolve();
  });
};

export const clearAll = () => {
  for (let key of Object.keys(keys)) {
    storage.remove(keys[key]);
  }
};

export const keys = {
  SPT_SUBMISSION: "submission-data",

  SPT_INIT: "init-data",
  SPT_1770_INDUK: "1770-induk",
  SPT_1770_PROFILE: "1770-profile",
  SPT_1770_HEADER_LAMP_I: "1770-headerLampI",
  SPT_1770_DETAIL_LAMP_IA: "1770-detailLampIA",
  SPT_1770_DETAIL_LAMP_IB: "1770-detailLampIB",
  SPT_1770_DETAIL_LAMP_IC: "1770-detailLampIC",
  SPT_1770_DETAIL_LAMP_ID: "1770-detailLampID",
  SPT_1770_HEADER_LAMP_II: "1770-headerLampII",
  SPT_1770_DETAIL_LAMP_II: "1770-detailLampII",
  SPT_1770_HEADER_LAMP_III: "1770-headerLampIII",
  SPT_1770_DETAIL_LAMP_IIIA: "1770-detailLampIIIA",
  SPT_1770_DETAIL_LAMP_IIIB: "1770-detailLampIIIB",
  SPT_1770_HEADER_LAMP_IV: "1770-headerLampIV",
  SPT_1770_DETAIL_LAMP_IVA: "1770-detailLampIVA",
  SPT_1770_DETAIL_LAMP_IVB: "1770-detailLampIVB",
  SPT_1770_DETAIL_LAMP_IVC: "1770-detailLampIVC",
  SPT_1770_DETAIL_LAMP_IVD: "1770-detailLampIVD",
  SPT_1770_PHMT: "1770-PHMT",
  SPT_1770_HEADER_PP46: "1770-headerPP46",
  SPT_1770_DETAIL_PP46: "1770-detailPP46",
  SPT_1770_HEADER_SSP: "1770-headerSSP",
  SPT_1770_DETAIL_SSP: "1770-detailSSP",

  SPT_1770S_INDUK: "1770S-induk",
  SPT_1770S_PROFILE: "1770S-profile",
  SPT_1770S_HEADER_LAMP_I: "1770S-headerLampI",
  SPT_1770S_DETAIL_LAMP_IA: "1770S-detailLampIA",
  SPT_1770S_DETAIL_LAMP_IB: "1770S-detailLampIB",
  SPT_1770S_DETAIL_LAMP_IC: "1770S-detailLampIC",
  SPT_1770S_DETAIL_LAMP_ID: "1770S-detailLampID",
  SPT_1770S_HEADER_LAMP_II: "1770S-headerLampII",
  SPT_1770S_DETAIL_LAMP_IIA: "1770S-detailLampIIA",
  SPT_1770S_DETAIL_LAMP_IIB: "1770S-detailLampIIB",
  SPT_1770S_DETAIL_LAMP_IIC: "1770S-detailLampIIC",
  SPT_1770S_DETAIL_LAMP_IID: "1770S-detailLampIID",
  SPT_1770S_PHMT: "1770S-PHMT",
  SPT_1770S_HEADER_SSP: "1770S-headerSSP",
  SPT_1770S_DETAIL_SSP: "1770S-detailSSP",
};
