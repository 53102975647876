import React from 'react'
import { DataForm } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import t from 'counterpart'
import { storage } from '../../../../../services'

const { inputTypes } = DataForm

const BagianA = ({
  defaultData = {},
  ...props
}) => {
  const phmt = storage.get(storage.keys.SPT_1770_PHMT)
  return (
    <DataForm
      baseId={`spt-1770-induk-A`}
      className="mpk-padding-NONE padding-all"
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.A1`),
          key: 'A1',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.A2`),
          key: 'A2',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.A3`),
          key: 'A3',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.A4`),
          key: 'A4',
          disabled: props.isPhmt
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.A5`),
          key: 'A5',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.A6`),
          key: 'A6',
          disabled: props.isPhmt
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.A7`),
          key: 'A7',
          disabled: true
        },
      ]}
      onBeforeChange={(key, value) => (
        number.format(number.clearFormat(value.replace(/\D/g, ''))))
      }
      onChange={(data, key) => {
        data.A5 = number.format(['A1', 'A2', 'A3', 'A4'].map((a) => (Number(number.clearFormat(data[a])))).reduce((a,b) => (a+b), 0))
        data.A7 = number.format(Number(number.clearFormat(data.A5)) - Number(number.clearFormat(data.A6)))
        return data
      }}
      onSubmit={(data, callback) => {
        if(props.isPhmt){
          phmt.A43 = data.A4
          phmt.A53 = data.A6
          storage.update(storage.keys.SPT_1770_PHMT, phmt)
        }
        props.onSave({A:data});
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  )
}

export default BagianA
