import React, { useState, useEffect } from 'react'
import { DataForm, LoaderInfo } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { storage } from '../../../../../services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const generate = () => {
  let data = storage.generate(storage.keys.SPT_1770_DETAIL_LAMP_IB, {
    B:{
      B13: "0",
      B14: "0",
      B15: "0",
      B23: "0",
      B24: "0",
      B25: "0",
      B33: "0",
      B34: "0",
      B35: "0",
      B43: "0",
      B44: "0",
      B45: "0",
      B53: "0",
      B54: "0",
      B55: "0"
    }
  })

  return data
}
const { inputTypes } = DataForm
const DetailLampIB = (props) => {
  const [ready, setReady] = useState(false)
  const [defaultData, setDefaultData] = useState({})
  const [B, setB] = useState({})
  useEffect(() => {
    let defData = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_IB)
    setDefaultData(defData)
    setB(defData.B)
    setReady(true)
  }, [])

  return ready ? (
    <DataForm
      baseId="spt-1770-header-lamp-IB"
      className="mpk-padding-NONE padding-all"
      style={{
        width: '100%',
        maxWidth: 800,
      }}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          render: (
            <DataForm
              baseId="spt-1770-header-lamp-IB-B"
              className="mpk-padding-NONE padding-all"
              style={{maxWidth: 'unset'}}
              asDialog={false}
              defaultData={B}
              definitions={[
                 {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B13'),
                    key:'B13',
                    className: 'mpk-padding-S padding-right',
                    width: '40%'
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B14'),
                    key:'B14',
                    rightChildren: '%',
                    width: '20%',
                    type: 'number',
                    step: '0.01',
                    min: '0',
                    max: '100'
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B15'),
                    key:'B15',
                    className: 'mpk-padding-N padding-left',
                    width: '40%',
                    disabled: false
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B23'),
                    key:'B23',
                    className: 'mpk-padding-S padding-right',
                    width: '40%'
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B24'),
                    key:'B24',
                    rightChildren: '%',
                    width: '20%',
                    type: 'number',
                    step: '0.01',
                    min: '0',
                    max: '100'
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B25'),
                    key:'B25',
                    className: 'mpk-padding-N padding-left',
                    width: '40%',
                    disabled: false
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B33'),
                    key:'B33',
                    className: 'mpk-padding-S padding-right',
                    width: '40%'
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B34'),
                    key:'B34',
                    rightChildren: '%',
                    width: '20%',
                    type: 'number',
                    step: '0.01',
                    min: '0',
                    max: '100'
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B35'),
                    key:'B35',
                    className: 'mpk-padding-N padding-left',
                    width: '40%',
                    disabled: false
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B43'),
                    key:'B43',
                    className: 'mpk-padding-S padding-right',
                    width: '40%'
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B44'),
                    key:'B44',
                    rightChildren: '%',
                    width: '20%',
                    type: 'number',
                    step: '0.01',
                    min: '0',
                    max: '100'
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B45'),
                    key:'B45',
                    className: 'mpk-padding-N padding-left',
                    width: '40%',
                    disabled: false
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B53'),
                    key:'B53',
                    className: 'mpk-padding-S padding-right',
                    width: '40%'
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B54'),
                    key:'B54',
                    rightChildren: '%',
                    width: '20%',
                    type: 'number',
                    step: '0.01',
                    min: '0',
                    max: '100'
                  },
                  {
                    inputType: inputTypes.INPUT,
                    label: t.translate('modules.submission.sptForm.1770.DetailLampIB.B55'),
                    key:'B55',
                    className: 'mpk-padding-N padding-left',
                    width: '40%',
                    disabled: false
                  }
              ]}
              onBeforeChange={(key, value) => (
                ['B14','B24','B34','B44','B54'].indexOf(key) >= 0 
                  ? (value > 100 
                    ? '100' 
                    : (value < 0 
                      ? '0' 
                      : value
                    )
                  )
                  : number.format(number.clearFormat(value.replace(/\D/g, '')))
              )}
              onChange={(data, key) => {
                if([
                  'B13', 'B14',
                  'B23', 'B24',
                  'B33', 'B34',
                  'B43', 'B44',
                  'B53', 'B54',
                ].indexOf(key) >= 0){
                  for(let k of [
                    ['B13', 'B14', 'B15'],
                    ['B23', 'B24', 'B25'],
                    ['B33', 'B34', 'B35'],
                    ['B43', 'B44', 'B45'],
                    ['B53', 'B54', 'B55'],
                  ]){
                    let a = number.clearFormat(data[k[0]])
                    let b = Number(data[k[1]])
                    data[k[2]] = number.format(a*(b/100))
                  }
                } else if(['B15', 'B25', 'B35', 'B45', 'B55'].indexOf(key) >= 0){
                  let b = {
                    B15:'B13',
                    B25:'B23',
                    B35:'B33',
                    B45:'B43',
                    B55:'B53'
                  }
                  if(number.clearFormat(data[key]) > number.clearFormat(data[b[key]])) 
                    data[key] = data[b[key]];
                }

                setB(data)
                return data
              }}
              submitLabel={t.translate('column.save')}
            />
          )
        }
      ]}
      onSubmit={(data, callback) => {
        let newObj = Object.assign(data, {B})
        props.onSave(storage.keys.SPT_1770_DETAIL_LAMP_IB, newObj)
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  ) : <LoaderInfo/>
}

DetailLampIB.generate = generate

export default inject('envStore')(observer(DetailLampIB))
