import React, { useState } from 'react'
import { CircularProgress, DropdownMenu, MenuItem } from 'react-md'
import { toast } from '../../../../libs/react-mpk/services'
import { downloadSpt, exportSpt } from '../../Submission.service'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const ExportSPT = ({
    className       = '',
    submission,
    kota,
    code='1770',
    ...props
}) => {
    const [inProgress, setInProgress] = useState(false)
    let code_1770S = [
        {
            label: 'Induk',
            key: 'induk_1770S',
            title: 'column.induk',
            fileName: 'Induk 1770S-{fileName}.pdf',

        },
        {
            label: 'LampI',
            key: 'lampiran_1_1770S',
            fileName: 'Lampiran I-1770S-{fileName}.pdf',
            title: 'column.lampiranI'
        },
        {
            label:'LampII',
            key:'lampiran_2_1770S',
            fileName: 'Lampiran II-1770S-{fileName}.pdf',
            title: 'column.lampiranII'
        },
        {
            label:'PHMT',
            key:'phmt',
            fileName: 'PHMT -1770S-{fileName}.pdf',
            title: 'column.phmt'
        },
    ]
    return submission && submission.referenceNo ? (
        <DropdownMenu
            id="export-dropdown"
            disableDropdownIcon
            theme="primary"
            themeType="outline"
            className={className}
            disabled={inProgress}
            items={code_1770S.map(d => (
                <MenuItem
                    style={{
                        width: 240
                    }}
                    onClick={async () => {
                        try {
                            setInProgress(true)
                            let res = await exportSpt(submission.fileName, d.key, props.authStore.user.isSupport, kota,code)
                            await downloadSpt(submission.fileName, res.data, d.fileName.replace(`{fileName}`, submission.fileName))
                            setInProgress(false)
                        } catch (error) {
                            setInProgress(false)
                            toast.errorRequest(error)
                        }
                    }}
                >
                    {t.translate(`${d.title}`)}
                </MenuItem>
            ))}
        >
            {inProgress && <CircularProgress className='mpk-margin-S margin-right'/>}
            {t.translate('column.exportSPT')}
        </DropdownMenu>
    ) : null
}

export default inject('authStore')(observer(ExportSPT))