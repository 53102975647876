import React, {useEffect, useState} from 'react'
import { ExpansionList, ExpansionPanel, usePanels } from 'react-md'
import { storage } from '../../../../../services'
import { LoaderInfo, Hint } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import HeaderLampII from './HeaderLampII'
import DetailLampII from './DetailLampII'

const generate = () => {
  HeaderLampII.generate()
  DetailLampII.generate()
}

const LampiranII = (props) => {
  const [ready, setReady] = useState(false)
  const [panels, onKeyDown] = usePanels({
    idPrefix: 'spt-1770-lampiran-II',
    count:2,
    defaultExpandedIndex: 0
  })

  const handleSave = (key, update) => {
    storage.update(key, update);
    updateHeader()
  }

  const updateHeader = () => {
    let header = storage.get(storage.keys.SPT_1770_HEADER_LAMP_II)
    let detail = storage.get(storage.keys.SPT_1770_DETAIL_LAMP_II)
    let profile = storage.get(storage.keys.SPT_1770_PROFILE)
    
    header.jumlahItemA = detail ? String(detail.daftarBuktiPotong.length) : '0'
    header.jumlahBA = detail ? number.sumFormatted(
      ...detail.daftarBuktiPotong.map(d => (d.jumlahPPH))
    ) : '0'
    storage.set(storage.keys.SPT_1770_HEADER_LAMP_II, header)
  }

  useEffect(() => {
    updateHeader()
    setReady(true)
  }, [])

  const [panel1Props, panel2Props] = panels;
  return (
    <div 
      className="mod-submission-spt-1770-lampiran-II mpk-margin-C"
      style={{
        width: '100%',
        maxWidth: 800
      }}
    >
      { ready ? (
        <div>
          {props.errorMessage && 
            <div className="mpk-paper mpk-padding-N padding-all">
              <Hint>{props.errorMessage}</Hint>
            </div>
          }
          <ExpansionList onKeyDown={onKeyDown}>
            {[
              {
                label: t.translate('modules.submission.sptForm.1770.DetailLampII.title'),
                render: (<DetailLampII onSave={handleSave}/>),
                panelProps: panel1Props
              },
              {
                label: t.translate('modules.submission.sptForm.1770.HeaderLampII.title'),
                render: (<HeaderLampII onSave={handleSave} {...props}/>),
                panelProps: panel2Props
              },
            ].map((p, i) => (
              <ExpansionPanel 
                key={`spt-1770-induk-panel-${i}`}
                {...p.panelProps}
                header={<div className="mpk-font size-M weight-M">{p.label}</div>}
              >
                {p.render}
              </ExpansionPanel>
            ))}
          </ExpansionList>
        </div>
      ) : <LoaderInfo/> }  
    </div>
  )
}

LampiranII.generate = generate

export default inject('navigationStore', 'envStore', 'authStore')(observer(LampiranII))
