import React, { useState, useEffect } from 'react'
import { Modal,LoaderInfo } from '../../../libs/react-mpk/components'
import { FontIcon } from 'react-md'
import t from 'counterpart'
import { toast } from '../../../libs/react-mpk/services'
import { getHistory } from '../Submission.service'
import './History.scss'
import moment from 'moment'
import { inject, observer } from 'mobx-react'

const History = ({
  visible     = false,
  onRequestClose,
  item,
  ...props
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading ] = useState(false)

  const handleFetchData = async () => {
    setLoading(true)
    try {
      let res = await getHistory(item.referenceNo, props.authStore.user.isSupport)
      setData(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.errorRequest(error)
    }
  }

  useEffect(() => {
    if(item) handleFetchData()
    else setTimeout(() => setData([]), 500)
  }, [visible])

  return (
    <Modal.Info
      baseId="mod-submission-history"
      id="naha teu nisa sih"
      title={t.translate('modules.submission.history.title')}
      className="mod-submission-history"
      visible={visible}
      onRequestClose={onRequestClose}
      disableFocusOnMount={true}
      style={{
        width: '100%',
        maxWidth: 640
      }}
    >
      { loading ? <LoaderInfo children="loading data.."/> : data.map((d, i) => (
        <div className={`log-item mpk-flex ${d.isError ? 'is-error' : ''}`}>
          <div className="flex-none node">
            <FontIcon iconClassName={d.isError ? 'mdi mdi-alert' : 'mdi mdi-check'}/>
          </div>
          <div className="flex">
            <div className="date">
              {moment(d.createdAt.$date).format('LLL')}
            </div>
            <div className="label">{String(d.function).replace(/\_/g, ' ').toUpperCase()}</div>
            {/* <div className="info">{d.status}</div> */}
            {d.isError ? (
              <div className="error">{d.error}</div>
            ) : null}
          </div>
        </div>
      ))}
    </Modal.Info>
  )
}

export default inject('authStore')(observer(History))
