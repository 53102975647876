import React from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './TableSample.service'
import moment from 'moment'

const TableSample = ({
  className       = '',
  showCommandbar  = true
}) => {
  return (
    <TableWrapper
      baseId="mod-table-sample"
      title={t.translate('modules.table.title')}
      className={className}
      hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="date"
      columns={[
        {
          label: 'String',
          searchable: true,
          key:'string',
          render: item => (
            <TableWrapper.Link
              to={`/internal/inline-form`}
            >
              {item.string}
            </TableWrapper.Link>)
        },
        {
          label: 'Number',
          searchable: true,
          key: 'number',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.number)
        },
        {
          label: 'Boolean',
          type: TableWrapper.dataTypes.BOOLEAN,
          render: item => (
            <TableWrapper.Status 
              type={item.boolean 
                ? TableWrapper.Status.types.SUCCESS
                : TableWrapper.Status.types.ERROR
              }
            >
              {item.boolean ? 'TRUE' : 'FALSE'}
            </TableWrapper.Status>
          )
        },
        {
          label: 'Date',
          type: TableWrapper.dataTypes.DATE,
          render: item => (moment(item.date).format('lll'))
        }
      ]}
      actions={[
        {
          label: 'Add',
          iconClassName: 'mdi mdi-plus'
        }
      ]}
      itemActions={[
        {
          label: 'Edit',
          iconClassName: 'mdi mdi-pencil'
        },
        {
          label: 'Delete',
          iconClassName: 'mdi mdi-delete'
        }
      ]}
      onFetchData={ async query => {
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
  )
}

export default TableSample
