import React from 'react'
import { DataForm, Link } from '../../../../../libs/react-mpk/components'
import { number } from '../../../../../libs/react-mpk/services'
import t from 'counterpart'
import { storage } from '../../../../../services'
import { inject, observer } from 'mobx-react'
import { FontIcon, Button } from 'react-md'
import { getPTKP } from '../../../Submission.service'

const { inputTypes } = DataForm

const BagianB = ({
  defaultData = {},
  ...props
}) => {
  const indukData = storage.get(storage.keys.SPT_1770_INDUK)
  const profile = storage.get(storage.keys.SPT_1770_PROFILE)
  const getUpdateData = (data, key) => {
    return new Promise(async (resolve, reject) => {
      const B9 = number.format(number.clearFormat(indukData.A.A7)-number.clearFormat(data.B8))
      let B10 = data.B10
      if(key === 'flagPTKP' || key === 'jumlahTanggungan'){
        let resFlagPTKP = await getPTKP(profile.tahunPajak, data.flagPTKP, data.jumlahTanggungan)
        B10 = number.format(resFlagPTKP.data.nominal)
      }

      const B11 = number.clearFormat(B9) < number.clearFormat(B10)
        ? '0'
        : number.sumFormatted(B9, `-${B10}`)

      let obj = {
        B9,
        B10,
        B11,
        
      }
      resolve(obj)
    })
  }

  return (
    <DataForm
      baseId={`spt-1770-induk-B`}
      className="mpk-padding-NONE padding-all"
      asDialog={false}
      defaultData={{...defaultData, ...getUpdateData(defaultData)}}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.B8`),
          key: 'B8',
          disabled: props.isPhmt,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.B9`),
          key: 'B9',
          disabled: true
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.FlgPTKP`),
          key: 'flagPTKP',
          width:'70%',
          className:"mpk-padding-N padding-right",
          options: props.isPhmt 
            ? props.envStore.env.spt.FlgPTKP1770
              .filter(d => (d.value !== '3')) 
            : profile.flagSuamiIstri === '2'
              ? props.envStore.env.spt.FlgPTKP1770.filter(d => (d.code === 'TK'))
              : props.envStore.env.spt.FlgPTKP1770,
          disabled: false,
          show: !props.isPhmt
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.JmlTanggungan`),
          key: 'jumlahTanggungan',
          width: '30%',
          type: 'number',
          disabled: true,
          show: !props.isPhmt
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.B10`),
          key: 'B10',
          disabled: true,
          show: !props.isPhmt
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`modules.submission.sptForm.1770.Induk.B11`),
          key: 'B11',
          disabled: true
        }
      ]}
      onBeforeChange={(key, value) => (
        ['B8', 'B9', 'B10'].indexOf(key) >= 0
          ? number.format(number.clearFormat(value.replace(/\D/g, '')))
          : value
      )}
      onChange={async (data, key) => {
        // const update = await getUpdateData(data, key)
        if(key === 'B8'){
          data.B9 = number.format(number.clearFormat(indukData.A.A7.replace(/\D/g, '')) -number.clearFormat(data.B8))
          console.log("🚀 ~ file: BagianB.js ~ line 107 ~ onChange={ ~ data.B9", data.B9)
        }
        if(key === 'flagPTKP' || key === 'jumlahTanggungan'){
          let resFlagPTKP = await getPTKP(profile.tahunPajak, data.flagPTKP, data.jumlahTanggungan)
          data.B10 = number.format(resFlagPTKP.data.nominal)
        }
        return data
      }}
      onSubmit={(data, callback) => {
        props.onSave({B:data});
        callback(t.translate('sentence.savedItem'), false)
      }}
      submitLabel={t.translate('column.save')}
    />
  )
}

export default inject('envStore')(observer(BagianB))

